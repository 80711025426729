@use 'sass:list';

$gradient-1: radial-gradient(113% 91% at 17% -2%, #00a6ffba 1%, #ff000000 99%),
    radial-gradient(142% 91% at 83% 7%, #00ecffe8 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #ff0049de 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #ff8400ff 0%, #ff0000ff 100%);

$gradient-2: radial-gradient(49% 81% at 45% 47%, #ffe20345 0%, #073aff00 100%),
    radial-gradient(113% 91% at 17% -2%, #00d8ffff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 83% 7%, #ff9b00ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #ff00494a 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #ffe100ff 0%, #ff0000ff 100%);

$gradient-3: radial-gradient(49% 81% at 40% 36%, #ffb703a8 0%, #073aff00 100%),
    radial-gradient(113% 91% at 6% -11%, #00a6ffff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 85% 2%, #f0cf39ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #bd4769ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #00ffa6ff 0%, #001effff 100%);

$gradient-4: repeating-linear-gradient(315deg, #00ffff2e 92%, #073aff00 100%),
    repeating-radial-gradient(
        75% 75% at 238% 218%,
        #00ffff12 30%,
        #073aff14 39%
    ),
    radial-gradient(99% 99% at 109% 2%, #00c9ffff 0%, #073aff00 100%),
    radial-gradient(99% 99% at 21% 78%, #441083ff 0%, #073aff00 100%),
    radial-gradient(160% 154% at 711px -303px, #ffd600ff 0%, #ff6d00ff 100%);

$background-gradient: $gradient-4;

body {
    overflow-x: hidden;
    min-height: 105vh;
    // https://colorgradient.dev/gradient-generator/
    background-size: 100% 100%;
    background-position:
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px;
    background-image: $background-gradient;
}

.gradient-header {
    padding-top: 5.5rem;
    font-size: calc(17 * (0.6vh + 0.4vw));
    line-height: calc(12 * (0.6vh + 0.4vw));
    font-weight: bold;
    color: transparent;
    text-transform: uppercase;
    overflow: hidden;
    background-size: 100vw 100vh;
    background-attachment: scroll;
    background-position:
        0px 0px,
        0px 0px,
        0px 0px,
        0px 0px;
    background-image: $background-gradient;

    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.service-card {
    --card-scale-x: 1;
    --card-scale-y: 1;
    box-shadow: var(--shadow-elevation-medium);
    transform: scaleX(var(--card-scale-x)) scaleY(var(--card-scale-y));
    transition-property: box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;

    &.coming-soon {
        > .row:first-of-type {
            filter: blur(8px) grayscale(50%) brightness(110%);
        }
    }
    &.animated {
        &:hover {
            --card-scale-x: 1.025;
            --card-scale-y: 1.025;
            box-shadow: var(--shadow-elevation-high);
        }
    }
}

.background-blur {
    backdrop-filter: blur(8px) grayscale(50%) brightness(110%);
}
