// https://www.joshwcomeau.com/shadow-palette/

:root {
    --shadow-color: 0deg 0% 0%;
    --shadow-elevation-low: 0.3px 0.6px 0.7px hsl(var(--shadow-color) / 0.09),
        0.5px 0.9px 1.1px -1.5px hsl(var(--shadow-color) / 0.08),
        1.2px 2.2px 2.6px -3px hsl(var(--shadow-color) / 0.07);
    --shadow-elevation-medium: 0.3px 0.6px 0.7px hsl(var(--shadow-color) / 0.07),
        0.7px 1.4px 1.6px -0.7px hsl(var(--shadow-color) / 0.07),
        1.4px 2.7px 3.2px -1.5px hsl(var(--shadow-color) / 0.06),
        2.9px 5.5px 6.5px -2.2px hsl(var(--shadow-color) / 0.06),
        5.8px 10.8px 12.9px -3px hsl(var(--shadow-color) / 0.05);
    --shadow-elevation-high: 0.3px 0.6px 0.7px hsl(var(--shadow-color) / 0.07),
        1.1px 2px 2.4px -0.3px hsl(var(--shadow-color) / 0.06),
        1.8px 3.4px 4px -0.7px hsl(var(--shadow-color) / 0.06),
        2.8px 5.2px 6.2px -1px hsl(var(--shadow-color) / 0.06),
        4.1px 7.7px 9.2px -1.3px hsl(var(--shadow-color) / 0.06),
        6.1px 11.4px 13.6px -1.7px hsl(var(--shadow-color) / 0.06),
        8.8px 16.6px 19.7px -2px hsl(var(--shadow-color) / 0.05),
        12.6px 23.6px 28.1px -2.3px hsl(var(--shadow-color) / 0.05),
        17.5px 32.8px 39px -2.7px hsl(var(--shadow-color) / 0.05),
        23.7px 44.5px 52.9px -3px hsl(var(--shadow-color) / 0.05);
}
