.btn-xl {
    $btn-padding-y-xl: calc($btn-padding-y * 4);
    $btn-padding-x-xl: calc($btn-padding-x * 4);
    $btn-font-size-xl: calc($btn-font-size * 4);
    $btn-border-radius-xl: calc($btn-border-radius * 4);
    @include button-size(
        $btn-padding-y-xl,
        $btn-padding-x-xl,
        $btn-font-size-xl,
        $btn-border-radius-xl
    );
}
