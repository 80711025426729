// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

//
// Bring in Bootstrap
//

// Toggle global options
// Include functions first
@import 'bootstrap/scss/functions';

// Required
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';

//Custom Variables
@import 'shadows';
@import 'variables';

// Required Functions
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';

// Components
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar'; // Requires nav
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal'; // Requires transitions
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas'; // Requires transitions
@import 'bootstrap/scss/placeholders';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

// Custom styles
@import '/node_modules/@fontsource-variable/sofia-sans-semi-condensed/wght.css';
$ti-font-path: '/node_modules/@tabler/icons-webfont/dist/fonts';
@import '/node_modules/@tabler/icons-webfont/dist/tabler-icons.scss';
@import '/node_modules/@jarstone/dselect/dist/css/dselect.css';
@import 'ribbon';
@import 'typography';
@import 'effects';
@import 'buttons';
@import 'layout';

body {
    min-height: 100vh;
}
