* {
    --#{$prefix}variable-font-default: 'wdth' 135, 'wght' 400;
    letter-spacing: 0.0125rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %heading;
    font-variation-settings:
        'wdth' 125,
        'wght' 400;
}

@each $display, $font-size in $display-font-sizes {
    .display-#{$display} {
        font-variation-settings:
            'wdth' 150,
            'wght' 500;
    }
}

.lh-none {
    line-height: 0 !important;
}
