@font-face {
  font-family: Sofia Sans Semi Condensed Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 1 1000;
  src: url("sofia-sans-semi-condensed-cyrillic-ext-wght-normal.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Sofia Sans Semi Condensed Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 1 1000;
  src: url("sofia-sans-semi-condensed-cyrillic-wght-normal.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Sofia Sans Semi Condensed Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 1 1000;
  src: url("sofia-sans-semi-condensed-greek-wght-normal.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Sofia Sans Semi Condensed Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 1 1000;
  src: url("sofia-sans-semi-condensed-latin-ext-wght-normal.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Sofia Sans Semi Condensed Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 1 1000;
  src: url("sofia-sans-semi-condensed-latin-wght-normal.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.dselect-wrapper .form-select {
  text-align: left;
  flex-wrap: wrap;
  align-items: center;
  gap: .25rem;
  padding-left: .75rem;
  display: flex;
}

.dselect-wrapper .form-select.show {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.dselect-wrapper .form-select.dselect-clearable {
  padding-right: 3.25rem;
}

.dselect-wrapper .form-select.form-select-sm {
  padding-left: .5rem;
}

.dselect-wrapper .form-select.form-select-sm.dselect-clearable {
  padding-right: 3.125rem;
}

.dselect-wrapper .form-select.form-select-lg {
  padding-left: 1rem;
}

.dselect-wrapper .form-select.form-select-lg.dselect-clearable {
  padding-right: 3.375rem;
}

.dselect-wrapper .dropdown-menu {
  width: 100%;
  padding: .375rem;
}

.dselect-wrapper .dropdown-menu > .flex-column {
  gap: .375rem;
}

.dselect-wrapper .form-select-sm + .dropdown-menu {
  padding: .25rem;
  font-size: .875rem;
}

.dselect-wrapper .form-select-lg + .dropdown-menu {
  padding: .5rem;
  font-size: 1.25rem;
}

.dselect-wrapper .dropdown-item, .dselect-wrapper .dropdown-header {
  padding-left: .375rem;
  padding-right: .375rem;
}

.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-item, .dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-header {
  padding-left: .25rem;
  padding-right: .25rem;
}

.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-item, .dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-header {
  padding-left: .5rem;
  padding-right: .5rem;
}

.dselect-wrapper .form-control {
  font-size: inherit;
  border-radius: 0;
  padding: .25rem calc(.375rem - 1px);
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.dselect-wrapper .form-select-sm + .dropdown-menu .form-control {
  padding: calc(.25rem - 1px);
}

.dselect-wrapper .form-select-lg + .dropdown-menu .form-control {
  padding: .25rem calc(.5rem - 1px);
}

.dselect-wrapper .dselect-no-results {
  padding: .25rem .375rem;
}

.dselect-wrapper .form-select-sm + .dropdown-menu .dselect-no-results {
  padding: calc(.25rem - 1px) .25rem;
}

.dselect-wrapper .form-select-lg + .dropdown-menu .dselect-no-results {
  padding: .25rem .5rem;
}

.dselect-wrapper .dselect-tag {
  color: #f8f9fa;
  background-color: #6c757d;
  border-radius: .25rem;
  height: 1.5rem;
  padding-left: calc(.5rem + 14px);
  padding-right: .5rem;
  line-height: calc(1.5rem - 1px);
  position: relative;
}

.dselect-wrapper .form-select-sm .dselect-tag {
  line-height: inherit;
  height: auto;
}

.dselect-wrapper .form-select-lg .dselect-tag {
  height: 1.875rem;
  line-height: 1.875rem;
}

.dselect-wrapper .dselect-tag-remove {
  color: #f8f9fa59;
  margin-top: -7px;
  transition: color .15s ease-in-out;
  position: absolute;
  top: 50%;
  left: .25rem;
}

@media (prefers-reduced-motion: reduce) {
  .dselect-wrapper .dselect-tag-remove {
    transition: none;
  }
}

.dselect-wrapper .dselect-tag-remove:hover {
  color: #f8f9fa;
}

.dselect-wrapper .dselect-placeholder {
  color: #6c757d;
}

.dselect-wrapper .dropdown-header ~ .dropdown-item {
  padding-left: .75rem;
  padding-right: .75rem;
}

.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-header {
  font-size: .85em;
}

.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-header ~ .dropdown-item {
  padding-left: .5rem;
  padding-right: .5rem;
}

.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-header {
  font-size: .85em;
}

.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-header ~ .dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

.dselect-wrapper .dselect-clear {
  box-shadow: none;
  color: #6c757d;
  cursor: pointer;
  border: 0;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2.25rem;
}

.dselect-wrapper .dselect-clear:hover {
  color: inherit;
}

.dselect-wrapper .dselect-clear svg {
  width: .625rem;
  height: .625rem;
}

.dselect-wrapper [data-dselect-text=""] ~ .dselect-clear {
  display: none;
}

.was-validated .form-select:invalid + .dselect-wrapper .form-select, .form-select.is-invalid + .dselect-wrapper .form-select {
  border-color: #dc3545;
}

.was-validated .form-select:invalid + .dselect-wrapper .form-select.show, .was-validated .form-select:invalid + .dselect-wrapper .form-select:focus, .form-select.is-invalid + .dselect-wrapper .form-select.show, .form-select.is-invalid + .dselect-wrapper .form-select:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-select:valid + .dselect-wrapper .form-select, .form-select.is-valid + .dselect-wrapper .form-select {
  border-color: #198754;
}

.was-validated .form-select:valid + .dselect-wrapper .form-select.show, .was-validated .form-select:valid + .dselect-wrapper .form-select:focus, .form-select.is-valid + .dselect-wrapper .form-select.show, .form-select.is-valid + .dselect-wrapper .form-select:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: .3px .6px .7px hsl(var(--shadow-color) / .09), .5px .9px 1.1px -1.5px hsl(var(--shadow-color) / .08), 1.2px 2.2px 2.6px -3px hsl(var(--shadow-color) / .07);
  --shadow-elevation-medium: .3px .6px .7px hsl(var(--shadow-color) / .07), .7px 1.4px 1.6px -.7px hsl(var(--shadow-color) / .07), 1.4px 2.7px 3.2px -1.5px hsl(var(--shadow-color) / .06), 2.9px 5.5px 6.5px -2.2px hsl(var(--shadow-color) / .06), 5.8px 10.8px 12.9px -3px hsl(var(--shadow-color) / .05);
  --shadow-elevation-high: .3px .6px .7px hsl(var(--shadow-color) / .07), 1.1px 2px 2.4px -.3px hsl(var(--shadow-color) / .06), 1.8px 3.4px 4px -.7px hsl(var(--shadow-color) / .06), 2.8px 5.2px 6.2px -1px hsl(var(--shadow-color) / .06), 4.1px 7.7px 9.2px -1.3px hsl(var(--shadow-color) / .06), 6.1px 11.4px 13.6px -1.7px hsl(var(--shadow-color) / .06), 8.8px 16.6px 19.7px -2px hsl(var(--shadow-color) / .05), 12.6px 23.6px 28.1px -2.3px hsl(var(--shadow-color) / .05), 17.5px 32.8px 39px -2.7px hsl(var(--shadow-color) / .05), 23.7px 44.5px 52.9px -3px hsl(var(--shadow-color) / .05);
}

:root, [data-bs-theme="light"] {
  --bs-blue: #00c9ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #ff0049;
  --bs-red: #dc3545;
  --bs-orange: #ff8400;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #00ecff;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #66dfff;
  --bs-secondary: #6c757d;
  --bs-success: #20c997;
  --bs-info: #00ecff;
  --bs-warning: #ffc107;
  --bs-danger: #ff336d;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 102, 223, 255;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 32, 201, 151;
  --bs-info-rgb: 0, 236, 255;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 255, 51, 109;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #295966;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0d503c;
  --bs-info-text-emphasis: #005e66;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #66142c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #e0f9ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d2f4ea;
  --bs-info-bg-subtle: #ccfbff;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #ffd6e2;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #c2f2ff;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a6e9d5;
  --bs-info-border-subtle: #99f7ff;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #ffadc5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Sofia Sans Semi Condensed Variable", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #343a40;
  --bs-body-color-rgb: 52, 58, 64;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #343a40bf;
  --bs-secondary-color-rgb: 52, 58, 64;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #343a4080;
  --bs-tertiary-color-rgb: 52, 58, 64;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #007999;
  --bs-link-color-rgb: 0, 121, 153;
  --bs-link-decoration: none;
  --bs-link-hover-color: #00617a;
  --bs-link-hover-color-rgb: 0, 97, 122;
  --bs-link-hover-decoration: none;
  --bs-code-color: #ff0049;
  --bs-highlight-color: #343a40;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: var(--shadow-elevation-medium);
  --bs-box-shadow-sm: var(--shadow-elevation-low);
  --bs-box-shadow-lg: var(--shadow-elevation-high);
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #66dfff40;
  --bs-form-valid-color: #20c997;
  --bs-form-valid-border-color: #20c997;
  --bs-form-invalid-color: #ff336d;
  --bs-form-invalid-border-color: #ff336d;
}

[data-bs-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #dee2e6bf;
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: #dee2e680;
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: #ffffff26;
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 600;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--bs-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), .05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), .1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #e0f9ff;
  --bs-table-border-color: #b3c7cc;
  --bs-table-striped-bg: #d5edf2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cae0e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfe6ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d2f4ea;
  --bs-table-border-color: #a8c3bb;
  --bs-table-striped-bg: #c8e8de;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bddcd3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2e2d8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccfbff;
  --bs-table-border-color: #a3c9cc;
  --bs-table-striped-bg: #c2eef2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8e2e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde8ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffd6e2;
  --bs-table-border-color: #ccabb5;
  --bs-table-striped-bg: #f2cbd7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c1cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecc6d1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--bs-border-width));
  padding-bottom: calc(.375rem + var(--bs-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--bs-border-width));
  padding-bottom: calc(.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--bs-border-width));
  padding-bottom: calc(.25rem + var(--bs-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--bs-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #ff99b6;
  outline: 0;
  box-shadow: 0 0 0 .25rem #66dfff40;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  background-image: var(--bs-gradient);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  margin: -.375rem -.75rem;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  color: var(--bs-body-color);
  border: solid #0000;
  border-width: var(--bs-border-width) 0;
  background-color: #0000;
  width: 100%;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
  width: 3rem;
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #ff99b6;
  outline: 0;
  box-shadow: 0 0 0 .25rem #66dfff40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  border-radius: var(--bs-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--bs-border-radius-lg);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #ff99b6;
  outline: 0;
  box-shadow: 0 0 0 .25rem #66dfff40;
}

.form-check-input:checked {
  background-color: #ff336d;
  border-color: #ff336d;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-input[type="checkbox"]:indeterminate {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"), var(--bs-gradient);
  background-color: #ff336d;
  border-color: #ff336d;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--bs-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff99b6'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"), var(--bs-gradient);
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #66dfff40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #66dfff40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #ff336d;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #ffc2d3;
  background-image: var(--bs-gradient);
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #ff336d;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #ffc2d3;
  background-image: var(--bs-gradient);
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--bs-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2320c997' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2320c997' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--bs-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff336d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff336d' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff336d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff336d' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  background-image: var(--bs-gradient);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  background-image: var(--bs-gradient);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  background-image: none;
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  background-image: none;
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #66dfff;
  --bs-btn-border-color: #66dfff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7de4ff;
  --bs-btn-hover-border-color: #75e2ff;
  --bs-btn-focus-shadow-rgb: 87, 190, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #85e5ff;
  --bs-btn-active-border-color: #75e2ff;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #66dfff;
  --bs-btn-disabled-border-color: #66dfff;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #41d1a7;
  --bs-btn-hover-border-color: #36cea1;
  --bs-btn-focus-shadow-rgb: 27, 171, 128;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #36cea1;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #20c997;
  --bs-btn-disabled-border-color: #20c997;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #00ecff;
  --bs-btn-border-color: #00ecff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26efff;
  --bs-btn-hover-border-color: #1aeeff;
  --bs-btn-focus-shadow-rgb: 0, 201, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33f0ff;
  --bs-btn-active-border-color: #1aeeff;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00ecff;
  --bs-btn-disabled-border-color: #00ecff;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff336d;
  --bs-btn-border-color: #ff336d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff5283;
  --bs-btn-hover-border-color: #ff477c;
  --bs-btn-focus-shadow-rgb: 217, 43, 93;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff5c8a;
  --bs-btn-active-border-color: #ff477c;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff336d;
  --bs-btn-disabled-border-color: #ff336d;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #66dfff;
  --bs-btn-border-color: #66dfff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #66dfff;
  --bs-btn-hover-border-color: #66dfff;
  --bs-btn-focus-shadow-rgb: 102, 223, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #66dfff;
  --bs-btn-active-border-color: #66dfff;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #66dfff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #66dfff;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #20c997;
  --bs-btn-hover-border-color: #20c997;
  --bs-btn-focus-shadow-rgb: 32, 201, 151;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #20c997;
  --bs-btn-active-border-color: #20c997;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #20c997;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #20c997;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00ecff;
  --bs-btn-border-color: #00ecff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00ecff;
  --bs-btn-hover-border-color: #00ecff;
  --bs-btn-focus-shadow-rgb: 0, 236, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00ecff;
  --bs-btn-active-border-color: #00ecff;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #00ecff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00ecff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff336d;
  --bs-btn-border-color: #ff336d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff336d;
  --bs-btn-hover-border-color: #ff336d;
  --bs-btn-focus-shadow-rgb: 255, 51, 109;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff336d;
  --bs-btn-active-border-color: #ff336d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ff336d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff336d;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 38, 141, 168;
  background-image: none;
  text-decoration: none;
}

.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-border-radius: var(--bs-border-radius-sm);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius-sm)  - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #ff336d;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
  background-image: var(--bs-gradient);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  background-image: var(--bs-gradient);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
  background-image: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #ff336d;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-size: 1.125rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #66dfff40;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-link-hover-border-color: transparent transparent var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-white);
  --bs-nav-tabs-link-active-bg: var(--bs-danger);
  --bs-nav-tabs-link-active-border-color: transparent transparent var(--bs-danger);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #ff336d;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
  background-image: var(--bs-gradient);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: .125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 700;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), .8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), .3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2852, 58, 64, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), .15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  background-image: var(--bs-gradient);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-size: 1.125rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  color: var(--bs-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23343a40'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23295966'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #66dfff40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed, .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #66dfff40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #ff336d;
  --bs-pagination-active-border-color: #ff336d;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  background-image: var(--bs-gradient);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 400;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  background-image: var(--bs-gradient);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--bs-alert-link-color);
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress, .progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #66dfff;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #ff336d;
  --bs-list-group-active-border-color: #ff336d;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: .5;
  --bs-btn-close-hover-opacity: .75;
  --bs-btn-close-focus-shadow: 0 0 0 .25rem #66dfff40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: .25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--bs-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  opacity: var(--bs-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg)  - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg)  - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  background-image: linear-gradient(90deg, #00000040, #0000);
  left: 0;
}

.carousel-control-next {
  background-image: linear-gradient(270deg, #00000040, #0000);
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform .3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  transition: var(--bs-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  line-height: var(--bs-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(133, 229, 255, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(133, 229, 255, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(133, 229, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(77, 212, 172, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(77, 212, 172, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(77, 212, 172, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(51, 240, 255, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(51, 240, 255, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 240, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(255, 92, 138, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 92, 138, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 92, 138, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--bs-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --bs-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --bs-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --bs-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --bs-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

@font-face {
  font-family: tabler-icons;
  font-style: normal;
  font-weight: 400;
  src: url("tabler-icons.woff2") format("woff2"), url("tabler-icons.woff") format("woff"), url("tabler-icons.ttf") format("truetype");
}

.ti {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: tabler-icons !important;
}

.ti-a-b:before {
  content: "";
}

.ti-a-b-2:before {
  content: "";
}

.ti-a-b-off:before {
  content: "";
}

.ti-abacus:before {
  content: "";
}

.ti-abacus-off:before {
  content: "";
}

.ti-abc:before {
  content: "";
}

.ti-access-point:before {
  content: "";
}

.ti-access-point-off:before {
  content: "";
}

.ti-accessible:before {
  content: "";
}

.ti-accessible-filled:before {
  content: "";
}

.ti-accessible-off:before {
  content: "";
}

.ti-activity:before {
  content: "";
}

.ti-activity-heartbeat:before {
  content: "";
}

.ti-ad:before {
  content: "";
}

.ti-ad-2:before {
  content: "";
}

.ti-ad-circle:before {
  content: "";
}

.ti-ad-circle-filled:before {
  content: "";
}

.ti-ad-circle-off:before {
  content: "";
}

.ti-ad-filled:before {
  content: "";
}

.ti-ad-off:before {
  content: "";
}

.ti-address-book:before {
  content: "";
}

.ti-address-book-off:before {
  content: "";
}

.ti-adjustments:before {
  content: "";
}

.ti-adjustments-alt:before {
  content: "";
}

.ti-adjustments-bolt:before {
  content: "";
}

.ti-adjustments-cancel:before {
  content: "";
}

.ti-adjustments-check:before {
  content: "";
}

.ti-adjustments-code:before {
  content: "";
}

.ti-adjustments-cog:before {
  content: "";
}

.ti-adjustments-dollar:before {
  content: "";
}

.ti-adjustments-down:before {
  content: "";
}

.ti-adjustments-exclamation:before {
  content: "";
}

.ti-adjustments-filled:before {
  content: "";
}

.ti-adjustments-heart:before {
  content: "";
}

.ti-adjustments-horizontal:before {
  content: "";
}

.ti-adjustments-minus:before {
  content: "";
}

.ti-adjustments-off:before {
  content: "";
}

.ti-adjustments-pause:before {
  content: "";
}

.ti-adjustments-pin:before {
  content: "";
}

.ti-adjustments-plus:before {
  content: "";
}

.ti-adjustments-question:before {
  content: "";
}

.ti-adjustments-search:before {
  content: "";
}

.ti-adjustments-share:before {
  content: "";
}

.ti-adjustments-star:before {
  content: "";
}

.ti-adjustments-up:before {
  content: "";
}

.ti-adjustments-x:before {
  content: "";
}

.ti-aerial-lift:before {
  content: "";
}

.ti-affiliate:before {
  content: "";
}

.ti-affiliate-filled:before {
  content: "";
}

.ti-air-balloon:before {
  content: "";
}

.ti-air-conditioning:before {
  content: "";
}

.ti-air-conditioning-disabled:before {
  content: "";
}

.ti-air-traffic-control:before {
  content: "ﬁ";
}

.ti-alarm:before {
  content: "";
}

.ti-alarm-average:before {
  content: "ﲞ";
}

.ti-alarm-filled:before {
  content: "";
}

.ti-alarm-minus:before {
  content: "";
}

.ti-alarm-minus-filled:before {
  content: "";
}

.ti-alarm-off:before {
  content: "";
}

.ti-alarm-plus:before {
  content: "";
}

.ti-alarm-plus-filled:before {
  content: "";
}

.ti-alarm-snooze:before {
  content: "";
}

.ti-alarm-snooze-filled:before {
  content: "";
}

.ti-album:before {
  content: "";
}

.ti-album-off:before {
  content: "";
}

.ti-alert-circle:before {
  content: "";
}

.ti-alert-circle-filled:before {
  content: "";
}

.ti-alert-circle-off:before {
  content: "ﱥ";
}

.ti-alert-hexagon:before {
  content: "";
}

.ti-alert-hexagon-filled:before {
  content: "勤";
}

.ti-alert-hexagon-off:before {
  content: "ﱦ";
}

.ti-alert-octagon:before {
  content: "";
}

.ti-alert-octagon-filled:before {
  content: "";
}

.ti-alert-small:before {
  content: "";
}

.ti-alert-small-off:before {
  content: "ﱧ";
}

.ti-alert-square:before {
  content: "";
}

.ti-alert-square-filled:before {
  content: "卑";
}

.ti-alert-square-rounded:before {
  content: "";
}

.ti-alert-square-rounded-filled:before {
  content: "喝";
}

.ti-alert-square-rounded-off:before {
  content: "ﱨ";
}

.ti-alert-triangle:before {
  content: "";
}

.ti-alert-triangle-filled:before {
  content: "";
}

.ti-alert-triangle-off:before {
  content: "ﱩ";
}

.ti-alien:before {
  content: "";
}

.ti-alien-filled:before {
  content: "";
}

.ti-align-box-bottom-center:before {
  content: "";
}

.ti-align-box-bottom-center-filled:before {
  content: "";
}

.ti-align-box-bottom-left:before {
  content: "";
}

.ti-align-box-bottom-left-filled:before {
  content: "";
}

.ti-align-box-bottom-right:before {
  content: "";
}

.ti-align-box-bottom-right-filled:before {
  content: "";
}

.ti-align-box-center-bottom:before {
  content: "頋";
}

.ti-align-box-center-middle:before {
  content: "";
}

.ti-align-box-center-middle-filled:before {
  content: "";
}

.ti-align-box-center-stretch:before {
  content: "頻";
}

.ti-align-box-center-top:before {
  content: "鬒";
}

.ti-align-box-left-bottom:before {
  content: "";
}

.ti-align-box-left-bottom-filled:before {
  content: "";
}

.ti-align-box-left-middle:before {
  content: "";
}

.ti-align-box-left-middle-filled:before {
  content: "";
}

.ti-align-box-left-stretch:before {
  content: "龜";
}

.ti-align-box-left-top:before {
  content: "";
}

.ti-align-box-left-top-filled:before {
  content: "";
}

.ti-align-box-right-bottom:before {
  content: "";
}

.ti-align-box-right-bottom-filled:before {
  content: "";
}

.ti-align-box-right-middle:before {
  content: "";
}

.ti-align-box-right-middle-filled:before {
  content: "";
}

.ti-align-box-right-stretch:before {
  content: "𢡊";
}

.ti-align-box-right-top:before {
  content: "";
}

.ti-align-box-right-top-filled:before {
  content: "";
}

.ti-align-box-top-center:before {
  content: "";
}

.ti-align-box-top-center-filled:before {
  content: "";
}

.ti-align-box-top-left:before {
  content: "";
}

.ti-align-box-top-left-filled:before {
  content: "";
}

.ti-align-box-top-right:before {
  content: "";
}

.ti-align-box-top-right-filled:before {
  content: "";
}

.ti-align-center:before {
  content: "";
}

.ti-align-justified:before {
  content: "";
}

.ti-align-left:before {
  content: "";
}

.ti-align-right:before {
  content: "";
}

.ti-alpha:before {
  content: "";
}

.ti-alphabet-cyrillic:before {
  content: "";
}

.ti-alphabet-greek:before {
  content: "";
}

.ti-alphabet-latin:before {
  content: "";
}

.ti-alt:before {
  content: "ﱔ";
}

.ti-ambulance:before {
  content: "";
}

.ti-ampersand:before {
  content: "";
}

.ti-analyze:before {
  content: "";
}

.ti-analyze-filled:before {
  content: "";
}

.ti-analyze-off:before {
  content: "";
}

.ti-anchor:before {
  content: "";
}

.ti-anchor-off:before {
  content: "";
}

.ti-angle:before {
  content: "";
}

.ti-ankh:before {
  content: "";
}

.ti-antenna:before {
  content: "";
}

.ti-antenna-bars-1:before {
  content: "";
}

.ti-antenna-bars-2:before {
  content: "";
}

.ti-antenna-bars-3:before {
  content: "";
}

.ti-antenna-bars-4:before {
  content: "";
}

.ti-antenna-bars-5:before {
  content: "";
}

.ti-antenna-bars-off:before {
  content: "";
}

.ti-antenna-off:before {
  content: "";
}

.ti-aperture:before {
  content: "";
}

.ti-aperture-off:before {
  content: "";
}

.ti-api:before {
  content: "";
}

.ti-api-app:before {
  content: "";
}

.ti-api-app-off:before {
  content: "";
}

.ti-api-off:before {
  content: "";
}

.ti-app-window:before {
  content: "";
}

.ti-app-window-filled:before {
  content: "";
}

.ti-apple:before {
  content: "";
}

.ti-apps:before {
  content: "";
}

.ti-apps-filled:before {
  content: "";
}

.ti-apps-off:before {
  content: "";
}

.ti-archery-arrow:before {
  content: "ﱕ";
}

.ti-archive:before {
  content: "";
}

.ti-archive-filled:before {
  content: "廒";
}

.ti-archive-off:before {
  content: "";
}

.ti-armchair:before {
  content: "";
}

.ti-armchair-2:before {
  content: "";
}

.ti-armchair-2-off:before {
  content: "";
}

.ti-armchair-off:before {
  content: "";
}

.ti-arrow-autofit-content:before {
  content: "";
}

.ti-arrow-autofit-content-filled:before {
  content: "";
}

.ti-arrow-autofit-down:before {
  content: "";
}

.ti-arrow-autofit-height:before {
  content: "";
}

.ti-arrow-autofit-left:before {
  content: "";
}

.ti-arrow-autofit-right:before {
  content: "";
}

.ti-arrow-autofit-up:before {
  content: "";
}

.ti-arrow-autofit-width:before {
  content: "";
}

.ti-arrow-back:before {
  content: "";
}

.ti-arrow-back-up:before {
  content: "";
}

.ti-arrow-back-up-double:before {
  content: "溺";
}

.ti-arrow-badge-down:before {
  content: "";
}

.ti-arrow-badge-down-filled:before {
  content: "";
}

.ti-arrow-badge-left:before {
  content: "";
}

.ti-arrow-badge-left-filled:before {
  content: "";
}

.ti-arrow-badge-right:before {
  content: "";
}

.ti-arrow-badge-right-filled:before {
  content: "";
}

.ti-arrow-badge-up:before {
  content: "";
}

.ti-arrow-badge-up-filled:before {
  content: "";
}

.ti-arrow-bar-both:before {
  content: "﫝";
}

.ti-arrow-bar-down:before {
  content: "";
}

.ti-arrow-bar-left:before {
  content: "";
}

.ti-arrow-bar-right:before {
  content: "";
}

.ti-arrow-bar-to-down:before {
  content: "";
}

.ti-arrow-bar-to-left:before {
  content: "";
}

.ti-arrow-bar-to-right:before {
  content: "";
}

.ti-arrow-bar-to-up:before {
  content: "";
}

.ti-arrow-bar-up:before {
  content: "";
}

.ti-arrow-bear-left:before {
  content: "";
}

.ti-arrow-bear-left-2:before {
  content: "";
}

.ti-arrow-bear-right:before {
  content: "";
}

.ti-arrow-bear-right-2:before {
  content: "";
}

.ti-arrow-big-down:before {
  content: "";
}

.ti-arrow-big-down-filled:before {
  content: "";
}

.ti-arrow-big-down-line:before {
  content: "";
}

.ti-arrow-big-down-line-filled:before {
  content: "";
}

.ti-arrow-big-down-lines:before {
  content: "";
}

.ti-arrow-big-down-lines-filled:before {
  content: "";
}

.ti-arrow-big-left:before {
  content: "";
}

.ti-arrow-big-left-filled:before {
  content: "";
}

.ti-arrow-big-left-line:before {
  content: "";
}

.ti-arrow-big-left-line-filled:before {
  content: "";
}

.ti-arrow-big-left-lines:before {
  content: "";
}

.ti-arrow-big-left-lines-filled:before {
  content: "";
}

.ti-arrow-big-right:before {
  content: "";
}

.ti-arrow-big-right-filled:before {
  content: "";
}

.ti-arrow-big-right-line:before {
  content: "";
}

.ti-arrow-big-right-line-filled:before {
  content: "";
}

.ti-arrow-big-right-lines:before {
  content: "";
}

.ti-arrow-big-right-lines-filled:before {
  content: "";
}

.ti-arrow-big-up:before {
  content: "";
}

.ti-arrow-big-up-filled:before {
  content: "";
}

.ti-arrow-big-up-line:before {
  content: "";
}

.ti-arrow-big-up-line-filled:before {
  content: "";
}

.ti-arrow-big-up-lines:before {
  content: "";
}

.ti-arrow-big-up-lines-filled:before {
  content: "";
}

.ti-arrow-bounce:before {
  content: "";
}

.ti-arrow-capsule:before {
  content: "﫞";
}

.ti-arrow-curve-left:before {
  content: "";
}

.ti-arrow-curve-right:before {
  content: "";
}

.ti-arrow-down:before {
  content: "";
}

.ti-arrow-down-bar:before {
  content: "";
}

.ti-arrow-down-circle:before {
  content: "";
}

.ti-arrow-down-from-arc:before {
  content: "ﶆ";
}

.ti-arrow-down-left:before {
  content: "";
}

.ti-arrow-down-left-circle:before {
  content: "";
}

.ti-arrow-down-rhombus:before {
  content: "";
}

.ti-arrow-down-right:before {
  content: "";
}

.ti-arrow-down-right-circle:before {
  content: "";
}

.ti-arrow-down-square:before {
  content: "";
}

.ti-arrow-down-tail:before {
  content: "";
}

.ti-arrow-down-to-arc:before {
  content: "ﶇ";
}

.ti-arrow-elbow-left:before {
  content: "吝";
}

.ti-arrow-elbow-right:before {
  content: "燐";
}

.ti-arrow-fork:before {
  content: "";
}

.ti-arrow-forward:before {
  content: "";
}

.ti-arrow-forward-up:before {
  content: "";
}

.ti-arrow-forward-up-double:before {
  content: "璘";
}

.ti-arrow-guide:before {
  content: "";
}

.ti-arrow-iteration:before {
  content: "";
}

.ti-arrow-left:before {
  content: "";
}

.ti-arrow-left-bar:before {
  content: "";
}

.ti-arrow-left-circle:before {
  content: "";
}

.ti-arrow-left-from-arc:before {
  content: "ﶈ";
}

.ti-arrow-left-rhombus:before {
  content: "";
}

.ti-arrow-left-right:before {
  content: "";
}

.ti-arrow-left-square:before {
  content: "";
}

.ti-arrow-left-tail:before {
  content: "";
}

.ti-arrow-left-to-arc:before {
  content: "ﶉ";
}

.ti-arrow-loop-left:before {
  content: "";
}

.ti-arrow-loop-left-2:before {
  content: "";
}

.ti-arrow-loop-right:before {
  content: "";
}

.ti-arrow-loop-right-2:before {
  content: "";
}

.ti-arrow-merge:before {
  content: "";
}

.ti-arrow-merge-alt-left:before {
  content: "ﲟ";
}

.ti-arrow-merge-alt-right:before {
  content: "ﲠ";
}

.ti-arrow-merge-both:before {
  content: "";
}

.ti-arrow-merge-left:before {
  content: "";
}

.ti-arrow-merge-right:before {
  content: "";
}

.ti-arrow-move-down:before {
  content: "";
}

.ti-arrow-move-left:before {
  content: "";
}

.ti-arrow-move-right:before {
  content: "";
}

.ti-arrow-move-up:before {
  content: "";
}

.ti-arrow-narrow-down:before {
  content: "";
}

.ti-arrow-narrow-left:before {
  content: "";
}

.ti-arrow-narrow-right:before {
  content: "";
}

.ti-arrow-narrow-up:before {
  content: "";
}

.ti-arrow-ramp-left:before {
  content: "";
}

.ti-arrow-ramp-left-2:before {
  content: "";
}

.ti-arrow-ramp-left-3:before {
  content: "";
}

.ti-arrow-ramp-right:before {
  content: "";
}

.ti-arrow-ramp-right-2:before {
  content: "";
}

.ti-arrow-ramp-right-3:before {
  content: "";
}

.ti-arrow-right:before {
  content: "";
}

.ti-arrow-right-bar:before {
  content: "";
}

.ti-arrow-right-circle:before {
  content: "";
}

.ti-arrow-right-from-arc:before {
  content: "ﶊ";
}

.ti-arrow-right-rhombus:before {
  content: "";
}

.ti-arrow-right-square:before {
  content: "";
}

.ti-arrow-right-tail:before {
  content: "";
}

.ti-arrow-right-to-arc:before {
  content: "ﶋ";
}

.ti-arrow-rotary-first-left:before {
  content: "";
}

.ti-arrow-rotary-first-right:before {
  content: "";
}

.ti-arrow-rotary-last-left:before {
  content: "";
}

.ti-arrow-rotary-last-right:before {
  content: "";
}

.ti-arrow-rotary-left:before {
  content: "";
}

.ti-arrow-rotary-right:before {
  content: "";
}

.ti-arrow-rotary-straight:before {
  content: "";
}

.ti-arrow-roundabout-left:before {
  content: "";
}

.ti-arrow-roundabout-right:before {
  content: "";
}

.ti-arrow-sharp-turn-left:before {
  content: "";
}

.ti-arrow-sharp-turn-right:before {
  content: "";
}

.ti-arrow-up:before {
  content: "";
}

.ti-arrow-up-bar:before {
  content: "";
}

.ti-arrow-up-circle:before {
  content: "";
}

.ti-arrow-up-from-arc:before {
  content: "ﶌ";
}

.ti-arrow-up-left:before {
  content: "";
}

.ti-arrow-up-left-circle:before {
  content: "";
}

.ti-arrow-up-rhombus:before {
  content: "";
}

.ti-arrow-up-right:before {
  content: "";
}

.ti-arrow-up-right-circle:before {
  content: "";
}

.ti-arrow-up-square:before {
  content: "";
}

.ti-arrow-up-tail:before {
  content: "";
}

.ti-arrow-up-to-arc:before {
  content: "ﶍ";
}

.ti-arrow-wave-left-down:before {
  content: "";
}

.ti-arrow-wave-left-up:before {
  content: "";
}

.ti-arrow-wave-right-down:before {
  content: "";
}

.ti-arrow-wave-right-up:before {
  content: "";
}

.ti-arrow-zig-zag:before {
  content: "";
}

.ti-arrows-cross:before {
  content: "";
}

.ti-arrows-diagonal:before {
  content: "";
}

.ti-arrows-diagonal-2:before {
  content: "";
}

.ti-arrows-diagonal-minimize:before {
  content: "";
}

.ti-arrows-diagonal-minimize-2:before {
  content: "";
}

.ti-arrows-diff:before {
  content: "";
}

.ti-arrows-double-ne-sw:before {
  content: "";
}

.ti-arrows-double-nw-se:before {
  content: "";
}

.ti-arrows-double-se-nw:before {
  content: "";
}

.ti-arrows-double-sw-ne:before {
  content: "";
}

.ti-arrows-down:before {
  content: "";
}

.ti-arrows-down-up:before {
  content: "";
}

.ti-arrows-exchange:before {
  content: "";
}

.ti-arrows-exchange-2:before {
  content: "";
}

.ti-arrows-horizontal:before {
  content: "";
}

.ti-arrows-join:before {
  content: "";
}

.ti-arrows-join-2:before {
  content: "";
}

.ti-arrows-left:before {
  content: "";
}

.ti-arrows-left-down:before {
  content: "";
}

.ti-arrows-left-right:before {
  content: "";
}

.ti-arrows-maximize:before {
  content: "";
}

.ti-arrows-minimize:before {
  content: "";
}

.ti-arrows-move:before {
  content: "";
}

.ti-arrows-move-horizontal:before {
  content: "";
}

.ti-arrows-move-vertical:before {
  content: "";
}

.ti-arrows-random:before {
  content: "";
}

.ti-arrows-right:before {
  content: "";
}

.ti-arrows-right-down:before {
  content: "";
}

.ti-arrows-right-left:before {
  content: "";
}

.ti-arrows-shuffle:before {
  content: "";
}

.ti-arrows-shuffle-2:before {
  content: "";
}

.ti-arrows-sort:before {
  content: "";
}

.ti-arrows-split:before {
  content: "";
}

.ti-arrows-split-2:before {
  content: "";
}

.ti-arrows-transfer-down:before {
  content: "";
}

.ti-arrows-transfer-up:before {
  content: "";
}

.ti-arrows-up:before {
  content: "";
}

.ti-arrows-up-down:before {
  content: "";
}

.ti-arrows-up-left:before {
  content: "";
}

.ti-arrows-up-right:before {
  content: "";
}

.ti-arrows-vertical:before {
  content: "";
}

.ti-artboard:before {
  content: "";
}

.ti-artboard-filled:before {
  content: "廙";
}

.ti-artboard-off:before {
  content: "";
}

.ti-article:before {
  content: "";
}

.ti-article-filled:before {
  content: "";
}

.ti-article-off:before {
  content: "";
}

.ti-aspect-ratio:before {
  content: "";
}

.ti-aspect-ratio-filled:before {
  content: "";
}

.ti-aspect-ratio-off:before {
  content: "";
}

.ti-assembly:before {
  content: "";
}

.ti-assembly-filled:before {
  content: "ﺞ";
}

.ti-assembly-off:before {
  content: "";
}

.ti-asset:before {
  content: "";
}

.ti-asset-filled:before {
  content: "ﺝ";
}

.ti-asterisk:before {
  content: "";
}

.ti-asterisk-simple:before {
  content: "";
}

.ti-at:before {
  content: "";
}

.ti-at-off:before {
  content: "";
}

.ti-atom:before {
  content: "";
}

.ti-atom-2:before {
  content: "";
}

.ti-atom-2-filled:before {
  content: "";
}

.ti-atom-off:before {
  content: "";
}

.ti-augmented-reality:before {
  content: "";
}

.ti-augmented-reality-2:before {
  content: "";
}

.ti-augmented-reality-off:before {
  content: "";
}

.ti-auth-2fa:before {
  content: "";
}

.ti-automatic-gearbox:before {
  content: "ﲉ";
}

.ti-avocado:before {
  content: "ﶎ";
}

.ti-award:before {
  content: "";
}

.ti-award-filled:before {
  content: "";
}

.ti-award-off:before {
  content: "";
}

.ti-axe:before {
  content: "";
}

.ti-axis-x:before {
  content: "";
}

.ti-axis-y:before {
  content: "";
}

.ti-baby-bottle:before {
  content: "";
}

.ti-baby-carriage:before {
  content: "";
}

.ti-baby-carriage-filled:before {
  content: "ﺜ";
}

.ti-background:before {
  content: "ﴬ";
}

.ti-backhoe:before {
  content: "";
}

.ti-backpack:before {
  content: "";
}

.ti-backpack-off:before {
  content: "";
}

.ti-backslash:before {
  content: "調";
}

.ti-backspace:before {
  content: "";
}

.ti-backspace-filled:before {
  content: "";
}

.ti-badge:before {
  content: "";
}

.ti-badge-3d:before {
  content: "";
}

.ti-badge-3d-filled:before {
  content: "ﺛ";
}

.ti-badge-4k:before {
  content: "";
}

.ti-badge-4k-filled:before {
  content: "ﺚ";
}

.ti-badge-8k:before {
  content: "";
}

.ti-badge-8k-filled:before {
  content: "ﺙ";
}

.ti-badge-ad:before {
  content: "";
}

.ti-badge-ad-filled:before {
  content: "ﺘ";
}

.ti-badge-ad-off:before {
  content: "ﶏ";
}

.ti-badge-ar:before {
  content: "";
}

.ti-badge-ar-filled:before {
  content: "ﺗ";
}

.ti-badge-cc:before {
  content: "";
}

.ti-badge-cc-filled:before {
  content: "ﺖ";
}

.ti-badge-filled:before {
  content: "";
}

.ti-badge-hd:before {
  content: "";
}

.ti-badge-hd-filled:before {
  content: "ﺕ";
}

.ti-badge-off:before {
  content: "";
}

.ti-badge-sd:before {
  content: "";
}

.ti-badge-sd-filled:before {
  content: "ﺔ";
}

.ti-badge-tm:before {
  content: "";
}

.ti-badge-tm-filled:before {
  content: "ﺓ";
}

.ti-badge-vo:before {
  content: "";
}

.ti-badge-vo-filled:before {
  content: "ﺒ";
}

.ti-badge-vr:before {
  content: "";
}

.ti-badge-vr-filled:before {
  content: "ﺑ";
}

.ti-badge-wc:before {
  content: "";
}

.ti-badge-wc-filled:before {
  content: "ﺐ";
}

.ti-badges:before {
  content: "";
}

.ti-badges-filled:before {
  content: "";
}

.ti-badges-off:before {
  content: "";
}

.ti-baguette:before {
  content: "";
}

.ti-ball-american-football:before {
  content: "";
}

.ti-ball-american-football-off:before {
  content: "";
}

.ti-ball-baseball:before {
  content: "";
}

.ti-ball-basketball:before {
  content: "";
}

.ti-ball-bowling:before {
  content: "";
}

.ti-ball-football:before {
  content: "";
}

.ti-ball-football-off:before {
  content: "";
}

.ti-ball-tennis:before {
  content: "";
}

.ti-ball-volleyball:before {
  content: "";
}

.ti-balloon:before {
  content: "";
}

.ti-balloon-filled:before {
  content: "彩";
}

.ti-balloon-off:before {
  content: "";
}

.ti-ballpen:before {
  content: "";
}

.ti-ballpen-filled:before {
  content: "徭";
}

.ti-ballpen-off:before {
  content: "";
}

.ti-ban:before {
  content: "";
}

.ti-bandage:before {
  content: "";
}

.ti-bandage-filled:before {
  content: "";
}

.ti-bandage-off:before {
  content: "";
}

.ti-barbell:before {
  content: "";
}

.ti-barbell-filled:before {
  content: "ﺏ";
}

.ti-barbell-off:before {
  content: "";
}

.ti-barcode:before {
  content: "";
}

.ti-barcode-off:before {
  content: "";
}

.ti-barrel:before {
  content: "";
}

.ti-barrel-off:before {
  content: "";
}

.ti-barrier-block:before {
  content: "";
}

.ti-barrier-block-filled:before {
  content: "ﺎ";
}

.ti-barrier-block-off:before {
  content: "";
}

.ti-baseline:before {
  content: "";
}

.ti-baseline-density-large:before {
  content: "藺";
}

.ti-baseline-density-medium:before {
  content: "隣";
}

.ti-baseline-density-small:before {
  content: "鱗";
}

.ti-basket:before {
  content: "";
}

.ti-basket-bolt:before {
  content: "ףּ";
}

.ti-basket-cancel:before {
  content: "פּ";
}

.ti-basket-check:before {
  content: "﭅";
}

.ti-basket-code:before {
  content: "צּ";
}

.ti-basket-cog:before {
  content: "קּ";
}

.ti-basket-discount:before {
  content: "רּ";
}

.ti-basket-dollar:before {
  content: "שּ";
}

.ti-basket-down:before {
  content: "תּ";
}

.ti-basket-exclamation:before {
  content: "וֹ";
}

.ti-basket-filled:before {
  content: "";
}

.ti-basket-heart:before {
  content: "בֿ";
}

.ti-basket-minus:before {
  content: "כֿ";
}

.ti-basket-off:before {
  content: "";
}

.ti-basket-pause:before {
  content: "פֿ";
}

.ti-basket-pin:before {
  content: "ﭏ";
}

.ti-basket-plus:before {
  content: "ﭐ";
}

.ti-basket-question:before {
  content: "ﭑ";
}

.ti-basket-search:before {
  content: "ﭒ";
}

.ti-basket-share:before {
  content: "ﭓ";
}

.ti-basket-star:before {
  content: "ﭔ";
}

.ti-basket-up:before {
  content: "ﭕ";
}

.ti-basket-x:before {
  content: "ﭖ";
}

.ti-bat:before {
  content: "";
}

.ti-bath:before {
  content: "";
}

.ti-bath-filled:before {
  content: "";
}

.ti-bath-off:before {
  content: "";
}

.ti-battery:before {
  content: "";
}

.ti-battery-1:before {
  content: "";
}

.ti-battery-1-filled:before {
  content: "";
}

.ti-battery-2:before {
  content: "";
}

.ti-battery-2-filled:before {
  content: "";
}

.ti-battery-3:before {
  content: "";
}

.ti-battery-3-filled:before {
  content: "";
}

.ti-battery-4:before {
  content: "";
}

.ti-battery-4-filled:before {
  content: "";
}

.ti-battery-automotive:before {
  content: "";
}

.ti-battery-charging:before {
  content: "";
}

.ti-battery-charging-2:before {
  content: "";
}

.ti-battery-eco:before {
  content: "";
}

.ti-battery-filled:before {
  content: "";
}

.ti-battery-off:before {
  content: "";
}

.ti-beach:before {
  content: "";
}

.ti-beach-off:before {
  content: "";
}

.ti-bed:before {
  content: "";
}

.ti-bed-filled:before {
  content: "";
}

.ti-bed-flat:before {
  content: "ﲡ";
}

.ti-bed-flat-filled:before {
  content: "ﺍ";
}

.ti-bed-off:before {
  content: "";
}

.ti-beer:before {
  content: "";
}

.ti-beer-filled:before {
  content: "";
}

.ti-beer-off:before {
  content: "";
}

.ti-bell:before {
  content: "";
}

.ti-bell-bolt:before {
  content: "";
}

.ti-bell-cancel:before {
  content: "";
}

.ti-bell-check:before {
  content: "";
}

.ti-bell-code:before {
  content: "";
}

.ti-bell-cog:before {
  content: "";
}

.ti-bell-dollar:before {
  content: "";
}

.ti-bell-down:before {
  content: "";
}

.ti-bell-exclamation:before {
  content: "";
}

.ti-bell-filled:before {
  content: "";
}

.ti-bell-heart:before {
  content: "";
}

.ti-bell-minus:before {
  content: "";
}

.ti-bell-minus-filled:before {
  content: "";
}

.ti-bell-off:before {
  content: "";
}

.ti-bell-pause:before {
  content: "";
}

.ti-bell-pin:before {
  content: "";
}

.ti-bell-plus:before {
  content: "";
}

.ti-bell-plus-filled:before {
  content: "";
}

.ti-bell-question:before {
  content: "";
}

.ti-bell-ringing:before {
  content: "";
}

.ti-bell-ringing-2:before {
  content: "";
}

.ti-bell-ringing-2-filled:before {
  content: "";
}

.ti-bell-ringing-filled:before {
  content: "";
}

.ti-bell-school:before {
  content: "";
}

.ti-bell-search:before {
  content: "";
}

.ti-bell-share:before {
  content: "";
}

.ti-bell-star:before {
  content: "";
}

.ti-bell-up:before {
  content: "";
}

.ti-bell-x:before {
  content: "";
}

.ti-bell-x-filled:before {
  content: "";
}

.ti-bell-z:before {
  content: "";
}

.ti-bell-z-filled:before {
  content: "";
}

.ti-beta:before {
  content: "";
}

.ti-bible:before {
  content: "";
}

.ti-bike:before {
  content: "";
}

.ti-bike-off:before {
  content: "";
}

.ti-binary:before {
  content: "";
}

.ti-binary-off:before {
  content: "";
}

.ti-binary-tree:before {
  content: "";
}

.ti-binary-tree-2:before {
  content: "";
}

.ti-biohazard:before {
  content: "";
}

.ti-biohazard-filled:before {
  content: "ﺌ";
}

.ti-biohazard-off:before {
  content: "";
}

.ti-blade:before {
  content: "";
}

.ti-blade-filled:before {
  content: "";
}

.ti-bleach:before {
  content: "";
}

.ti-bleach-chlorine:before {
  content: "";
}

.ti-bleach-no-chlorine:before {
  content: "";
}

.ti-bleach-off:before {
  content: "";
}

.ti-blend-mode:before {
  content: "ﺰ";
}

.ti-blender:before {
  content: "ﲢ";
}

.ti-blob:before {
  content: "ﺯ";
}

.ti-blob-filled:before {
  content: "ﺱ";
}

.ti-blockquote:before {
  content: "";
}

.ti-bluetooth:before {
  content: "";
}

.ti-bluetooth-connected:before {
  content: "";
}

.ti-bluetooth-off:before {
  content: "";
}

.ti-bluetooth-x:before {
  content: "";
}

.ti-blur:before {
  content: "";
}

.ti-blur-off:before {
  content: "";
}

.ti-bmp:before {
  content: "";
}

.ti-body-scan:before {
  content: "ﲣ";
}

.ti-bold:before {
  content: "";
}

.ti-bold-off:before {
  content: "";
}

.ti-bolt:before {
  content: "";
}

.ti-bolt-off:before {
  content: "";
}

.ti-bomb:before {
  content: "";
}

.ti-bomb-filled:before {
  content: "惘";
}

.ti-bone:before {
  content: "";
}

.ti-bone-filled:before {
  content: "ﺋ";
}

.ti-bone-off:before {
  content: "";
}

.ti-bong:before {
  content: "";
}

.ti-bong-off:before {
  content: "";
}

.ti-book:before {
  content: "";
}

.ti-book-2:before {
  content: "";
}

.ti-book-download:before {
  content: "";
}

.ti-book-filled:before {
  content: "慎";
}

.ti-book-off:before {
  content: "";
}

.ti-book-upload:before {
  content: "";
}

.ti-bookmark:before {
  content: "";
}

.ti-bookmark-ai:before {
  content: "ﲊ";
}

.ti-bookmark-edit:before {
  content: "艹";
}

.ti-bookmark-filled:before {
  content: "愈";
}

.ti-bookmark-minus:before {
  content: "著";
}

.ti-bookmark-off:before {
  content: "";
}

.ti-bookmark-plus:before {
  content: "褐";
}

.ti-bookmark-question:before {
  content: "視";
}

.ti-bookmarks:before {
  content: "";
}

.ti-bookmarks-filled:before {
  content: "ײַ";
}

.ti-bookmarks-off:before {
  content: "";
}

.ti-books:before {
  content: "";
}

.ti-books-off:before {
  content: "";
}

.ti-boom:before {
  content: "ﶾ";
}

.ti-boom-filled:before {
  content: "ﺊ";
}

.ti-border-all:before {
  content: "";
}

.ti-border-bottom:before {
  content: "";
}

.ti-border-bottom-plus:before {
  content: "ﶽ";
}

.ti-border-corner-ios:before {
  content: "ﶘ";
}

.ti-border-corner-pill:before {
  content: "ﵢ";
}

.ti-border-corner-rounded:before {
  content: "ﵣ";
}

.ti-border-corner-square:before {
  content: "ﵤ";
}

.ti-border-corners:before {
  content: "";
}

.ti-border-horizontal:before {
  content: "";
}

.ti-border-inner:before {
  content: "";
}

.ti-border-left:before {
  content: "";
}

.ti-border-left-plus:before {
  content: "ﶼ";
}

.ti-border-none:before {
  content: "";
}

.ti-border-outer:before {
  content: "";
}

.ti-border-radius:before {
  content: "";
}

.ti-border-right:before {
  content: "";
}

.ti-border-right-plus:before {
  content: "ﶻ";
}

.ti-border-sides:before {
  content: "";
}

.ti-border-style:before {
  content: "";
}

.ti-border-style-2:before {
  content: "";
}

.ti-border-top:before {
  content: "";
}

.ti-border-top-plus:before {
  content: "ﶺ";
}

.ti-border-vertical:before {
  content: "";
}

.ti-bottle:before {
  content: "";
}

.ti-bottle-filled:before {
  content: "憎";
}

.ti-bottle-off:before {
  content: "";
}

.ti-bounce-left:before {
  content: "";
}

.ti-bounce-left-filled:before {
  content: "ﬠ";
}

.ti-bounce-right:before {
  content: "";
}

.ti-bounce-right-filled:before {
  content: "ﬡ";
}

.ti-bow:before {
  content: "";
}

.ti-bow-filled:before {
  content: "ﺉ";
}

.ti-bowl:before {
  content: "";
}

.ti-bowl-chopsticks:before {
  content: "﶐";
}

.ti-bowl-chopsticks-filled:before {
  content: "ﺈ";
}

.ti-bowl-filled:before {
  content: "ﬢ";
}

.ti-bowl-spoon:before {
  content: "﶑";
}

.ti-bowl-spoon-filled:before {
  content: "ﺇ";
}

.ti-box:before {
  content: "";
}

.ti-box-align-bottom:before {
  content: "";
}

.ti-box-align-bottom-filled:before {
  content: "慠";
}

.ti-box-align-bottom-left:before {
  content: "";
}

.ti-box-align-bottom-left-filled:before {
  content: "懲";
}

.ti-box-align-bottom-right:before {
  content: "";
}

.ti-box-align-bottom-right-filled:before {
  content: "戴";
}

.ti-box-align-left:before {
  content: "";
}

.ti-box-align-left-filled:before {
  content: "揄";
}

.ti-box-align-right:before {
  content: "";
}

.ti-box-align-right-filled:before {
  content: "搜";
}

.ti-box-align-top:before {
  content: "";
}

.ti-box-align-top-filled:before {
  content: "摒";
}

.ti-box-align-top-left:before {
  content: "";
}

.ti-box-align-top-left-filled:before {
  content: "敖";
}

.ti-box-align-top-right:before {
  content: "";
}

.ti-box-align-top-right-filled:before {
  content: "晴";
}

.ti-box-margin:before {
  content: "";
}

.ti-box-model:before {
  content: "";
}

.ti-box-model-2:before {
  content: "";
}

.ti-box-model-2-off:before {
  content: "";
}

.ti-box-model-off:before {
  content: "";
}

.ti-box-multiple:before {
  content: "";
}

.ti-box-multiple-0:before {
  content: "";
}

.ti-box-multiple-1:before {
  content: "";
}

.ti-box-multiple-2:before {
  content: "";
}

.ti-box-multiple-3:before {
  content: "";
}

.ti-box-multiple-4:before {
  content: "";
}

.ti-box-multiple-5:before {
  content: "";
}

.ti-box-multiple-6:before {
  content: "";
}

.ti-box-multiple-7:before {
  content: "";
}

.ti-box-multiple-8:before {
  content: "";
}

.ti-box-multiple-9:before {
  content: "";
}

.ti-box-off:before {
  content: "";
}

.ti-box-padding:before {
  content: "";
}

.ti-braces:before {
  content: "";
}

.ti-braces-off:before {
  content: "";
}

.ti-brackets:before {
  content: "";
}

.ti-brackets-angle:before {
  content: "ﲲ";
}

.ti-brackets-angle-off:before {
  content: "ﲱ";
}

.ti-brackets-contain:before {
  content: "";
}

.ti-brackets-contain-end:before {
  content: "";
}

.ti-brackets-contain-start:before {
  content: "";
}

.ti-brackets-off:before {
  content: "";
}

.ti-braille:before {
  content: "";
}

.ti-brain:before {
  content: "";
}

.ti-brand-4chan:before {
  content: "";
}

.ti-brand-abstract:before {
  content: "";
}

.ti-brand-adobe:before {
  content: "";
}

.ti-brand-adonis-js:before {
  content: "";
}

.ti-brand-airbnb:before {
  content: "";
}

.ti-brand-airtable:before {
  content: "";
}

.ti-brand-algolia:before {
  content: "";
}

.ti-brand-alipay:before {
  content: "";
}

.ti-brand-alpine-js:before {
  content: "";
}

.ti-brand-amazon:before {
  content: "";
}

.ti-brand-amd:before {
  content: "";
}

.ti-brand-amigo:before {
  content: "";
}

.ti-brand-among-us:before {
  content: "";
}

.ti-brand-android:before {
  content: "";
}

.ti-brand-angular:before {
  content: "";
}

.ti-brand-ansible:before {
  content: "並";
}

.ti-brand-ao3:before {
  content: "";
}

.ti-brand-appgallery:before {
  content: "";
}

.ti-brand-apple:before {
  content: "";
}

.ti-brand-apple-arcade:before {
  content: "";
}

.ti-brand-apple-filled:before {
  content: "ﵴ";
}

.ti-brand-apple-podcast:before {
  content: "";
}

.ti-brand-appstore:before {
  content: "";
}

.ti-brand-arc:before {
  content: "ﺮ";
}

.ti-brand-asana:before {
  content: "";
}

.ti-brand-astro:before {
  content: "ﶹ";
}

.ti-brand-auth0:before {
  content: "ﲳ";
}

.ti-brand-aws:before {
  content: "社";
}

.ti-brand-azure:before {
  content: "祉";
}

.ti-brand-backbone:before {
  content: "";
}

.ti-brand-badoo:before {
  content: "";
}

.ti-brand-baidu:before {
  content: "";
}

.ti-brand-bandcamp:before {
  content: "";
}

.ti-brand-bandlab:before {
  content: "";
}

.ti-brand-beats:before {
  content: "";
}

.ti-brand-behance:before {
  content: "";
}

.ti-brand-bilibili:before {
  content: "";
}

.ti-brand-binance:before {
  content: "";
}

.ti-brand-bing:before {
  content: "";
}

.ti-brand-bitbucket:before {
  content: "";
}

.ti-brand-blackberry:before {
  content: "";
}

.ti-brand-blender:before {
  content: "";
}

.ti-brand-blogger:before {
  content: "";
}

.ti-brand-bluesky:before {
  content: "ﵵ";
}

.ti-brand-booking:before {
  content: "";
}

.ti-brand-bootstrap:before {
  content: "";
}

.ti-brand-bulma:before {
  content: "";
}

.ti-brand-bumble:before {
  content: "";
}

.ti-brand-bunpo:before {
  content: "";
}

.ti-brand-c-sharp:before {
  content: "";
}

.ti-brand-cake:before {
  content: "";
}

.ti-brand-cakephp:before {
  content: "";
}

.ti-brand-campaignmonitor:before {
  content: "";
}

.ti-brand-carbon:before {
  content: "";
}

.ti-brand-cashapp:before {
  content: "";
}

.ti-brand-chrome:before {
  content: "";
}

.ti-brand-cinema-4d:before {
  content: "况";
}

.ti-brand-citymapper:before {
  content: "";
}

.ti-brand-cloudflare:before {
  content: "祈";
}

.ti-brand-codecov:before {
  content: "";
}

.ti-brand-codepen:before {
  content: "";
}

.ti-brand-codesandbox:before {
  content: "";
}

.ti-brand-cohost:before {
  content: "";
}

.ti-brand-coinbase:before {
  content: "";
}

.ti-brand-comedy-central:before {
  content: "";
}

.ti-brand-coreos:before {
  content: "";
}

.ti-brand-couchdb:before {
  content: "";
}

.ti-brand-couchsurfing:before {
  content: "";
}

.ti-brand-cpp:before {
  content: "";
}

.ti-brand-craft:before {
  content: "全";
}

.ti-brand-crunchbase:before {
  content: "";
}

.ti-brand-css3:before {
  content: "";
}

.ti-brand-ctemplar:before {
  content: "";
}

.ti-brand-cucumber:before {
  content: "";
}

.ti-brand-cupra:before {
  content: "";
}

.ti-brand-cypress:before {
  content: "";
}

.ti-brand-d3:before {
  content: "";
}

.ti-brand-databricks:before {
  content: "ﱁ";
}

.ti-brand-days-counter:before {
  content: "";
}

.ti-brand-dcos:before {
  content: "";
}

.ti-brand-debian:before {
  content: "";
}

.ti-brand-deezer:before {
  content: "";
}

.ti-brand-deliveroo:before {
  content: "";
}

.ti-brand-deno:before {
  content: "";
}

.ti-brand-denodo:before {
  content: "";
}

.ti-brand-deviantart:before {
  content: "";
}

.ti-brand-digg:before {
  content: "侀";
}

.ti-brand-dingtalk:before {
  content: "";
}

.ti-brand-discord:before {
  content: "";
}

.ti-brand-discord-filled:before {
  content: "";
}

.ti-brand-disney:before {
  content: "";
}

.ti-brand-disqus:before {
  content: "";
}

.ti-brand-django:before {
  content: "";
}

.ti-brand-docker:before {
  content: "";
}

.ti-brand-doctrine:before {
  content: "";
}

.ti-brand-dolby-digital:before {
  content: "";
}

.ti-brand-douban:before {
  content: "";
}

.ti-brand-dribbble:before {
  content: "";
}

.ti-brand-dribbble-filled:before {
  content: "";
}

.ti-brand-drops:before {
  content: "";
}

.ti-brand-drupal:before {
  content: "";
}

.ti-brand-edge:before {
  content: "";
}

.ti-brand-elastic:before {
  content: "";
}

.ti-brand-electronic-arts:before {
  content: "充";
}

.ti-brand-ember:before {
  content: "";
}

.ti-brand-envato:before {
  content: "";
}

.ti-brand-etsy:before {
  content: "";
}

.ti-brand-evernote:before {
  content: "";
}

.ti-brand-facebook:before {
  content: "";
}

.ti-brand-facebook-filled:before {
  content: "";
}

.ti-brand-feedly:before {
  content: "冀";
}

.ti-brand-figma:before {
  content: "";
}

.ti-brand-filezilla:before {
  content: "勇";
}

.ti-brand-finder:before {
  content: "";
}

.ti-brand-firebase:before {
  content: "";
}

.ti-brand-firefox:before {
  content: "";
}

.ti-brand-fiverr:before {
  content: "";
}

.ti-brand-flickr:before {
  content: "";
}

.ti-brand-flightradar24:before {
  content: "";
}

.ti-brand-flipboard:before {
  content: "";
}

.ti-brand-flutter:before {
  content: "";
}

.ti-brand-fortnite:before {
  content: "";
}

.ti-brand-foursquare:before {
  content: "";
}

.ti-brand-framer:before {
  content: "";
}

.ti-brand-framer-motion:before {
  content: "";
}

.ti-brand-funimation:before {
  content: "";
}

.ti-brand-gatsby:before {
  content: "";
}

.ti-brand-git:before {
  content: "";
}

.ti-brand-github:before {
  content: "";
}

.ti-brand-github-copilot:before {
  content: "";
}

.ti-brand-github-filled:before {
  content: "";
}

.ti-brand-gitlab:before {
  content: "";
}

.ti-brand-gmail:before {
  content: "";
}

.ti-brand-golang:before {
  content: "";
}

.ti-brand-google:before {
  content: "";
}

.ti-brand-google-analytics:before {
  content: "";
}

.ti-brand-google-big-query:before {
  content: "";
}

.ti-brand-google-drive:before {
  content: "";
}

.ti-brand-google-filled:before {
  content: "ﴚ";
}

.ti-brand-google-fit:before {
  content: "";
}

.ti-brand-google-home:before {
  content: "";
}

.ti-brand-google-maps:before {
  content: "祐";
}

.ti-brand-google-one:before {
  content: "";
}

.ti-brand-google-photos:before {
  content: "";
}

.ti-brand-google-play:before {
  content: "";
}

.ti-brand-google-podcasts:before {
  content: "";
}

.ti-brand-grammarly:before {
  content: "";
}

.ti-brand-graphql:before {
  content: "";
}

.ti-brand-gravatar:before {
  content: "";
}

.ti-brand-grindr:before {
  content: "";
}

.ti-brand-guardian:before {
  content: "";
}

.ti-brand-gumroad:before {
  content: "";
}

.ti-brand-hbo:before {
  content: "";
}

.ti-brand-headlessui:before {
  content: "";
}

.ti-brand-hexo:before {
  content: "祖";
}

.ti-brand-hipchat:before {
  content: "";
}

.ti-brand-html5:before {
  content: "";
}

.ti-brand-inertia:before {
  content: "";
}

.ti-brand-instagram:before {
  content: "";
}

.ti-brand-intercom:before {
  content: "";
}

.ti-brand-itch:before {
  content: "諸";
}

.ti-brand-javascript:before {
  content: "";
}

.ti-brand-juejin:before {
  content: "";
}

.ti-brand-kako-talk:before {
  content: "ﴭ";
}

.ti-brand-kbin:before {
  content: "𢡄";
}

.ti-brand-kick:before {
  content: "﨣";
}

.ti-brand-kickstarter:before {
  content: "";
}

.ti-brand-kotlin:before {
  content: "";
}

.ti-brand-laravel:before {
  content: "";
}

.ti-brand-lastfm:before {
  content: "";
}

.ti-brand-leetcode:before {
  content: "祝";
}

.ti-brand-letterboxd:before {
  content: "﨤";
}

.ti-brand-line:before {
  content: "";
}

.ti-brand-linkedin:before {
  content: "";
}

.ti-brand-linktree:before {
  content: "";
}

.ti-brand-linqpad:before {
  content: "";
}

.ti-brand-livewire:before {
  content: "ﵶ";
}

.ti-brand-loom:before {
  content: "";
}

.ti-brand-mailgun:before {
  content: "";
}

.ti-brand-mantine:before {
  content: "";
}

.ti-brand-mastercard:before {
  content: "";
}

.ti-brand-mastodon:before {
  content: "";
}

.ti-brand-matrix:before {
  content: "";
}

.ti-brand-mcdonalds:before {
  content: "";
}

.ti-brand-medium:before {
  content: "";
}

.ti-brand-meetup:before {
  content: "ﱪ";
}

.ti-brand-mercedes:before {
  content: "";
}

.ti-brand-messenger:before {
  content: "";
}

.ti-brand-meta:before {
  content: "";
}

.ti-brand-minecraft:before {
  content: "﫯";
}

.ti-brand-miniprogram:before {
  content: "";
}

.ti-brand-mixpanel:before {
  content: "";
}

.ti-brand-monday:before {
  content: "";
}

.ti-brand-mongodb:before {
  content: "";
}

.ti-brand-my-oppo:before {
  content: "";
}

.ti-brand-mysql:before {
  content: "";
}

.ti-brand-national-geographic:before {
  content: "";
}

.ti-brand-nem:before {
  content: "";
}

.ti-brand-netbeans:before {
  content: "";
}

.ti-brand-netease-music:before {
  content: "";
}

.ti-brand-netflix:before {
  content: "";
}

.ti-brand-nexo:before {
  content: "";
}

.ti-brand-nextcloud:before {
  content: "";
}

.ti-brand-nextjs:before {
  content: "";
}

.ti-brand-nodejs:before {
  content: "﫠";
}

.ti-brand-nord-vpn:before {
  content: "";
}

.ti-brand-notion:before {
  content: "";
}

.ti-brand-npm:before {
  content: "";
}

.ti-brand-nuxt:before {
  content: "";
}

.ti-brand-nytimes:before {
  content: "";
}

.ti-brand-oauth:before {
  content: "禍";
}

.ti-brand-office:before {
  content: "";
}

.ti-brand-ok-ru:before {
  content: "";
}

.ti-brand-onedrive:before {
  content: "";
}

.ti-brand-onlyfans:before {
  content: "";
}

.ti-brand-open-source:before {
  content: "";
}

.ti-brand-openai:before {
  content: "";
}

.ti-brand-openvpn:before {
  content: "";
}

.ti-brand-opera:before {
  content: "";
}

.ti-brand-pagekit:before {
  content: "";
}

.ti-brand-parsinta:before {
  content: "ﱂ";
}

.ti-brand-patreon:before {
  content: "";
}

.ti-brand-patreon-filled:before {
  content: "ﳿ";
}

.ti-brand-paypal:before {
  content: "";
}

.ti-brand-paypal-filled:before {
  content: "";
}

.ti-brand-paypay:before {
  content: "";
}

.ti-brand-peanut:before {
  content: "";
}

.ti-brand-pepsi:before {
  content: "";
}

.ti-brand-php:before {
  content: "";
}

.ti-brand-picsart:before {
  content: "";
}

.ti-brand-pinterest:before {
  content: "";
}

.ti-brand-planetscale:before {
  content: "";
}

.ti-brand-pnpm:before {
  content: "ﵷ";
}

.ti-brand-pocket:before {
  content: "";
}

.ti-brand-polymer:before {
  content: "";
}

.ti-brand-powershell:before {
  content: "";
}

.ti-brand-printables:before {
  content: "ﴛ";
}

.ti-brand-prisma:before {
  content: "";
}

.ti-brand-producthunt:before {
  content: "";
}

.ti-brand-pushbullet:before {
  content: "";
}

.ti-brand-pushover:before {
  content: "";
}

.ti-brand-python:before {
  content: "";
}

.ti-brand-qq:before {
  content: "";
}

.ti-brand-radix-ui:before {
  content: "";
}

.ti-brand-react:before {
  content: "";
}

.ti-brand-react-native:before {
  content: "";
}

.ti-brand-reason:before {
  content: "";
}

.ti-brand-reddit:before {
  content: "";
}

.ti-brand-redhat:before {
  content: "";
}

.ti-brand-redux:before {
  content: "";
}

.ti-brand-revolut:before {
  content: "";
}

.ti-brand-rumble:before {
  content: "𣏕";
}

.ti-brand-rust:before {
  content: "禎";
}

.ti-brand-safari:before {
  content: "";
}

.ti-brand-samsungpass:before {
  content: "";
}

.ti-brand-sass:before {
  content: "";
}

.ti-brand-sentry:before {
  content: "";
}

.ti-brand-sharik:before {
  content: "";
}

.ti-brand-shazam:before {
  content: "";
}

.ti-brand-shopee:before {
  content: "";
}

.ti-brand-sketch:before {
  content: "";
}

.ti-brand-skype:before {
  content: "";
}

.ti-brand-slack:before {
  content: "";
}

.ti-brand-snapchat:before {
  content: "";
}

.ti-brand-snapseed:before {
  content: "";
}

.ti-brand-snowflake:before {
  content: "";
}

.ti-brand-socket-io:before {
  content: "";
}

.ti-brand-solidjs:before {
  content: "";
}

.ti-brand-soundcloud:before {
  content: "";
}

.ti-brand-spacehey:before {
  content: "";
}

.ti-brand-speedtest:before {
  content: "勺";
}

.ti-brand-spotify:before {
  content: "";
}

.ti-brand-spotify-filled:before {
  content: "ﺆ";
}

.ti-brand-stackoverflow:before {
  content: "";
}

.ti-brand-stackshare:before {
  content: "";
}

.ti-brand-steam:before {
  content: "";
}

.ti-brand-stocktwits:before {
  content: "ﵸ";
}

.ti-brand-storj:before {
  content: "穀";
}

.ti-brand-storybook:before {
  content: "";
}

.ti-brand-storytel:before {
  content: "";
}

.ti-brand-strava:before {
  content: "";
}

.ti-brand-stripe:before {
  content: "";
}

.ti-brand-sublime-text:before {
  content: "";
}

.ti-brand-sugarizer:before {
  content: "";
}

.ti-brand-supabase:before {
  content: "";
}

.ti-brand-superhuman:before {
  content: "";
}

.ti-brand-supernova:before {
  content: "";
}

.ti-brand-surfshark:before {
  content: "";
}

.ti-brand-svelte:before {
  content: "";
}

.ti-brand-swift:before {
  content: "突";
}

.ti-brand-symfony:before {
  content: "";
}

.ti-brand-tabler:before {
  content: "";
}

.ti-brand-tailwind:before {
  content: "";
}

.ti-brand-taobao:before {
  content: "";
}

.ti-brand-teams:before {
  content: "﫟";
}

.ti-brand-ted:before {
  content: "";
}

.ti-brand-telegram:before {
  content: "";
}

.ti-brand-terraform:before {
  content: "節";
}

.ti-brand-tether:before {
  content: "";
}

.ti-brand-thingiverse:before {
  content: "ﴜ";
}

.ti-brand-threads:before {
  content: "ﬂ";
}

.ti-brand-threejs:before {
  content: "";
}

.ti-brand-tidal:before {
  content: "";
}

.ti-brand-tiktok:before {
  content: "";
}

.ti-brand-tiktok-filled:before {
  content: "";
}

.ti-brand-tinder:before {
  content: "";
}

.ti-brand-topbuzz:before {
  content: "";
}

.ti-brand-torchain:before {
  content: "";
}

.ti-brand-toyota:before {
  content: "";
}

.ti-brand-trello:before {
  content: "";
}

.ti-brand-tripadvisor:before {
  content: "";
}

.ti-brand-tumblr:before {
  content: "";
}

.ti-brand-twilio:before {
  content: "";
}

.ti-brand-twitch:before {
  content: "";
}

.ti-brand-twitter:before {
  content: "";
}

.ti-brand-twitter-filled:before {
  content: "";
}

.ti-brand-typescript:before {
  content: "";
}

.ti-brand-uber:before {
  content: "";
}

.ti-brand-ubuntu:before {
  content: "";
}

.ti-brand-unity:before {
  content: "";
}

.ti-brand-unsplash:before {
  content: "";
}

.ti-brand-upwork:before {
  content: "";
}

.ti-brand-valorant:before {
  content: "";
}

.ti-brand-vercel:before {
  content: "";
}

.ti-brand-vimeo:before {
  content: "";
}

.ti-brand-vinted:before {
  content: "";
}

.ti-brand-visa:before {
  content: "";
}

.ti-brand-visual-studio:before {
  content: "";
}

.ti-brand-vite:before {
  content: "";
}

.ti-brand-vivaldi:before {
  content: "";
}

.ti-brand-vk:before {
  content: "";
}

.ti-brand-vlc:before {
  content: "喝";
}

.ti-brand-volkswagen:before {
  content: "";
}

.ti-brand-vsco:before {
  content: "";
}

.ti-brand-vscode:before {
  content: "";
}

.ti-brand-vue:before {
  content: "";
}

.ti-brand-walmart:before {
  content: "";
}

.ti-brand-waze:before {
  content: "";
}

.ti-brand-webflow:before {
  content: "";
}

.ti-brand-wechat:before {
  content: "";
}

.ti-brand-weibo:before {
  content: "";
}

.ti-brand-whatsapp:before {
  content: "";
}

.ti-brand-wikipedia:before {
  content: "啕";
}

.ti-brand-windows:before {
  content: "";
}

.ti-brand-windy:before {
  content: "";
}

.ti-brand-wish:before {
  content: "";
}

.ti-brand-wix:before {
  content: "";
}

.ti-brand-wordpress:before {
  content: "";
}

.ti-brand-x:before {
  content: "ﰏ";
}

.ti-brand-x-filled:before {
  content: "ﰡ";
}

.ti-brand-xamarin:before {
  content: "喙";
}

.ti-brand-xbox:before {
  content: "";
}

.ti-brand-xdeep:before {
  content: "ﰐ";
}

.ti-brand-xing:before {
  content: "";
}

.ti-brand-yahoo:before {
  content: "";
}

.ti-brand-yandex:before {
  content: "﫡";
}

.ti-brand-yarn:before {
  content: "ﵹ";
}

.ti-brand-yatse:before {
  content: "";
}

.ti-brand-ycombinator:before {
  content: "";
}

.ti-brand-youtube:before {
  content: "";
}

.ti-brand-youtube-filled:before {
  content: "ﰢ";
}

.ti-brand-youtube-kids:before {
  content: "";
}

.ti-brand-zalando:before {
  content: "";
}

.ti-brand-zapier:before {
  content: "";
}

.ti-brand-zeit:before {
  content: "";
}

.ti-brand-zhihu:before {
  content: "";
}

.ti-brand-zoom:before {
  content: "";
}

.ti-brand-zulip:before {
  content: "";
}

.ti-brand-zwift:before {
  content: "";
}

.ti-bread:before {
  content: "";
}

.ti-bread-filled:before {
  content: "ﺅ";
}

.ti-bread-off:before {
  content: "";
}

.ti-briefcase:before {
  content: "";
}

.ti-briefcase-2:before {
  content: "ﬃ";
}

.ti-briefcase-2-filled:before {
  content: "ﺄ";
}

.ti-briefcase-filled:before {
  content: "ﴀ";
}

.ti-briefcase-off:before {
  content: "";
}

.ti-brightness:before {
  content: "";
}

.ti-brightness-2:before {
  content: "";
}

.ti-brightness-auto:before {
  content: "ﶙ";
}

.ti-brightness-auto-filled:before {
  content: "ﺃ";
}

.ti-brightness-down:before {
  content: "";
}

.ti-brightness-down-filled:before {
  content: "ﬣ";
}

.ti-brightness-filled:before {
  content: "ﺂ";
}

.ti-brightness-half:before {
  content: "";
}

.ti-brightness-off:before {
  content: "";
}

.ti-brightness-up:before {
  content: "";
}

.ti-brightness-up-filled:before {
  content: "ﬤ";
}

.ti-broadcast:before {
  content: "";
}

.ti-broadcast-off:before {
  content: "";
}

.ti-browser:before {
  content: "";
}

.ti-browser-check:before {
  content: "";
}

.ti-browser-off:before {
  content: "";
}

.ti-browser-plus:before {
  content: "";
}

.ti-browser-x:before {
  content: "";
}

.ti-brush:before {
  content: "";
}

.ti-brush-off:before {
  content: "";
}

.ti-bubble:before {
  content: "ﺺ";
}

.ti-bubble-filled:before {
  content: "ﻃ";
}

.ti-bubble-minus:before {
  content: "ﺾ";
}

.ti-bubble-plus:before {
  content: "ﺽ";
}

.ti-bubble-text:before {
  content: "ﺼ";
}

.ti-bubble-x:before {
  content: "ﺻ";
}

.ti-bucket:before {
  content: "";
}

.ti-bucket-droplet:before {
  content: "";
}

.ti-bucket-off:before {
  content: "";
}

.ti-bug:before {
  content: "";
}

.ti-bug-filled:before {
  content: "ﴁ";
}

.ti-bug-off:before {
  content: "";
}

.ti-building:before {
  content: "";
}

.ti-building-arch:before {
  content: "";
}

.ti-building-bank:before {
  content: "";
}

.ti-building-bridge:before {
  content: "";
}

.ti-building-bridge-2:before {
  content: "";
}

.ti-building-broadcast-tower:before {
  content: "";
}

.ti-building-broadcast-tower-filled:before {
  content: "ﺁ";
}

.ti-building-carousel:before {
  content: "";
}

.ti-building-castle:before {
  content: "";
}

.ti-building-church:before {
  content: "";
}

.ti-building-circus:before {
  content: "";
}

.ti-building-community:before {
  content: "";
}

.ti-building-cottage:before {
  content: "";
}

.ti-building-estate:before {
  content: "";
}

.ti-building-factory:before {
  content: "";
}

.ti-building-factory-2:before {
  content: "";
}

.ti-building-fortress:before {
  content: "";
}

.ti-building-hospital:before {
  content: "";
}

.ti-building-lighthouse:before {
  content: "";
}

.ti-building-monument:before {
  content: "";
}

.ti-building-mosque:before {
  content: "練";
}

.ti-building-pavilion:before {
  content: "";
}

.ti-building-skyscraper:before {
  content: "";
}

.ti-building-stadium:before {
  content: "";
}

.ti-building-store:before {
  content: "";
}

.ti-building-tunnel:before {
  content: "";
}

.ti-building-warehouse:before {
  content: "";
}

.ti-building-wind-turbine:before {
  content: "";
}

.ti-bulb:before {
  content: "";
}

.ti-bulb-filled:before {
  content: "";
}

.ti-bulb-off:before {
  content: "";
}

.ti-bulldozer:before {
  content: "";
}

.ti-burger:before {
  content: "ﲴ";
}

.ti-bus:before {
  content: "";
}

.ti-bus-off:before {
  content: "";
}

.ti-bus-stop:before {
  content: "";
}

.ti-businessplan:before {
  content: "";
}

.ti-butterfly:before {
  content: "";
}

.ti-cactus:before {
  content: "";
}

.ti-cactus-filled:before {
  content: "ﬥ";
}

.ti-cactus-off:before {
  content: "";
}

.ti-cake:before {
  content: "";
}

.ti-cake-off:before {
  content: "";
}

.ti-calculator:before {
  content: "";
}

.ti-calculator-filled:before {
  content: "ﬦ";
}

.ti-calculator-off:before {
  content: "";
}

.ti-calendar:before {
  content: "";
}

.ti-calendar-bolt:before {
  content: "";
}

.ti-calendar-cancel:before {
  content: "";
}

.ti-calendar-check:before {
  content: "";
}

.ti-calendar-clock:before {
  content: "ﴮ";
}

.ti-calendar-code:before {
  content: "";
}

.ti-calendar-cog:before {
  content: "";
}

.ti-calendar-dollar:before {
  content: "";
}

.ti-calendar-dot:before {
  content: "﴾";
}

.ti-calendar-down:before {
  content: "";
}

.ti-calendar-due:before {
  content: "";
}

.ti-calendar-event:before {
  content: "";
}

.ti-calendar-exclamation:before {
  content: "";
}

.ti-calendar-filled:before {
  content: "ﬧ";
}

.ti-calendar-heart:before {
  content: "";
}

.ti-calendar-minus:before {
  content: "";
}

.ti-calendar-month:before {
  content: "ﴯ";
}

.ti-calendar-off:before {
  content: "";
}

.ti-calendar-pause:before {
  content: "";
}

.ti-calendar-pin:before {
  content: "";
}

.ti-calendar-plus:before {
  content: "";
}

.ti-calendar-question:before {
  content: "";
}

.ti-calendar-repeat:before {
  content: "㮝";
}

.ti-calendar-sad:before {
  content: "ﴝ";
}

.ti-calendar-search:before {
  content: "";
}

.ti-calendar-share:before {
  content: "";
}

.ti-calendar-smile:before {
  content: "ﴞ";
}

.ti-calendar-star:before {
  content: "";
}

.ti-calendar-stats:before {
  content: "";
}

.ti-calendar-time:before {
  content: "";
}

.ti-calendar-up:before {
  content: "";
}

.ti-calendar-user:before {
  content: "ﴟ";
}

.ti-calendar-week:before {
  content: "ﴰ";
}

.ti-calendar-x:before {
  content: "";
}

.ti-camera:before {
  content: "";
}

.ti-camera-bolt:before {
  content: "";
}

.ti-camera-cancel:before {
  content: "";
}

.ti-camera-check:before {
  content: "";
}

.ti-camera-code:before {
  content: "";
}

.ti-camera-cog:before {
  content: "";
}

.ti-camera-dollar:before {
  content: "";
}

.ti-camera-down:before {
  content: "";
}

.ti-camera-exclamation:before {
  content: "";
}

.ti-camera-filled:before {
  content: "嘆";
}

.ti-camera-heart:before {
  content: "";
}

.ti-camera-minus:before {
  content: "";
}

.ti-camera-off:before {
  content: "";
}

.ti-camera-pause:before {
  content: "";
}

.ti-camera-pin:before {
  content: "";
}

.ti-camera-plus:before {
  content: "";
}

.ti-camera-question:before {
  content: "";
}

.ti-camera-rotate:before {
  content: "";
}

.ti-camera-search:before {
  content: "";
}

.ti-camera-selfie:before {
  content: "";
}

.ti-camera-share:before {
  content: "";
}

.ti-camera-star:before {
  content: "";
}

.ti-camera-up:before {
  content: "";
}

.ti-camera-x:before {
  content: "";
}

.ti-camper:before {
  content: "逸";
}

.ti-campfire:before {
  content: "";
}

.ti-campfire-filled:before {
  content: "ﬨ";
}

.ti-candle:before {
  content: "";
}

.ti-candle-filled:before {
  content: "ﰣ";
}

.ti-candy:before {
  content: "";
}

.ti-candy-off:before {
  content: "";
}

.ti-cane:before {
  content: "";
}

.ti-cannabis:before {
  content: "";
}

.ti-capsule:before {
  content: "﫣";
}

.ti-capsule-filled:before {
  content: "ﰤ";
}

.ti-capsule-horizontal:before {
  content: "﫢";
}

.ti-capsule-horizontal-filled:before {
  content: "ﰥ";
}

.ti-capture:before {
  content: "";
}

.ti-capture-filled:before {
  content: "﬩";
}

.ti-capture-off:before {
  content: "";
}

.ti-car:before {
  content: "";
}

.ti-car-4wd:before {
  content: "ﶸ";
}

.ti-car-crane:before {
  content: "";
}

.ti-car-crash:before {
  content: "";
}

.ti-car-fan:before {
  content: "ﶳ";
}

.ti-car-fan-1:before {
  content: "ﶷ";
}

.ti-car-fan-2:before {
  content: "ﶶ";
}

.ti-car-fan-3:before {
  content: "ﶵ";
}

.ti-car-fan-auto:before {
  content: "ﶴ";
}

.ti-car-garage:before {
  content: "ﱷ";
}

.ti-car-off:before {
  content: "";
}

.ti-car-suv:before {
  content: "ﲋ";
}

.ti-car-turbine:before {
  content: "";
}

.ti-carambola:before {
  content: "ﺹ";
}

.ti-caravan:before {
  content: "";
}

.ti-cardboards:before {
  content: "";
}

.ti-cardboards-off:before {
  content: "";
}

.ti-cards:before {
  content: "";
}

.ti-cards-filled:before {
  content: "ﰦ";
}

.ti-caret-down:before {
  content: "";
}

.ti-caret-down-filled:before {
  content: "שׁ";
}

.ti-caret-left:before {
  content: "";
}

.ti-caret-left-filled:before {
  content: "שׂ";
}

.ti-caret-left-right:before {
  content: "ﱃ";
}

.ti-caret-left-right-filled:before {
  content: "ﴂ";
}

.ti-caret-right:before {
  content: "";
}

.ti-caret-right-filled:before {
  content: "שּׁ";
}

.ti-caret-up:before {
  content: "";
}

.ti-caret-up-down:before {
  content: "ﱄ";
}

.ti-caret-up-down-filled:before {
  content: "ﴃ";
}

.ti-caret-up-filled:before {
  content: "שּׂ";
}

.ti-carousel-horizontal:before {
  content: "";
}

.ti-carousel-horizontal-filled:before {
  content: "朗";
}

.ti-carousel-vertical:before {
  content: "";
}

.ti-carousel-vertical-filled:before {
  content: "望";
}

.ti-carrot:before {
  content: "";
}

.ti-carrot-off:before {
  content: "";
}

.ti-cash:before {
  content: "";
}

.ti-cash-banknote:before {
  content: "";
}

.ti-cash-banknote-filled:before {
  content: "ﺀ";
}

.ti-cash-banknote-off:before {
  content: "";
}

.ti-cash-off:before {
  content: "";
}

.ti-cast:before {
  content: "";
}

.ti-cast-off:before {
  content: "";
}

.ti-cat:before {
  content: "";
}

.ti-category:before {
  content: "";
}

.ti-category-2:before {
  content: "";
}

.ti-category-filled:before {
  content: "אַ";
}

.ti-category-minus:before {
  content: "ﴠ";
}

.ti-category-plus:before {
  content: "ﴡ";
}

.ti-ce:before {
  content: "";
}

.ti-ce-off:before {
  content: "";
}

.ti-cell:before {
  content: "";
}

.ti-cell-signal-1:before {
  content: "";
}

.ti-cell-signal-2:before {
  content: "";
}

.ti-cell-signal-3:before {
  content: "";
}

.ti-cell-signal-4:before {
  content: "";
}

.ti-cell-signal-5:before {
  content: "";
}

.ti-cell-signal-off:before {
  content: "";
}

.ti-certificate:before {
  content: "";
}

.ti-certificate-2:before {
  content: "";
}

.ti-certificate-2-off:before {
  content: "";
}

.ti-certificate-off:before {
  content: "";
}

.ti-chair-director:before {
  content: "";
}

.ti-chalkboard:before {
  content: "";
}

.ti-chalkboard-off:before {
  content: "";
}

.ti-charging-pile:before {
  content: "";
}

.ti-chart-arcs:before {
  content: "";
}

.ti-chart-arcs-3:before {
  content: "";
}

.ti-chart-area:before {
  content: "";
}

.ti-chart-area-filled:before {
  content: "";
}

.ti-chart-area-line:before {
  content: "";
}

.ti-chart-area-line-filled:before {
  content: "";
}

.ti-chart-arrows:before {
  content: "";
}

.ti-chart-arrows-vertical:before {
  content: "";
}

.ti-chart-bar:before {
  content: "";
}

.ti-chart-bar-off:before {
  content: "";
}

.ti-chart-bubble:before {
  content: "";
}

.ti-chart-bubble-filled:before {
  content: "";
}

.ti-chart-candle:before {
  content: "";
}

.ti-chart-candle-filled:before {
  content: "";
}

.ti-chart-circles:before {
  content: "";
}

.ti-chart-donut:before {
  content: "";
}

.ti-chart-donut-2:before {
  content: "";
}

.ti-chart-donut-3:before {
  content: "";
}

.ti-chart-donut-4:before {
  content: "";
}

.ti-chart-donut-filled:before {
  content: "";
}

.ti-chart-dots:before {
  content: "";
}

.ti-chart-dots-2:before {
  content: "";
}

.ti-chart-dots-3:before {
  content: "";
}

.ti-chart-dots-filled:before {
  content: "ﴄ";
}

.ti-chart-grid-dots:before {
  content: "";
}

.ti-chart-grid-dots-filled:before {
  content: "ﴅ";
}

.ti-chart-histogram:before {
  content: "";
}

.ti-chart-infographic:before {
  content: "";
}

.ti-chart-line:before {
  content: "";
}

.ti-chart-pie:before {
  content: "";
}

.ti-chart-pie-2:before {
  content: "";
}

.ti-chart-pie-3:before {
  content: "";
}

.ti-chart-pie-4:before {
  content: "";
}

.ti-chart-pie-filled:before {
  content: "";
}

.ti-chart-pie-off:before {
  content: "";
}

.ti-chart-ppf:before {
  content: "";
}

.ti-chart-radar:before {
  content: "";
}

.ti-chart-sankey:before {
  content: "";
}

.ti-chart-scatter:before {
  content: "ﶓ";
}

.ti-chart-scatter-3d:before {
  content: "ﶒ";
}

.ti-chart-treemap:before {
  content: "";
}

.ti-check:before {
  content: "";
}

.ti-checkbox:before {
  content: "";
}

.ti-checklist:before {
  content: "";
}

.ti-checks:before {
  content: "";
}

.ti-checkup-list:before {
  content: "";
}

.ti-cheese:before {
  content: "";
}

.ti-chef-hat:before {
  content: "";
}

.ti-chef-hat-off:before {
  content: "";
}

.ti-cherry:before {
  content: "";
}

.ti-cherry-filled:before {
  content: "";
}

.ti-chess:before {
  content: "";
}

.ti-chess-bishop:before {
  content: "";
}

.ti-chess-bishop-filled:before {
  content: "";
}

.ti-chess-filled:before {
  content: "";
}

.ti-chess-king:before {
  content: "";
}

.ti-chess-king-filled:before {
  content: "";
}

.ti-chess-knight:before {
  content: "";
}

.ti-chess-knight-filled:before {
  content: "";
}

.ti-chess-queen:before {
  content: "";
}

.ti-chess-queen-filled:before {
  content: "";
}

.ti-chess-rook:before {
  content: "";
}

.ti-chess-rook-filled:before {
  content: "";
}

.ti-chevron-compact-down:before {
  content: "﫰";
}

.ti-chevron-compact-left:before {
  content: "﫱";
}

.ti-chevron-compact-right:before {
  content: "﫲";
}

.ti-chevron-compact-up:before {
  content: "﫳";
}

.ti-chevron-down:before {
  content: "";
}

.ti-chevron-down-left:before {
  content: "";
}

.ti-chevron-down-right:before {
  content: "";
}

.ti-chevron-left:before {
  content: "";
}

.ti-chevron-left-pipe:before {
  content: "﫤";
}

.ti-chevron-right:before {
  content: "";
}

.ti-chevron-right-pipe:before {
  content: "﫥";
}

.ti-chevron-up:before {
  content: "";
}

.ti-chevron-up-left:before {
  content: "";
}

.ti-chevron-up-right:before {
  content: "";
}

.ti-chevrons-down:before {
  content: "";
}

.ti-chevrons-down-left:before {
  content: "";
}

.ti-chevrons-down-right:before {
  content: "";
}

.ti-chevrons-left:before {
  content: "";
}

.ti-chevrons-right:before {
  content: "";
}

.ti-chevrons-up:before {
  content: "";
}

.ti-chevrons-up-left:before {
  content: "";
}

.ti-chevrons-up-right:before {
  content: "";
}

.ti-chisel:before {
  content: "";
}

.ti-christmas-ball:before {
  content: "ﴱ";
}

.ti-christmas-tree:before {
  content: "";
}

.ti-christmas-tree-off:before {
  content: "";
}

.ti-circle:before {
  content: "";
}

.ti-circle-arrow-down:before {
  content: "";
}

.ti-circle-arrow-down-filled:before {
  content: "";
}

.ti-circle-arrow-down-left:before {
  content: "";
}

.ti-circle-arrow-down-left-filled:before {
  content: "";
}

.ti-circle-arrow-down-right:before {
  content: "";
}

.ti-circle-arrow-down-right-filled:before {
  content: "";
}

.ti-circle-arrow-left:before {
  content: "";
}

.ti-circle-arrow-left-filled:before {
  content: "";
}

.ti-circle-arrow-right:before {
  content: "";
}

.ti-circle-arrow-right-filled:before {
  content: "";
}

.ti-circle-arrow-up:before {
  content: "";
}

.ti-circle-arrow-up-filled:before {
  content: "";
}

.ti-circle-arrow-up-left:before {
  content: "";
}

.ti-circle-arrow-up-left-filled:before {
  content: "";
}

.ti-circle-arrow-up-right:before {
  content: "";
}

.ti-circle-arrow-up-right-filled:before {
  content: "";
}

.ti-circle-caret-down:before {
  content: "";
}

.ti-circle-caret-left:before {
  content: "";
}

.ti-circle-caret-right:before {
  content: "";
}

.ti-circle-caret-up:before {
  content: "";
}

.ti-circle-check:before {
  content: "";
}

.ti-circle-check-filled:before {
  content: "";
}

.ti-circle-chevron-down:before {
  content: "";
}

.ti-circle-chevron-left:before {
  content: "";
}

.ti-circle-chevron-right:before {
  content: "";
}

.ti-circle-chevron-up:before {
  content: "";
}

.ti-circle-chevrons-down:before {
  content: "";
}

.ti-circle-chevrons-left:before {
  content: "";
}

.ti-circle-chevrons-right:before {
  content: "";
}

.ti-circle-chevrons-up:before {
  content: "";
}

.ti-circle-dashed:before {
  content: "";
}

.ti-circle-dashed-check:before {
  content: "ﺸ";
}

.ti-circle-dashed-minus:before {
  content: "ﺷ";
}

.ti-circle-dashed-number-0:before {
  content: "ﱫ";
}

.ti-circle-dashed-number-1:before {
  content: "ﱬ";
}

.ti-circle-dashed-number-2:before {
  content: "ﱭ";
}

.ti-circle-dashed-number-3:before {
  content: "ﱮ";
}

.ti-circle-dashed-number-4:before {
  content: "ﱯ";
}

.ti-circle-dashed-number-5:before {
  content: "ﱰ";
}

.ti-circle-dashed-number-6:before {
  content: "ﱱ";
}

.ti-circle-dashed-number-7:before {
  content: "ﱲ";
}

.ti-circle-dashed-number-8:before {
  content: "ﱳ";
}

.ti-circle-dashed-number-9:before {
  content: "ﱴ";
}

.ti-circle-dashed-percentage:before {
  content: "ﵺ";
}

.ti-circle-dashed-plus:before {
  content: "ﺶ";
}

.ti-circle-dashed-x:before {
  content: "ﱵ";
}

.ti-circle-dot:before {
  content: "";
}

.ti-circle-dot-filled:before {
  content: "";
}

.ti-circle-dotted:before {
  content: "";
}

.ti-circle-filled:before {
  content: "";
}

.ti-circle-half:before {
  content: "";
}

.ti-circle-half-2:before {
  content: "";
}

.ti-circle-half-vertical:before {
  content: "";
}

.ti-circle-key:before {
  content: "";
}

.ti-circle-key-filled:before {
  content: "";
}

.ti-circle-letter-a:before {
  content: "";
}

.ti-circle-letter-a-filled:before {
  content: "ﹿ";
}

.ti-circle-letter-b:before {
  content: "";
}

.ti-circle-letter-b-filled:before {
  content: "ﹾ";
}

.ti-circle-letter-c:before {
  content: "";
}

.ti-circle-letter-c-filled:before {
  content: "ﹽ";
}

.ti-circle-letter-d:before {
  content: "";
}

.ti-circle-letter-d-filled:before {
  content: "ﹼ";
}

.ti-circle-letter-e:before {
  content: "";
}

.ti-circle-letter-e-filled:before {
  content: "ﹻ";
}

.ti-circle-letter-f:before {
  content: "";
}

.ti-circle-letter-f-filled:before {
  content: "ﹺ";
}

.ti-circle-letter-g:before {
  content: "";
}

.ti-circle-letter-g-filled:before {
  content: "ﹹ";
}

.ti-circle-letter-h:before {
  content: "";
}

.ti-circle-letter-h-filled:before {
  content: "ﹸ";
}

.ti-circle-letter-i:before {
  content: "";
}

.ti-circle-letter-i-filled:before {
  content: "ﹷ";
}

.ti-circle-letter-j:before {
  content: "";
}

.ti-circle-letter-j-filled:before {
  content: "ﹶ";
}

.ti-circle-letter-k:before {
  content: "";
}

.ti-circle-letter-k-filled:before {
  content: "﹵";
}

.ti-circle-letter-l:before {
  content: "";
}

.ti-circle-letter-l-filled:before {
  content: "ﹴ";
}

.ti-circle-letter-m:before {
  content: "";
}

.ti-circle-letter-m-filled:before {
  content: "ﹳ";
}

.ti-circle-letter-n:before {
  content: "";
}

.ti-circle-letter-n-filled:before {
  content: "ﹲ";
}

.ti-circle-letter-o:before {
  content: "";
}

.ti-circle-letter-o-filled:before {
  content: "ﹱ";
}

.ti-circle-letter-p:before {
  content: "";
}

.ti-circle-letter-p-filled:before {
  content: "ﹰ";
}

.ti-circle-letter-q:before {
  content: "";
}

.ti-circle-letter-q-filled:before {
  content: "﹯";
}

.ti-circle-letter-r:before {
  content: "";
}

.ti-circle-letter-r-filled:before {
  content: "﹮";
}

.ti-circle-letter-s:before {
  content: "";
}

.ti-circle-letter-s-filled:before {
  content: "﹭";
}

.ti-circle-letter-t:before {
  content: "";
}

.ti-circle-letter-t-filled:before {
  content: "﹬";
}

.ti-circle-letter-u:before {
  content: "";
}

.ti-circle-letter-u-filled:before {
  content: "﹫";
}

.ti-circle-letter-v:before {
  content: "";
}

.ti-circle-letter-v-filled:before {
  content: "﹪";
}

.ti-circle-letter-w:before {
  content: "";
}

.ti-circle-letter-w-filled:before {
  content: "﹩";
}

.ti-circle-letter-x:before {
  content: "";
}

.ti-circle-letter-x-filled:before {
  content: "﹨";
}

.ti-circle-letter-y:before {
  content: "";
}

.ti-circle-letter-y-filled:before {
  content: "﹧";
}

.ti-circle-letter-z:before {
  content: "";
}

.ti-circle-letter-z-filled:before {
  content: "﹦";
}

.ti-circle-minus:before {
  content: "";
}

.ti-circle-minus-2:before {
  content: "ﲌ";
}

.ti-circle-number-0:before {
  content: "";
}

.ti-circle-number-0-filled:before {
  content: "";
}

.ti-circle-number-1:before {
  content: "";
}

.ti-circle-number-1-filled:before {
  content: "";
}

.ti-circle-number-2:before {
  content: "";
}

.ti-circle-number-2-filled:before {
  content: "";
}

.ti-circle-number-3:before {
  content: "";
}

.ti-circle-number-3-filled:before {
  content: "";
}

.ti-circle-number-4:before {
  content: "";
}

.ti-circle-number-4-filled:before {
  content: "";
}

.ti-circle-number-5:before {
  content: "";
}

.ti-circle-number-5-filled:before {
  content: "";
}

.ti-circle-number-6:before {
  content: "";
}

.ti-circle-number-6-filled:before {
  content: "";
}

.ti-circle-number-7:before {
  content: "";
}

.ti-circle-number-7-filled:before {
  content: "";
}

.ti-circle-number-8:before {
  content: "";
}

.ti-circle-number-8-filled:before {
  content: "";
}

.ti-circle-number-9:before {
  content: "";
}

.ti-circle-number-9-filled:before {
  content: "";
}

.ti-circle-off:before {
  content: "";
}

.ti-circle-percentage:before {
  content: "ﵻ";
}

.ti-circle-plus:before {
  content: "";
}

.ti-circle-plus-2:before {
  content: "ﲍ";
}

.ti-circle-rectangle:before {
  content: "";
}

.ti-circle-rectangle-off:before {
  content: "";
}

.ti-circle-square:before {
  content: "";
}

.ti-circle-triangle:before {
  content: "";
}

.ti-circle-x:before {
  content: "";
}

.ti-circle-x-filled:before {
  content: "";
}

.ti-circles:before {
  content: "";
}

.ti-circles-filled:before {
  content: "";
}

.ti-circles-relation:before {
  content: "";
}

.ti-circuit-ammeter:before {
  content: "";
}

.ti-circuit-battery:before {
  content: "";
}

.ti-circuit-bulb:before {
  content: "";
}

.ti-circuit-capacitor:before {
  content: "";
}

.ti-circuit-capacitor-polarized:before {
  content: "";
}

.ti-circuit-cell:before {
  content: "";
}

.ti-circuit-cell-plus:before {
  content: "";
}

.ti-circuit-changeover:before {
  content: "";
}

.ti-circuit-diode:before {
  content: "";
}

.ti-circuit-diode-zener:before {
  content: "";
}

.ti-circuit-ground:before {
  content: "";
}

.ti-circuit-ground-digital:before {
  content: "";
}

.ti-circuit-inductor:before {
  content: "";
}

.ti-circuit-motor:before {
  content: "";
}

.ti-circuit-pushbutton:before {
  content: "";
}

.ti-circuit-resistor:before {
  content: "";
}

.ti-circuit-switch-closed:before {
  content: "";
}

.ti-circuit-switch-open:before {
  content: "";
}

.ti-circuit-voltmeter:before {
  content: "";
}

.ti-clear-all:before {
  content: "";
}

.ti-clear-formatting:before {
  content: "";
}

.ti-click:before {
  content: "";
}

.ti-clipboard:before {
  content: "";
}

.ti-clipboard-check:before {
  content: "";
}

.ti-clipboard-copy:before {
  content: "";
}

.ti-clipboard-data:before {
  content: "";
}

.ti-clipboard-heart:before {
  content: "";
}

.ti-clipboard-list:before {
  content: "";
}

.ti-clipboard-off:before {
  content: "";
}

.ti-clipboard-plus:before {
  content: "";
}

.ti-clipboard-smile:before {
  content: "ﶚ";
}

.ti-clipboard-text:before {
  content: "";
}

.ti-clipboard-typography:before {
  content: "";
}

.ti-clipboard-x:before {
  content: "";
}

.ti-clock:before {
  content: "";
}

.ti-clock-12:before {
  content: "ﱖ";
}

.ti-clock-2:before {
  content: "";
}

.ti-clock-24:before {
  content: "ﱗ";
}

.ti-clock-bolt:before {
  content: "";
}

.ti-clock-cancel:before {
  content: "";
}

.ti-clock-check:before {
  content: "";
}

.ti-clock-code:before {
  content: "";
}

.ti-clock-cog:before {
  content: "";
}

.ti-clock-dollar:before {
  content: "";
}

.ti-clock-down:before {
  content: "";
}

.ti-clock-edit:before {
  content: "";
}

.ti-clock-exclamation:before {
  content: "";
}

.ti-clock-filled:before {
  content: "";
}

.ti-clock-heart:before {
  content: "";
}

.ti-clock-hour-1:before {
  content: "";
}

.ti-clock-hour-1-filled:before {
  content: "﹥";
}

.ti-clock-hour-10:before {
  content: "";
}

.ti-clock-hour-10-filled:before {
  content: "﹤";
}

.ti-clock-hour-11:before {
  content: "";
}

.ti-clock-hour-11-filled:before {
  content: "﹣";
}

.ti-clock-hour-12:before {
  content: "";
}

.ti-clock-hour-12-filled:before {
  content: "﹢";
}

.ti-clock-hour-2:before {
  content: "";
}

.ti-clock-hour-2-filled:before {
  content: "﹡";
}

.ti-clock-hour-3:before {
  content: "";
}

.ti-clock-hour-3-filled:before {
  content: "﹠";
}

.ti-clock-hour-4:before {
  content: "";
}

.ti-clock-hour-4-filled:before {
  content: "﹟";
}

.ti-clock-hour-5:before {
  content: "";
}

.ti-clock-hour-5-filled:before {
  content: "﹞";
}

.ti-clock-hour-6:before {
  content: "";
}

.ti-clock-hour-6-filled:before {
  content: "﹝";
}

.ti-clock-hour-7:before {
  content: "";
}

.ti-clock-hour-7-filled:before {
  content: "﹜";
}

.ti-clock-hour-8:before {
  content: "";
}

.ti-clock-hour-8-filled:before {
  content: "﹛";
}

.ti-clock-hour-9:before {
  content: "";
}

.ti-clock-hour-9-filled:before {
  content: "﹚";
}

.ti-clock-minus:before {
  content: "";
}

.ti-clock-off:before {
  content: "";
}

.ti-clock-pause:before {
  content: "";
}

.ti-clock-pin:before {
  content: "";
}

.ti-clock-play:before {
  content: "";
}

.ti-clock-plus:before {
  content: "";
}

.ti-clock-question:before {
  content: "";
}

.ti-clock-record:before {
  content: "";
}

.ti-clock-search:before {
  content: "";
}

.ti-clock-share:before {
  content: "";
}

.ti-clock-shield:before {
  content: "";
}

.ti-clock-star:before {
  content: "";
}

.ti-clock-stop:before {
  content: "";
}

.ti-clock-up:before {
  content: "";
}

.ti-clock-x:before {
  content: "";
}

.ti-clothes-rack:before {
  content: "";
}

.ti-clothes-rack-off:before {
  content: "";
}

.ti-cloud:before {
  content: "";
}

.ti-cloud-bolt:before {
  content: "";
}

.ti-cloud-cancel:before {
  content: "";
}

.ti-cloud-check:before {
  content: "";
}

.ti-cloud-code:before {
  content: "";
}

.ti-cloud-cog:before {
  content: "";
}

.ti-cloud-computing:before {
  content: "";
}

.ti-cloud-data-connection:before {
  content: "";
}

.ti-cloud-dollar:before {
  content: "";
}

.ti-cloud-down:before {
  content: "";
}

.ti-cloud-download:before {
  content: "";
}

.ti-cloud-exclamation:before {
  content: "";
}

.ti-cloud-filled:before {
  content: "";
}

.ti-cloud-fog:before {
  content: "";
}

.ti-cloud-heart:before {
  content: "";
}

.ti-cloud-lock:before {
  content: "";
}

.ti-cloud-lock-open:before {
  content: "";
}

.ti-cloud-minus:before {
  content: "";
}

.ti-cloud-network:before {
  content: "ﱸ";
}

.ti-cloud-off:before {
  content: "";
}

.ti-cloud-pause:before {
  content: "";
}

.ti-cloud-pin:before {
  content: "";
}

.ti-cloud-plus:before {
  content: "";
}

.ti-cloud-question:before {
  content: "";
}

.ti-cloud-rain:before {
  content: "";
}

.ti-cloud-search:before {
  content: "";
}

.ti-cloud-share:before {
  content: "";
}

.ti-cloud-snow:before {
  content: "";
}

.ti-cloud-star:before {
  content: "";
}

.ti-cloud-storm:before {
  content: "";
}

.ti-cloud-up:before {
  content: "";
}

.ti-cloud-upload:before {
  content: "";
}

.ti-cloud-x:before {
  content: "";
}

.ti-clover:before {
  content: "";
}

.ti-clover-2:before {
  content: "";
}

.ti-clubs:before {
  content: "";
}

.ti-clubs-filled:before {
  content: "";
}

.ti-code:before {
  content: "";
}

.ti-code-asterisk:before {
  content: "";
}

.ti-code-circle:before {
  content: "";
}

.ti-code-circle-2:before {
  content: "";
}

.ti-code-dots:before {
  content: "";
}

.ti-code-minus:before {
  content: "";
}

.ti-code-off:before {
  content: "";
}

.ti-code-plus:before {
  content: "";
}

.ti-coffee:before {
  content: "";
}

.ti-coffee-off:before {
  content: "";
}

.ti-coffin:before {
  content: "";
}

.ti-coin:before {
  content: "";
}

.ti-coin-bitcoin:before {
  content: "";
}

.ti-coin-bitcoin-filled:before {
  content: "ﴆ";
}

.ti-coin-euro:before {
  content: "";
}

.ti-coin-euro-filled:before {
  content: "ﴇ";
}

.ti-coin-filled:before {
  content: "ﴈ";
}

.ti-coin-monero:before {
  content: "";
}

.ti-coin-monero-filled:before {
  content: "ﴉ";
}

.ti-coin-off:before {
  content: "";
}

.ti-coin-pound:before {
  content: "";
}

.ti-coin-pound-filled:before {
  content: "ﴊ";
}

.ti-coin-rupee:before {
  content: "";
}

.ti-coin-rupee-filled:before {
  content: "ﴋ";
}

.ti-coin-taka:before {
  content: "ﴍ";
}

.ti-coin-taka-filled:before {
  content: "ﴌ";
}

.ti-coin-yen:before {
  content: "";
}

.ti-coin-yen-filled:before {
  content: "ﴎ";
}

.ti-coin-yuan:before {
  content: "";
}

.ti-coin-yuan-filled:before {
  content: "ﴏ";
}

.ti-coins:before {
  content: "";
}

.ti-color-filter:before {
  content: "";
}

.ti-color-picker:before {
  content: "";
}

.ti-color-picker-off:before {
  content: "";
}

.ti-color-swatch:before {
  content: "";
}

.ti-color-swatch-off:before {
  content: "";
}

.ti-column-insert-left:before {
  content: "";
}

.ti-column-insert-right:before {
  content: "";
}

.ti-column-remove:before {
  content: "﫴";
}

.ti-columns:before {
  content: "";
}

.ti-columns-1:before {
  content: "";
}

.ti-columns-2:before {
  content: "";
}

.ti-columns-3:before {
  content: "";
}

.ti-columns-off:before {
  content: "";
}

.ti-comet:before {
  content: "";
}

.ti-command:before {
  content: "";
}

.ti-command-off:before {
  content: "";
}

.ti-compass:before {
  content: "";
}

.ti-compass-filled:before {
  content: "ﴐ";
}

.ti-compass-off:before {
  content: "";
}

.ti-components:before {
  content: "";
}

.ti-components-off:before {
  content: "";
}

.ti-cone:before {
  content: "";
}

.ti-cone-2:before {
  content: "";
}

.ti-cone-2-filled:before {
  content: "﹙";
}

.ti-cone-filled:before {
  content: "﹘";
}

.ti-cone-off:before {
  content: "";
}

.ti-cone-plus:before {
  content: "杖";
}

.ti-confetti:before {
  content: "";
}

.ti-confetti-off:before {
  content: "";
}

.ti-confucius:before {
  content: "";
}

.ti-container:before {
  content: "";
}

.ti-container-off:before {
  content: "";
}

.ti-contrast:before {
  content: "";
}

.ti-contrast-2:before {
  content: "";
}

.ti-contrast-2-filled:before {
  content: "﹗";
}

.ti-contrast-2-off:before {
  content: "";
}

.ti-contrast-filled:before {
  content: "﹖";
}

.ti-contrast-off:before {
  content: "";
}

.ti-cooker:before {
  content: "";
}

.ti-cookie:before {
  content: "ﶱ";
}

.ti-cookie-filled:before {
  content: "﹔";
}

.ti-cookie-man:before {
  content: "ﶲ";
}

.ti-cookie-man-filled:before {
  content: "﹕";
}

.ti-cookie-off:before {
  content: "";
}

.ti-copy:before {
  content: "";
}

.ti-copy-check:before {
  content: "ﶰ";
}

.ti-copy-check-filled:before {
  content: "﹓";
}

.ti-copy-minus:before {
  content: "ﶯ";
}

.ti-copy-minus-filled:before {
  content: "﹒";
}

.ti-copy-off:before {
  content: "";
}

.ti-copy-plus:before {
  content: "ﶮ";
}

.ti-copy-plus-filled:before {
  content: "﹑";
}

.ti-copy-x:before {
  content: "ﶭ";
}

.ti-copy-x-filled:before {
  content: "﹐";
}

.ti-copyleft:before {
  content: "";
}

.ti-copyleft-filled:before {
  content: "";
}

.ti-copyleft-off:before {
  content: "";
}

.ti-copyright:before {
  content: "";
}

.ti-copyright-filled:before {
  content: "";
}

.ti-copyright-off:before {
  content: "";
}

.ti-corner-down-left:before {
  content: "";
}

.ti-corner-down-left-double:before {
  content: "";
}

.ti-corner-down-right:before {
  content: "";
}

.ti-corner-down-right-double:before {
  content: "";
}

.ti-corner-left-down:before {
  content: "";
}

.ti-corner-left-down-double:before {
  content: "";
}

.ti-corner-left-up:before {
  content: "";
}

.ti-corner-left-up-double:before {
  content: "";
}

.ti-corner-right-down:before {
  content: "";
}

.ti-corner-right-down-double:before {
  content: "";
}

.ti-corner-right-up:before {
  content: "";
}

.ti-corner-right-up-double:before {
  content: "";
}

.ti-corner-up-left:before {
  content: "";
}

.ti-corner-up-left-double:before {
  content: "";
}

.ti-corner-up-right:before {
  content: "";
}

.ti-corner-up-right-double:before {
  content: "";
}

.ti-cpu:before {
  content: "";
}

.ti-cpu-2:before {
  content: "";
}

.ti-cpu-off:before {
  content: "";
}

.ti-crane:before {
  content: "";
}

.ti-crane-off:before {
  content: "";
}

.ti-creative-commons:before {
  content: "";
}

.ti-creative-commons-by:before {
  content: "";
}

.ti-creative-commons-nc:before {
  content: "";
}

.ti-creative-commons-nd:before {
  content: "";
}

.ti-creative-commons-off:before {
  content: "";
}

.ti-creative-commons-sa:before {
  content: "";
}

.ti-creative-commons-zero:before {
  content: "";
}

.ti-credit-card:before {
  content: "";
}

.ti-credit-card-filled:before {
  content: "ﴑ";
}

.ti-credit-card-off:before {
  content: "";
}

.ti-credit-card-pay:before {
  content: "ﴲ";
}

.ti-credit-card-refund:before {
  content: "ﴳ";
}

.ti-cricket:before {
  content: "";
}

.ti-crop:before {
  content: "";
}

.ti-crop-1-1:before {
  content: "ﵐ";
}

.ti-crop-1-1-filled:before {
  content: "﹏";
}

.ti-crop-16-9:before {
  content: "ﵑ";
}

.ti-crop-16-9-filled:before {
  content: "﹎";
}

.ti-crop-3-2:before {
  content: "ﵒ";
}

.ti-crop-3-2-filled:before {
  content: "﹍";
}

.ti-crop-5-4:before {
  content: "ﵓ";
}

.ti-crop-5-4-filled:before {
  content: "﹌";
}

.ti-crop-7-5:before {
  content: "ﵔ";
}

.ti-crop-7-5-filled:before {
  content: "﹋";
}

.ti-crop-landscape:before {
  content: "ﵕ";
}

.ti-crop-landscape-filled:before {
  content: "﹊";
}

.ti-crop-portrait:before {
  content: "ﵖ";
}

.ti-crop-portrait-filled:before {
  content: "﹉";
}

.ti-cross:before {
  content: "";
}

.ti-cross-filled:before {
  content: "";
}

.ti-cross-off:before {
  content: "";
}

.ti-crosshair:before {
  content: "";
}

.ti-crown:before {
  content: "";
}

.ti-crown-off:before {
  content: "";
}

.ti-crutches:before {
  content: "";
}

.ti-crutches-off:before {
  content: "";
}

.ti-crystal-ball:before {
  content: "";
}

.ti-csv:before {
  content: "";
}

.ti-cube:before {
  content: "流";
}

.ti-cube-3d-sphere:before {
  content: "";
}

.ti-cube-3d-sphere-off:before {
  content: "";
}

.ti-cube-off:before {
  content: "歹";
}

.ti-cube-plus:before {
  content: "殺";
}

.ti-cube-send:before {
  content: "";
}

.ti-cube-unfolded:before {
  content: "";
}

.ti-cup:before {
  content: "";
}

.ti-cup-off:before {
  content: "";
}

.ti-curling:before {
  content: "";
}

.ti-curly-loop:before {
  content: "";
}

.ti-currency:before {
  content: "";
}

.ti-currency-afghani:before {
  content: "";
}

.ti-currency-bahraini:before {
  content: "";
}

.ti-currency-baht:before {
  content: "";
}

.ti-currency-bitcoin:before {
  content: "";
}

.ti-currency-cent:before {
  content: "";
}

.ti-currency-dinar:before {
  content: "";
}

.ti-currency-dirham:before {
  content: "";
}

.ti-currency-dogecoin:before {
  content: "";
}

.ti-currency-dollar:before {
  content: "";
}

.ti-currency-dollar-australian:before {
  content: "";
}

.ti-currency-dollar-brunei:before {
  content: "";
}

.ti-currency-dollar-canadian:before {
  content: "";
}

.ti-currency-dollar-guyanese:before {
  content: "";
}

.ti-currency-dollar-off:before {
  content: "";
}

.ti-currency-dollar-singapore:before {
  content: "";
}

.ti-currency-dollar-zimbabwean:before {
  content: "";
}

.ti-currency-dong:before {
  content: "";
}

.ti-currency-dram:before {
  content: "";
}

.ti-currency-ethereum:before {
  content: "";
}

.ti-currency-euro:before {
  content: "";
}

.ti-currency-euro-off:before {
  content: "";
}

.ti-currency-florin:before {
  content: "﫵";
}

.ti-currency-forint:before {
  content: "";
}

.ti-currency-frank:before {
  content: "";
}

.ti-currency-guarani:before {
  content: "";
}

.ti-currency-hryvnia:before {
  content: "";
}

.ti-currency-iranian-rial:before {
  content: "縉";
}

.ti-currency-kip:before {
  content: "";
}

.ti-currency-krone-czech:before {
  content: "";
}

.ti-currency-krone-danish:before {
  content: "";
}

.ti-currency-krone-swedish:before {
  content: "";
}

.ti-currency-lari:before {
  content: "";
}

.ti-currency-leu:before {
  content: "";
}

.ti-currency-lira:before {
  content: "";
}

.ti-currency-litecoin:before {
  content: "";
}

.ti-currency-lyd:before {
  content: "";
}

.ti-currency-manat:before {
  content: "";
}

.ti-currency-monero:before {
  content: "";
}

.ti-currency-naira:before {
  content: "";
}

.ti-currency-nano:before {
  content: "";
}

.ti-currency-off:before {
  content: "";
}

.ti-currency-paanga:before {
  content: "";
}

.ti-currency-peso:before {
  content: "";
}

.ti-currency-pound:before {
  content: "";
}

.ti-currency-pound-off:before {
  content: "";
}

.ti-currency-quetzal:before {
  content: "";
}

.ti-currency-real:before {
  content: "";
}

.ti-currency-renminbi:before {
  content: "";
}

.ti-currency-ripple:before {
  content: "";
}

.ti-currency-riyal:before {
  content: "";
}

.ti-currency-rubel:before {
  content: "";
}

.ti-currency-rufiyaa:before {
  content: "";
}

.ti-currency-rupee:before {
  content: "";
}

.ti-currency-rupee-nepalese:before {
  content: "";
}

.ti-currency-shekel:before {
  content: "";
}

.ti-currency-solana:before {
  content: "";
}

.ti-currency-som:before {
  content: "";
}

.ti-currency-taka:before {
  content: "";
}

.ti-currency-tenge:before {
  content: "";
}

.ti-currency-tugrik:before {
  content: "";
}

.ti-currency-won:before {
  content: "";
}

.ti-currency-xrp:before {
  content: "ﴴ";
}

.ti-currency-yen:before {
  content: "";
}

.ti-currency-yen-off:before {
  content: "";
}

.ti-currency-yuan:before {
  content: "";
}

.ti-currency-zloty:before {
  content: "";
}

.ti-current-location:before {
  content: "";
}

.ti-current-location-off:before {
  content: "";
}

.ti-cursor-off:before {
  content: "";
}

.ti-cursor-text:before {
  content: "";
}

.ti-cut:before {
  content: "";
}

.ti-cylinder:before {
  content: "";
}

.ti-cylinder-off:before {
  content: "滛";
}

.ti-cylinder-plus:before {
  content: "滋";
}

.ti-dashboard:before {
  content: "";
}

.ti-dashboard-off:before {
  content: "";
}

.ti-database:before {
  content: "";
}

.ti-database-cog:before {
  content: "塚";
}

.ti-database-dollar:before {
  content: "﨑";
}

.ti-database-edit:before {
  content: "晴";
}

.ti-database-exclamation:before {
  content: "﨓";
}

.ti-database-export:before {
  content: "";
}

.ti-database-heart:before {
  content: "﨔";
}

.ti-database-import:before {
  content: "";
}

.ti-database-leak:before {
  content: "凞";
}

.ti-database-minus:before {
  content: "猪";
}

.ti-database-off:before {
  content: "";
}

.ti-database-plus:before {
  content: "益";
}

.ti-database-search:before {
  content: "礼";
}

.ti-database-share:before {
  content: "神";
}

.ti-database-smile:before {
  content: "ﶛ";
}

.ti-database-star:before {
  content: "祥";
}

.ti-database-x:before {
  content: "福";
}

.ti-decimal:before {
  content: "都";
}

.ti-deer:before {
  content: "";
}

.ti-delta:before {
  content: "";
}

.ti-dental:before {
  content: "";
}

.ti-dental-broken:before {
  content: "";
}

.ti-dental-off:before {
  content: "";
}

.ti-deselect:before {
  content: "麟";
}

.ti-desk:before {
  content: "ﴵ";
}

.ti-details:before {
  content: "";
}

.ti-details-off:before {
  content: "";
}

.ti-device-airpods:before {
  content: "";
}

.ti-device-airpods-case:before {
  content: "";
}

.ti-device-airtag:before {
  content: "﫦";
}

.ti-device-analytics:before {
  content: "";
}

.ti-device-audio-tape:before {
  content: "";
}

.ti-device-camera-phone:before {
  content: "";
}

.ti-device-cctv:before {
  content: "";
}

.ti-device-cctv-off:before {
  content: "";
}

.ti-device-computer-camera:before {
  content: "";
}

.ti-device-computer-camera-off:before {
  content: "";
}

.ti-device-desktop:before {
  content: "";
}

.ti-device-desktop-analytics:before {
  content: "";
}

.ti-device-desktop-bolt:before {
  content: "";
}

.ti-device-desktop-cancel:before {
  content: "";
}

.ti-device-desktop-check:before {
  content: "";
}

.ti-device-desktop-code:before {
  content: "";
}

.ti-device-desktop-cog:before {
  content: "";
}

.ti-device-desktop-dollar:before {
  content: "";
}

.ti-device-desktop-down:before {
  content: "";
}

.ti-device-desktop-exclamation:before {
  content: "";
}

.ti-device-desktop-heart:before {
  content: "";
}

.ti-device-desktop-minus:before {
  content: "";
}

.ti-device-desktop-off:before {
  content: "";
}

.ti-device-desktop-pause:before {
  content: "";
}

.ti-device-desktop-pin:before {
  content: "";
}

.ti-device-desktop-plus:before {
  content: "";
}

.ti-device-desktop-question:before {
  content: "";
}

.ti-device-desktop-search:before {
  content: "";
}

.ti-device-desktop-share:before {
  content: "";
}

.ti-device-desktop-star:before {
  content: "";
}

.ti-device-desktop-up:before {
  content: "";
}

.ti-device-desktop-x:before {
  content: "";
}

.ti-device-floppy:before {
  content: "";
}

.ti-device-gamepad:before {
  content: "";
}

.ti-device-gamepad-2:before {
  content: "";
}

.ti-device-gamepad-3:before {
  content: "ﱘ";
}

.ti-device-heart-monitor:before {
  content: "";
}

.ti-device-heart-monitor-filled:before {
  content: "器";
}

.ti-device-imac:before {
  content: "";
}

.ti-device-imac-bolt:before {
  content: "";
}

.ti-device-imac-cancel:before {
  content: "";
}

.ti-device-imac-check:before {
  content: "";
}

.ti-device-imac-code:before {
  content: "";
}

.ti-device-imac-cog:before {
  content: "";
}

.ti-device-imac-dollar:before {
  content: "";
}

.ti-device-imac-down:before {
  content: "";
}

.ti-device-imac-exclamation:before {
  content: "";
}

.ti-device-imac-heart:before {
  content: "";
}

.ti-device-imac-minus:before {
  content: "";
}

.ti-device-imac-off:before {
  content: "";
}

.ti-device-imac-pause:before {
  content: "";
}

.ti-device-imac-pin:before {
  content: "";
}

.ti-device-imac-plus:before {
  content: "";
}

.ti-device-imac-question:before {
  content: "";
}

.ti-device-imac-search:before {
  content: "";
}

.ti-device-imac-share:before {
  content: "";
}

.ti-device-imac-star:before {
  content: "";
}

.ti-device-imac-up:before {
  content: "";
}

.ti-device-imac-x:before {
  content: "";
}

.ti-device-ipad:before {
  content: "";
}

.ti-device-ipad-bolt:before {
  content: "";
}

.ti-device-ipad-cancel:before {
  content: "";
}

.ti-device-ipad-check:before {
  content: "";
}

.ti-device-ipad-code:before {
  content: "";
}

.ti-device-ipad-cog:before {
  content: "";
}

.ti-device-ipad-dollar:before {
  content: "";
}

.ti-device-ipad-down:before {
  content: "";
}

.ti-device-ipad-exclamation:before {
  content: "";
}

.ti-device-ipad-heart:before {
  content: "";
}

.ti-device-ipad-horizontal:before {
  content: "";
}

.ti-device-ipad-horizontal-bolt:before {
  content: "";
}

.ti-device-ipad-horizontal-cancel:before {
  content: "";
}

.ti-device-ipad-horizontal-check:before {
  content: "";
}

.ti-device-ipad-horizontal-code:before {
  content: "";
}

.ti-device-ipad-horizontal-cog:before {
  content: "";
}

.ti-device-ipad-horizontal-dollar:before {
  content: "";
}

.ti-device-ipad-horizontal-down:before {
  content: "";
}

.ti-device-ipad-horizontal-exclamation:before {
  content: "";
}

.ti-device-ipad-horizontal-heart:before {
  content: "";
}

.ti-device-ipad-horizontal-minus:before {
  content: "";
}

.ti-device-ipad-horizontal-off:before {
  content: "";
}

.ti-device-ipad-horizontal-pause:before {
  content: "";
}

.ti-device-ipad-horizontal-pin:before {
  content: "";
}

.ti-device-ipad-horizontal-plus:before {
  content: "";
}

.ti-device-ipad-horizontal-question:before {
  content: "";
}

.ti-device-ipad-horizontal-search:before {
  content: "";
}

.ti-device-ipad-horizontal-share:before {
  content: "";
}

.ti-device-ipad-horizontal-star:before {
  content: "";
}

.ti-device-ipad-horizontal-up:before {
  content: "";
}

.ti-device-ipad-horizontal-x:before {
  content: "";
}

.ti-device-ipad-minus:before {
  content: "";
}

.ti-device-ipad-off:before {
  content: "";
}

.ti-device-ipad-pause:before {
  content: "";
}

.ti-device-ipad-pin:before {
  content: "";
}

.ti-device-ipad-plus:before {
  content: "";
}

.ti-device-ipad-question:before {
  content: "";
}

.ti-device-ipad-search:before {
  content: "";
}

.ti-device-ipad-share:before {
  content: "";
}

.ti-device-ipad-star:before {
  content: "";
}

.ti-device-ipad-up:before {
  content: "";
}

.ti-device-ipad-x:before {
  content: "";
}

.ti-device-landline-phone:before {
  content: "";
}

.ti-device-laptop:before {
  content: "";
}

.ti-device-laptop-off:before {
  content: "";
}

.ti-device-mobile:before {
  content: "";
}

.ti-device-mobile-bolt:before {
  content: "";
}

.ti-device-mobile-cancel:before {
  content: "";
}

.ti-device-mobile-charging:before {
  content: "";
}

.ti-device-mobile-check:before {
  content: "";
}

.ti-device-mobile-code:before {
  content: "";
}

.ti-device-mobile-cog:before {
  content: "";
}

.ti-device-mobile-dollar:before {
  content: "";
}

.ti-device-mobile-down:before {
  content: "";
}

.ti-device-mobile-exclamation:before {
  content: "";
}

.ti-device-mobile-filled:before {
  content: "塀";
}

.ti-device-mobile-heart:before {
  content: "";
}

.ti-device-mobile-message:before {
  content: "";
}

.ti-device-mobile-minus:before {
  content: "";
}

.ti-device-mobile-off:before {
  content: "";
}

.ti-device-mobile-pause:before {
  content: "";
}

.ti-device-mobile-pin:before {
  content: "";
}

.ti-device-mobile-plus:before {
  content: "";
}

.ti-device-mobile-question:before {
  content: "";
}

.ti-device-mobile-rotated:before {
  content: "";
}

.ti-device-mobile-search:before {
  content: "";
}

.ti-device-mobile-share:before {
  content: "";
}

.ti-device-mobile-star:before {
  content: "";
}

.ti-device-mobile-up:before {
  content: "";
}

.ti-device-mobile-vibration:before {
  content: "";
}

.ti-device-mobile-x:before {
  content: "";
}

.ti-device-nintendo:before {
  content: "";
}

.ti-device-nintendo-off:before {
  content: "";
}

.ti-device-projector:before {
  content: "ﰑ";
}

.ti-device-remote:before {
  content: "";
}

.ti-device-sd-card:before {
  content: "";
}

.ti-device-sim:before {
  content: "";
}

.ti-device-sim-1:before {
  content: "";
}

.ti-device-sim-2:before {
  content: "";
}

.ti-device-sim-3:before {
  content: "";
}

.ti-device-speaker:before {
  content: "";
}

.ti-device-speaker-off:before {
  content: "";
}

.ti-device-tablet:before {
  content: "";
}

.ti-device-tablet-bolt:before {
  content: "";
}

.ti-device-tablet-cancel:before {
  content: "";
}

.ti-device-tablet-check:before {
  content: "";
}

.ti-device-tablet-code:before {
  content: "";
}

.ti-device-tablet-cog:before {
  content: "";
}

.ti-device-tablet-dollar:before {
  content: "";
}

.ti-device-tablet-down:before {
  content: "";
}

.ti-device-tablet-exclamation:before {
  content: "";
}

.ti-device-tablet-filled:before {
  content: "墨";
}

.ti-device-tablet-heart:before {
  content: "";
}

.ti-device-tablet-minus:before {
  content: "";
}

.ti-device-tablet-off:before {
  content: "";
}

.ti-device-tablet-pause:before {
  content: "";
}

.ti-device-tablet-pin:before {
  content: "";
}

.ti-device-tablet-plus:before {
  content: "";
}

.ti-device-tablet-question:before {
  content: "";
}

.ti-device-tablet-search:before {
  content: "";
}

.ti-device-tablet-share:before {
  content: "";
}

.ti-device-tablet-star:before {
  content: "";
}

.ti-device-tablet-up:before {
  content: "";
}

.ti-device-tablet-x:before {
  content: "";
}

.ti-device-tv:before {
  content: "";
}

.ti-device-tv-off:before {
  content: "";
}

.ti-device-tv-old:before {
  content: "";
}

.ti-device-usb:before {
  content: "ﱙ";
}

.ti-device-vision-pro:before {
  content: "﫧";
}

.ti-device-watch:before {
  content: "";
}

.ti-device-watch-bolt:before {
  content: "";
}

.ti-device-watch-cancel:before {
  content: "";
}

.ti-device-watch-check:before {
  content: "";
}

.ti-device-watch-code:before {
  content: "";
}

.ti-device-watch-cog:before {
  content: "";
}

.ti-device-watch-dollar:before {
  content: "";
}

.ti-device-watch-down:before {
  content: "";
}

.ti-device-watch-exclamation:before {
  content: "";
}

.ti-device-watch-heart:before {
  content: "";
}

.ti-device-watch-minus:before {
  content: "";
}

.ti-device-watch-off:before {
  content: "";
}

.ti-device-watch-pause:before {
  content: "";
}

.ti-device-watch-pin:before {
  content: "";
}

.ti-device-watch-plus:before {
  content: "";
}

.ti-device-watch-question:before {
  content: "";
}

.ti-device-watch-search:before {
  content: "";
}

.ti-device-watch-share:before {
  content: "";
}

.ti-device-watch-star:before {
  content: "";
}

.ti-device-watch-stats:before {
  content: "";
}

.ti-device-watch-stats-2:before {
  content: "";
}

.ti-device-watch-up:before {
  content: "";
}

.ti-device-watch-x:before {
  content: "";
}

.ti-devices:before {
  content: "";
}

.ti-devices-2:before {
  content: "";
}

.ti-devices-bolt:before {
  content: "";
}

.ti-devices-cancel:before {
  content: "";
}

.ti-devices-check:before {
  content: "";
}

.ti-devices-code:before {
  content: "";
}

.ti-devices-cog:before {
  content: "";
}

.ti-devices-dollar:before {
  content: "";
}

.ti-devices-down:before {
  content: "";
}

.ti-devices-exclamation:before {
  content: "";
}

.ti-devices-heart:before {
  content: "";
}

.ti-devices-minus:before {
  content: "";
}

.ti-devices-off:before {
  content: "";
}

.ti-devices-pause:before {
  content: "";
}

.ti-devices-pc:before {
  content: "";
}

.ti-devices-pc-off:before {
  content: "";
}

.ti-devices-pin:before {
  content: "";
}

.ti-devices-plus:before {
  content: "";
}

.ti-devices-question:before {
  content: "";
}

.ti-devices-search:before {
  content: "";
}

.ti-devices-share:before {
  content: "";
}

.ti-devices-star:before {
  content: "";
}

.ti-devices-up:before {
  content: "";
}

.ti-devices-x:before {
  content: "";
}

.ti-diabolo:before {
  content: "煮";
}

.ti-diabolo-off:before {
  content: "漢";
}

.ti-diabolo-plus:before {
  content: "瀞";
}

.ti-dialpad:before {
  content: "";
}

.ti-dialpad-filled:before {
  content: "層";
}

.ti-dialpad-off:before {
  content: "";
}

.ti-diamond:before {
  content: "";
}

.ti-diamond-filled:before {
  content: "";
}

.ti-diamond-off:before {
  content: "";
}

.ti-diamonds:before {
  content: "";
}

.ti-diamonds-filled:before {
  content: "";
}

.ti-dice:before {
  content: "";
}

.ti-dice-1:before {
  content: "";
}

.ti-dice-1-filled:before {
  content: "";
}

.ti-dice-2:before {
  content: "";
}

.ti-dice-2-filled:before {
  content: "";
}

.ti-dice-3:before {
  content: "";
}

.ti-dice-3-filled:before {
  content: "";
}

.ti-dice-4:before {
  content: "";
}

.ti-dice-4-filled:before {
  content: "";
}

.ti-dice-5:before {
  content: "";
}

.ti-dice-5-filled:before {
  content: "";
}

.ti-dice-6:before {
  content: "";
}

.ti-dice-6-filled:before {
  content: "";
}

.ti-dice-filled:before {
  content: "";
}

.ti-dimensions:before {
  content: "";
}

.ti-direction:before {
  content: "";
}

.ti-direction-arrows:before {
  content: "ﴶ";
}

.ti-direction-horizontal:before {
  content: "";
}

.ti-direction-sign:before {
  content: "";
}

.ti-direction-sign-filled:before {
  content: "";
}

.ti-direction-sign-off:before {
  content: "";
}

.ti-directions:before {
  content: "";
}

.ti-directions-off:before {
  content: "";
}

.ti-disabled:before {
  content: "";
}

.ti-disabled-2:before {
  content: "";
}

.ti-disabled-off:before {
  content: "";
}

.ti-disc:before {
  content: "";
}

.ti-disc-golf:before {
  content: "";
}

.ti-disc-off:before {
  content: "";
}

.ti-discount:before {
  content: "";
}

.ti-discount-off:before {
  content: "";
}

.ti-divide:before {
  content: "";
}

.ti-dna:before {
  content: "";
}

.ti-dna-2:before {
  content: "";
}

.ti-dna-2-off:before {
  content: "";
}

.ti-dna-off:before {
  content: "";
}

.ti-dog:before {
  content: "";
}

.ti-dog-bowl:before {
  content: "";
}

.ti-door:before {
  content: "";
}

.ti-door-enter:before {
  content: "";
}

.ti-door-exit:before {
  content: "";
}

.ti-door-off:before {
  content: "";
}

.ti-dots:before {
  content: "";
}

.ti-dots-circle-horizontal:before {
  content: "";
}

.ti-dots-diagonal:before {
  content: "";
}

.ti-dots-diagonal-2:before {
  content: "";
}

.ti-dots-vertical:before {
  content: "";
}

.ti-download:before {
  content: "";
}

.ti-download-off:before {
  content: "";
}

.ti-drag-drop:before {
  content: "";
}

.ti-drag-drop-2:before {
  content: "";
}

.ti-drone:before {
  content: "";
}

.ti-drone-off:before {
  content: "";
}

.ti-drop-circle:before {
  content: "";
}

.ti-droplet:before {
  content: "";
}

.ti-droplet-bolt:before {
  content: "";
}

.ti-droplet-cancel:before {
  content: "";
}

.ti-droplet-check:before {
  content: "";
}

.ti-droplet-code:before {
  content: "";
}

.ti-droplet-cog:before {
  content: "";
}

.ti-droplet-dollar:before {
  content: "";
}

.ti-droplet-down:before {
  content: "";
}

.ti-droplet-exclamation:before {
  content: "豈";
}

.ti-droplet-filled:before {
  content: "";
}

.ti-droplet-half:before {
  content: "";
}

.ti-droplet-half-2:before {
  content: "";
}

.ti-droplet-half-2-filled:before {
  content: "ﭬ";
}

.ti-droplet-half-filled:before {
  content: "";
}

.ti-droplet-heart:before {
  content: "更";
}

.ti-droplet-minus:before {
  content: "車";
}

.ti-droplet-off:before {
  content: "";
}

.ti-droplet-pause:before {
  content: "賈";
}

.ti-droplet-pin:before {
  content: "滑";
}

.ti-droplet-plus:before {
  content: "串";
}

.ti-droplet-question:before {
  content: "句";
}

.ti-droplet-search:before {
  content: "龜";
}

.ti-droplet-share:before {
  content: "龜";
}

.ti-droplet-star:before {
  content: "契";
}

.ti-droplet-up:before {
  content: "金";
}

.ti-droplet-x:before {
  content: "喇";
}

.ti-droplets:before {
  content: "ﰒ";
}

.ti-dual-screen:before {
  content: "繁";
}

.ti-dumpling:before {
  content: "ﺵ";
}

.ti-e-passport:before {
  content: "";
}

.ti-ear:before {
  content: "";
}

.ti-ear-off:before {
  content: "";
}

.ti-ear-scan:before {
  content: "ﵗ";
}

.ti-ease-in:before {
  content: "";
}

.ti-ease-in-control-point:before {
  content: "";
}

.ti-ease-in-out:before {
  content: "";
}

.ti-ease-in-out-control-points:before {
  content: "";
}

.ti-ease-out:before {
  content: "";
}

.ti-ease-out-control-point:before {
  content: "";
}

.ti-edit:before {
  content: "";
}

.ti-edit-circle:before {
  content: "";
}

.ti-edit-circle-off:before {
  content: "";
}

.ti-edit-off:before {
  content: "";
}

.ti-egg:before {
  content: "";
}

.ti-egg-cracked:before {
  content: "";
}

.ti-egg-filled:before {
  content: "";
}

.ti-egg-fried:before {
  content: "";
}

.ti-egg-off:before {
  content: "";
}

.ti-eggs:before {
  content: "";
}

.ti-elevator:before {
  content: "";
}

.ti-elevator-off:before {
  content: "";
}

.ti-emergency-bed:before {
  content: "";
}

.ti-empathize:before {
  content: "";
}

.ti-empathize-off:before {
  content: "";
}

.ti-emphasis:before {
  content: "";
}

.ti-engine:before {
  content: "";
}

.ti-engine-off:before {
  content: "";
}

.ti-equal:before {
  content: "";
}

.ti-equal-double:before {
  content: "";
}

.ti-equal-not:before {
  content: "";
}

.ti-eraser:before {
  content: "";
}

.ti-eraser-off:before {
  content: "";
}

.ti-error-404:before {
  content: "";
}

.ti-error-404-off:before {
  content: "";
}

.ti-escalator:before {
  content: "ﬆ";
}

.ti-escalator-down:before {
  content: "ﬄ";
}

.ti-escalator-up:before {
  content: "ﬅ";
}

.ti-exchange:before {
  content: "";
}

.ti-exchange-off:before {
  content: "";
}

.ti-exclamation-circle:before {
  content: "";
}

.ti-exclamation-mark:before {
  content: "";
}

.ti-exclamation-mark-off:before {
  content: "";
}

.ti-explicit:before {
  content: "";
}

.ti-explicit-off:before {
  content: "";
}

.ti-exposure:before {
  content: "";
}

.ti-exposure-0:before {
  content: "";
}

.ti-exposure-minus-1:before {
  content: "";
}

.ti-exposure-minus-2:before {
  content: "";
}

.ti-exposure-off:before {
  content: "";
}

.ti-exposure-plus-1:before {
  content: "";
}

.ti-exposure-plus-2:before {
  content: "";
}

.ti-external-link:before {
  content: "";
}

.ti-external-link-off:before {
  content: "";
}

.ti-eye:before {
  content: "";
}

.ti-eye-bolt:before {
  content: "ﭭ";
}

.ti-eye-cancel:before {
  content: "ﭮ";
}

.ti-eye-check:before {
  content: "";
}

.ti-eye-closed:before {
  content: "";
}

.ti-eye-code:before {
  content: "ﭯ";
}

.ti-eye-cog:before {
  content: "";
}

.ti-eye-discount:before {
  content: "ﭰ";
}

.ti-eye-dollar:before {
  content: "ﭱ";
}

.ti-eye-dotted:before {
  content: "ﺭ";
}

.ti-eye-down:before {
  content: "ﭲ";
}

.ti-eye-edit:before {
  content: "";
}

.ti-eye-exclamation:before {
  content: "";
}

.ti-eye-filled:before {
  content: "";
}

.ti-eye-heart:before {
  content: "";
}

.ti-eye-minus:before {
  content: "ﭳ";
}

.ti-eye-off:before {
  content: "";
}

.ti-eye-pause:before {
  content: "ﭴ";
}

.ti-eye-pin:before {
  content: "ﭵ";
}

.ti-eye-plus:before {
  content: "ﭶ";
}

.ti-eye-question:before {
  content: "ﭷ";
}

.ti-eye-search:before {
  content: "ﭸ";
}

.ti-eye-share:before {
  content: "ﭹ";
}

.ti-eye-star:before {
  content: "ﭺ";
}

.ti-eye-table:before {
  content: "";
}

.ti-eye-up:before {
  content: "ﭻ";
}

.ti-eye-x:before {
  content: "";
}

.ti-eyeglass:before {
  content: "";
}

.ti-eyeglass-2:before {
  content: "";
}

.ti-eyeglass-off:before {
  content: "";
}

.ti-face-id:before {
  content: "";
}

.ti-face-id-error:before {
  content: "";
}

.ti-face-mask:before {
  content: "";
}

.ti-face-mask-off:before {
  content: "";
}

.ti-fall:before {
  content: "";
}

.ti-favicon:before {
  content: "ﵥ";
}

.ti-feather:before {
  content: "";
}

.ti-feather-off:before {
  content: "";
}

.ti-fence:before {
  content: "";
}

.ti-fence-off:before {
  content: "";
}

.ti-fidget-spinner:before {
  content: "";
}

.ti-file:before {
  content: "";
}

.ti-file-3d:before {
  content: "";
}

.ti-file-alert:before {
  content: "";
}

.ti-file-analytics:before {
  content: "";
}

.ti-file-arrow-left:before {
  content: "";
}

.ti-file-arrow-right:before {
  content: "";
}

.ti-file-barcode:before {
  content: "";
}

.ti-file-broken:before {
  content: "";
}

.ti-file-certificate:before {
  content: "";
}

.ti-file-chart:before {
  content: "";
}

.ti-file-check:before {
  content: "";
}

.ti-file-code:before {
  content: "";
}

.ti-file-code-2:before {
  content: "";
}

.ti-file-cv:before {
  content: "署";
}

.ti-file-database:before {
  content: "";
}

.ti-file-delta:before {
  content: "";
}

.ti-file-description:before {
  content: "";
}

.ti-file-diff:before {
  content: "";
}

.ti-file-digit:before {
  content: "";
}

.ti-file-dislike:before {
  content: "";
}

.ti-file-dollar:before {
  content: "";
}

.ti-file-dots:before {
  content: "";
}

.ti-file-download:before {
  content: "";
}

.ti-file-euro:before {
  content: "";
}

.ti-file-export:before {
  content: "";
}

.ti-file-filled:before {
  content: "";
}

.ti-file-function:before {
  content: "";
}

.ti-file-horizontal:before {
  content: "";
}

.ti-file-import:before {
  content: "";
}

.ti-file-infinity:before {
  content: "";
}

.ti-file-info:before {
  content: "";
}

.ti-file-invoice:before {
  content: "";
}

.ti-file-isr:before {
  content: "ﺬ";
}

.ti-file-lambda:before {
  content: "";
}

.ti-file-like:before {
  content: "";
}

.ti-file-minus:before {
  content: "";
}

.ti-file-music:before {
  content: "";
}

.ti-file-neutral:before {
  content: "ﴢ";
}

.ti-file-off:before {
  content: "";
}

.ti-file-orientation:before {
  content: "";
}

.ti-file-pencil:before {
  content: "";
}

.ti-file-percent:before {
  content: "";
}

.ti-file-phone:before {
  content: "";
}

.ti-file-plus:before {
  content: "";
}

.ti-file-power:before {
  content: "";
}

.ti-file-report:before {
  content: "";
}

.ti-file-rss:before {
  content: "";
}

.ti-file-sad:before {
  content: "ﴣ";
}

.ti-file-scissors:before {
  content: "";
}

.ti-file-search:before {
  content: "";
}

.ti-file-settings:before {
  content: "";
}

.ti-file-shredder:before {
  content: "";
}

.ti-file-signal:before {
  content: "";
}

.ti-file-smile:before {
  content: "ﴤ";
}

.ti-file-spreadsheet:before {
  content: "";
}

.ti-file-stack:before {
  content: "";
}

.ti-file-star:before {
  content: "";
}

.ti-file-symlink:before {
  content: "";
}

.ti-file-text:before {
  content: "";
}

.ti-file-text-ai:before {
  content: "﨧";
}

.ti-file-time:before {
  content: "";
}

.ti-file-type-bmp:before {
  content: "﬇";
}

.ti-file-type-css:before {
  content: "﬈";
}

.ti-file-type-csv:before {
  content: "﬉";
}

.ti-file-type-doc:before {
  content: "﬊";
}

.ti-file-type-docx:before {
  content: "﬋";
}

.ti-file-type-html:before {
  content: "﬌";
}

.ti-file-type-jpg:before {
  content: "﬍";
}

.ti-file-type-js:before {
  content: "﬎";
}

.ti-file-type-jsx:before {
  content: "﬏";
}

.ti-file-type-pdf:before {
  content: "﬐";
}

.ti-file-type-php:before {
  content: "﬑";
}

.ti-file-type-png:before {
  content: "﬒";
}

.ti-file-type-ppt:before {
  content: "ﬓ";
}

.ti-file-type-rs:before {
  content: "ﬔ";
}

.ti-file-type-sql:before {
  content: "ﬕ";
}

.ti-file-type-svg:before {
  content: "ﬖ";
}

.ti-file-type-ts:before {
  content: "ﬗ";
}

.ti-file-type-tsx:before {
  content: "﬘";
}

.ti-file-type-txt:before {
  content: "﬙";
}

.ti-file-type-vue:before {
  content: "﬚";
}

.ti-file-type-xls:before {
  content: "﬛";
}

.ti-file-type-xml:before {
  content: "﬜";
}

.ti-file-type-zip:before {
  content: "יִ";
}

.ti-file-typography:before {
  content: "";
}

.ti-file-unknown:before {
  content: "";
}

.ti-file-upload:before {
  content: "";
}

.ti-file-vector:before {
  content: "";
}

.ti-file-x:before {
  content: "";
}

.ti-file-x-filled:before {
  content: "";
}

.ti-file-zip:before {
  content: "";
}

.ti-files:before {
  content: "";
}

.ti-files-off:before {
  content: "";
}

.ti-filter:before {
  content: "";
}

.ti-filter-bolt:before {
  content: "ﭼ";
}

.ti-filter-cancel:before {
  content: "ﭽ";
}

.ti-filter-check:before {
  content: "ﭾ";
}

.ti-filter-code:before {
  content: "ﭿ";
}

.ti-filter-cog:before {
  content: "茶";
}

.ti-filter-discount:before {
  content: "ﮀ";
}

.ti-filter-dollar:before {
  content: "刺";
}

.ti-filter-down:before {
  content: "ﮁ";
}

.ti-filter-edit:before {
  content: "切";
}

.ti-filter-exclamation:before {
  content: "ﮂ";
}

.ti-filter-filled:before {
  content: "ﰧ";
}

.ti-filter-heart:before {
  content: "ﮃ";
}

.ti-filter-minus:before {
  content: "度";
}

.ti-filter-off:before {
  content: "";
}

.ti-filter-pause:before {
  content: "ﮄ";
}

.ti-filter-pin:before {
  content: "ﮅ";
}

.ti-filter-plus:before {
  content: "拓";
}

.ti-filter-question:before {
  content: "ﮆ";
}

.ti-filter-search:before {
  content: "ﮇ";
}

.ti-filter-share:before {
  content: "ﮈ";
}

.ti-filter-star:before {
  content: "糖";
}

.ti-filter-up:before {
  content: "ﮉ";
}

.ti-filter-x:before {
  content: "宅";
}

.ti-filters:before {
  content: "";
}

.ti-fingerprint:before {
  content: "";
}

.ti-fingerprint-off:before {
  content: "";
}

.ti-fingerprint-scan:before {
  content: "ﲵ";
}

.ti-fire-extinguisher:before {
  content: "﫶";
}

.ti-fire-hydrant:before {
  content: "";
}

.ti-fire-hydrant-off:before {
  content: "";
}

.ti-firetruck:before {
  content: "";
}

.ti-first-aid-kit:before {
  content: "";
}

.ti-first-aid-kit-off:before {
  content: "";
}

.ti-fish:before {
  content: "";
}

.ti-fish-bone:before {
  content: "";
}

.ti-fish-christianity:before {
  content: "";
}

.ti-fish-hook:before {
  content: "";
}

.ti-fish-hook-off:before {
  content: "";
}

.ti-fish-off:before {
  content: "";
}

.ti-flag:before {
  content: "";
}

.ti-flag-2:before {
  content: "";
}

.ti-flag-2-filled:before {
  content: "";
}

.ti-flag-2-off:before {
  content: "";
}

.ti-flag-3:before {
  content: "";
}

.ti-flag-3-filled:before {
  content: "";
}

.ti-flag-bolt:before {
  content: "ﮊ";
}

.ti-flag-cancel:before {
  content: "ﮋ";
}

.ti-flag-check:before {
  content: "ﮌ";
}

.ti-flag-code:before {
  content: "ﮍ";
}

.ti-flag-cog:before {
  content: "ﮎ";
}

.ti-flag-discount:before {
  content: "ﮏ";
}

.ti-flag-dollar:before {
  content: "ﮐ";
}

.ti-flag-down:before {
  content: "ﮑ";
}

.ti-flag-exclamation:before {
  content: "ﮒ";
}

.ti-flag-filled:before {
  content: "";
}

.ti-flag-heart:before {
  content: "ﮓ";
}

.ti-flag-minus:before {
  content: "ﮔ";
}

.ti-flag-off:before {
  content: "";
}

.ti-flag-pause:before {
  content: "ﮕ";
}

.ti-flag-pin:before {
  content: "ﮖ";
}

.ti-flag-plus:before {
  content: "ﮗ";
}

.ti-flag-question:before {
  content: "ﮘ";
}

.ti-flag-search:before {
  content: "ﮙ";
}

.ti-flag-share:before {
  content: "ﮚ";
}

.ti-flag-star:before {
  content: "ﮛ";
}

.ti-flag-up:before {
  content: "ﮜ";
}

.ti-flag-x:before {
  content: "ﮝ";
}

.ti-flame:before {
  content: "";
}

.ti-flame-off:before {
  content: "";
}

.ti-flare:before {
  content: "";
}

.ti-flask:before {
  content: "";
}

.ti-flask-2:before {
  content: "";
}

.ti-flask-2-filled:before {
  content: "ﴒ";
}

.ti-flask-2-off:before {
  content: "";
}

.ti-flask-filled:before {
  content: "ﴓ";
}

.ti-flask-off:before {
  content: "";
}

.ti-flip-flops:before {
  content: "";
}

.ti-flip-horizontal:before {
  content: "";
}

.ti-flip-vertical:before {
  content: "";
}

.ti-float-center:before {
  content: "";
}

.ti-float-left:before {
  content: "";
}

.ti-float-none:before {
  content: "";
}

.ti-float-right:before {
  content: "";
}

.ti-flower:before {
  content: "";
}

.ti-flower-off:before {
  content: "";
}

.ti-focus:before {
  content: "";
}

.ti-focus-2:before {
  content: "";
}

.ti-focus-auto:before {
  content: "謁";
}

.ti-focus-centered:before {
  content: "";
}

.ti-fold:before {
  content: "";
}

.ti-fold-down:before {
  content: "";
}

.ti-fold-up:before {
  content: "";
}

.ti-folder:before {
  content: "";
}

.ti-folder-bolt:before {
  content: "奈";
}

.ti-folder-cancel:before {
  content: "懶";
}

.ti-folder-check:before {
  content: "癩";
}

.ti-folder-code:before {
  content: "羅";
}

.ti-folder-cog:before {
  content: "蘿";
}

.ti-folder-dollar:before {
  content: "螺";
}

.ti-folder-down:before {
  content: "裸";
}

.ti-folder-exclamation:before {
  content: "邏";
}

.ti-folder-filled:before {
  content: "";
}

.ti-folder-heart:before {
  content: "樂";
}

.ti-folder-minus:before {
  content: "";
}

.ti-folder-off:before {
  content: "";
}

.ti-folder-open:before {
  content: "﫷";
}

.ti-folder-pause:before {
  content: "洛";
}

.ti-folder-pin:before {
  content: "烙";
}

.ti-folder-plus:before {
  content: "";
}

.ti-folder-question:before {
  content: "珞";
}

.ti-folder-root:before {
  content: "﵃";
}

.ti-folder-search:before {
  content: "落";
}

.ti-folder-share:before {
  content: "酪";
}

.ti-folder-star:before {
  content: "駱";
}

.ti-folder-symlink:before {
  content: "亂";
}

.ti-folder-up:before {
  content: "卵";
}

.ti-folder-x:before {
  content: "";
}

.ti-folders:before {
  content: "";
}

.ti-folders-off:before {
  content: "";
}

.ti-forbid:before {
  content: "";
}

.ti-forbid-2:before {
  content: "";
}

.ti-forbid-2-filled:before {
  content: "ﰨ";
}

.ti-forbid-filled:before {
  content: "ﰩ";
}

.ti-forklift:before {
  content: "";
}

.ti-forms:before {
  content: "";
}

.ti-fountain:before {
  content: "";
}

.ti-fountain-filled:before {
  content: "ﰪ";
}

.ti-fountain-off:before {
  content: "";
}

.ti-frame:before {
  content: "";
}

.ti-frame-off:before {
  content: "";
}

.ti-free-rights:before {
  content: "";
}

.ti-freeze-column:before {
  content: "謹";
}

.ti-freeze-row:before {
  content: "贈";
}

.ti-freeze-row-column:before {
  content: "賓";
}

.ti-fridge:before {
  content: "";
}

.ti-fridge-off:before {
  content: "";
}

.ti-friends:before {
  content: "";
}

.ti-friends-off:before {
  content: "";
}

.ti-frustum:before {
  content: "犯";
}

.ti-frustum-off:before {
  content: "瞧";
}

.ti-frustum-plus:before {
  content: "爵";
}

.ti-function:before {
  content: "";
}

.ti-function-filled:before {
  content: "ﰫ";
}

.ti-function-off:before {
  content: "";
}

.ti-galaxy:before {
  content: "ﲶ";
}

.ti-garden-cart:before {
  content: "";
}

.ti-garden-cart-off:before {
  content: "";
}

.ti-gas-station:before {
  content: "";
}

.ti-gas-station-off:before {
  content: "";
}

.ti-gauge:before {
  content: "";
}

.ti-gauge-filled:before {
  content: "ﰬ";
}

.ti-gauge-off:before {
  content: "";
}

.ti-gavel:before {
  content: "";
}

.ti-gender-agender:before {
  content: "";
}

.ti-gender-androgyne:before {
  content: "";
}

.ti-gender-bigender:before {
  content: "";
}

.ti-gender-demiboy:before {
  content: "";
}

.ti-gender-demigirl:before {
  content: "";
}

.ti-gender-epicene:before {
  content: "";
}

.ti-gender-female:before {
  content: "";
}

.ti-gender-femme:before {
  content: "";
}

.ti-gender-genderfluid:before {
  content: "";
}

.ti-gender-genderless:before {
  content: "";
}

.ti-gender-genderqueer:before {
  content: "";
}

.ti-gender-hermaphrodite:before {
  content: "";
}

.ti-gender-intergender:before {
  content: "";
}

.ti-gender-male:before {
  content: "";
}

.ti-gender-neutrois:before {
  content: "";
}

.ti-gender-third:before {
  content: "";
}

.ti-gender-transgender:before {
  content: "";
}

.ti-gender-trasvesti:before {
  content: "";
}

.ti-geometry:before {
  content: "";
}

.ti-ghost:before {
  content: "";
}

.ti-ghost-2:before {
  content: "";
}

.ti-ghost-2-filled:before {
  content: "";
}

.ti-ghost-3:before {
  content: "ﰓ";
}

.ti-ghost-filled:before {
  content: "";
}

.ti-ghost-off:before {
  content: "";
}

.ti-gif:before {
  content: "";
}

.ti-gift:before {
  content: "";
}

.ti-gift-card:before {
  content: "";
}

.ti-gift-card-filled:before {
  content: "ﰭ";
}

.ti-gift-filled:before {
  content: "ﴔ";
}

.ti-gift-off:before {
  content: "";
}

.ti-git-branch:before {
  content: "";
}

.ti-git-branch-deleted:before {
  content: "";
}

.ti-git-cherry-pick:before {
  content: "";
}

.ti-git-commit:before {
  content: "";
}

.ti-git-compare:before {
  content: "";
}

.ti-git-fork:before {
  content: "";
}

.ti-git-merge:before {
  content: "";
}

.ti-git-pull-request:before {
  content: "";
}

.ti-git-pull-request-closed:before {
  content: "";
}

.ti-git-pull-request-draft:before {
  content: "";
}

.ti-gizmo:before {
  content: "";
}

.ti-glass:before {
  content: "";
}

.ti-glass-champagne:before {
  content: "ﶜ";
}

.ti-glass-cocktail:before {
  content: "ﶝ";
}

.ti-glass-full:before {
  content: "";
}

.ti-glass-full-filled:before {
  content: "ﰮ";
}

.ti-glass-gin:before {
  content: "ﶞ";
}

.ti-glass-off:before {
  content: "";
}

.ti-globe:before {
  content: "";
}

.ti-globe-filled:before {
  content: "ﰯ";
}

.ti-globe-off:before {
  content: "";
}

.ti-go-game:before {
  content: "";
}

.ti-golf:before {
  content: "";
}

.ti-golf-off:before {
  content: "";
}

.ti-gps:before {
  content: "";
}

.ti-gps-filled:before {
  content: "﹈";
}

.ti-gradienter:before {
  content: "";
}

.ti-grain:before {
  content: "";
}

.ti-graph:before {
  content: "";
}

.ti-graph-filled:before {
  content: "ﴕ";
}

.ti-graph-off:before {
  content: "";
}

.ti-grave:before {
  content: "";
}

.ti-grave-2:before {
  content: "";
}

.ti-grid-3x3:before {
  content: "ﲤ";
}

.ti-grid-4x4:before {
  content: "ﲥ";
}

.ti-grid-dots:before {
  content: "";
}

.ti-grid-goldenratio:before {
  content: "ﲦ";
}

.ti-grid-pattern:before {
  content: "";
}

.ti-grid-scan:before {
  content: "ﲧ";
}

.ti-grill:before {
  content: "";
}

.ti-grill-fork:before {
  content: "";
}

.ti-grill-off:before {
  content: "";
}

.ti-grill-spatula:before {
  content: "";
}

.ti-grip-horizontal:before {
  content: "";
}

.ti-grip-vertical:before {
  content: "";
}

.ti-growth:before {
  content: "";
}

.ti-guitar-pick:before {
  content: "";
}

.ti-guitar-pick-filled:before {
  content: "";
}

.ti-gymnastics:before {
  content: "﵄";
}

.ti-h-1:before {
  content: "";
}

.ti-h-2:before {
  content: "";
}

.ti-h-3:before {
  content: "";
}

.ti-h-4:before {
  content: "";
}

.ti-h-5:before {
  content: "";
}

.ti-h-6:before {
  content: "";
}

.ti-hammer:before {
  content: "";
}

.ti-hammer-off:before {
  content: "";
}

.ti-hand-click:before {
  content: "";
}

.ti-hand-finger:before {
  content: "";
}

.ti-hand-finger-off:before {
  content: "";
}

.ti-hand-grab:before {
  content: "";
}

.ti-hand-little-finger:before {
  content: "";
}

.ti-hand-love-you:before {
  content: "";
}

.ti-hand-middle-finger:before {
  content: "";
}

.ti-hand-move:before {
  content: "";
}

.ti-hand-off:before {
  content: "";
}

.ti-hand-ring-finger:before {
  content: "";
}

.ti-hand-sanitizer:before {
  content: "";
}

.ti-hand-stop:before {
  content: "";
}

.ti-hand-three-fingers:before {
  content: "";
}

.ti-hand-two-fingers:before {
  content: "";
}

.ti-hanger:before {
  content: "";
}

.ti-hanger-2:before {
  content: "";
}

.ti-hanger-off:before {
  content: "";
}

.ti-hash:before {
  content: "";
}

.ti-haze:before {
  content: "";
}

.ti-haze-moon:before {
  content: "﫸";
}

.ti-hdr:before {
  content: "嗢";
}

.ti-heading:before {
  content: "";
}

.ti-heading-off:before {
  content: "";
}

.ti-headphones:before {
  content: "";
}

.ti-headphones-filled:before {
  content: "屮";
}

.ti-headphones-off:before {
  content: "";
}

.ti-headset:before {
  content: "";
}

.ti-headset-off:before {
  content: "";
}

.ti-health-recognition:before {
  content: "";
}

.ti-heart:before {
  content: "";
}

.ti-heart-bolt:before {
  content: "ﮞ";
}

.ti-heart-broken:before {
  content: "";
}

.ti-heart-cancel:before {
  content: "ﮟ";
}

.ti-heart-check:before {
  content: "ﮠ";
}

.ti-heart-code:before {
  content: "ﮡ";
}

.ti-heart-cog:before {
  content: "ﮢ";
}

.ti-heart-discount:before {
  content: "ﮣ";
}

.ti-heart-dollar:before {
  content: "ﮤ";
}

.ti-heart-down:before {
  content: "ﮥ";
}

.ti-heart-exclamation:before {
  content: "ﮦ";
}

.ti-heart-filled:before {
  content: "";
}

.ti-heart-handshake:before {
  content: "";
}

.ti-heart-minus:before {
  content: "";
}

.ti-heart-off:before {
  content: "";
}

.ti-heart-pause:before {
  content: "ﮧ";
}

.ti-heart-pin:before {
  content: "ﮨ";
}

.ti-heart-plus:before {
  content: "";
}

.ti-heart-question:before {
  content: "ﮩ";
}

.ti-heart-rate-monitor:before {
  content: "";
}

.ti-heart-search:before {
  content: "ﮪ";
}

.ti-heart-share:before {
  content: "ﮫ";
}

.ti-heart-star:before {
  content: "ﮬ";
}

.ti-heart-up:before {
  content: "ﮭ";
}

.ti-heart-x:before {
  content: "ﮮ";
}

.ti-heartbeat:before {
  content: "";
}

.ti-hearts:before {
  content: "";
}

.ti-hearts-off:before {
  content: "";
}

.ti-helicopter:before {
  content: "";
}

.ti-helicopter-landing:before {
  content: "";
}

.ti-helmet:before {
  content: "";
}

.ti-helmet-off:before {
  content: "";
}

.ti-help:before {
  content: "";
}

.ti-help-circle:before {
  content: "欄";
}

.ti-help-circle-filled:before {
  content: "悔";
}

.ti-help-hexagon:before {
  content: "";
}

.ti-help-hexagon-filled:before {
  content: "慨";
}

.ti-help-octagon:before {
  content: "";
}

.ti-help-octagon-filled:before {
  content: "憎";
}

.ti-help-off:before {
  content: "";
}

.ti-help-small:before {
  content: "爛";
}

.ti-help-square:before {
  content: "鸞";
}

.ti-help-square-filled:before {
  content: "懲";
}

.ti-help-square-rounded:before {
  content: "蘭";
}

.ti-help-square-rounded-filled:before {
  content: "敏";
}

.ti-help-triangle:before {
  content: "嵐";
}

.ti-help-triangle-filled:before {
  content: "既";
}

.ti-hemisphere:before {
  content: "甆";
}

.ti-hemisphere-off:before {
  content: "猪";
}

.ti-hemisphere-plus:before {
  content: "瑱";
}

.ti-hexagon:before {
  content: "";
}

.ti-hexagon-3d:before {
  content: "";
}

.ti-hexagon-filled:before {
  content: "";
}

.ti-hexagon-letter-a:before {
  content: "";
}

.ti-hexagon-letter-a-filled:before {
  content: "﹇";
}

.ti-hexagon-letter-b:before {
  content: "";
}

.ti-hexagon-letter-b-filled:before {
  content: "﹆";
}

.ti-hexagon-letter-c:before {
  content: "";
}

.ti-hexagon-letter-c-filled:before {
  content: "﹅";
}

.ti-hexagon-letter-d:before {
  content: "";
}

.ti-hexagon-letter-d-filled:before {
  content: "﹄";
}

.ti-hexagon-letter-e:before {
  content: "";
}

.ti-hexagon-letter-e-filled:before {
  content: "﹃";
}

.ti-hexagon-letter-f:before {
  content: "";
}

.ti-hexagon-letter-f-filled:before {
  content: "﹂";
}

.ti-hexagon-letter-g:before {
  content: "";
}

.ti-hexagon-letter-g-filled:before {
  content: "﹁";
}

.ti-hexagon-letter-h:before {
  content: "";
}

.ti-hexagon-letter-h-filled:before {
  content: "﹀";
}

.ti-hexagon-letter-i:before {
  content: "";
}

.ti-hexagon-letter-i-filled:before {
  content: "︿";
}

.ti-hexagon-letter-j:before {
  content: "";
}

.ti-hexagon-letter-j-filled:before {
  content: "︾";
}

.ti-hexagon-letter-k:before {
  content: "";
}

.ti-hexagon-letter-k-filled:before {
  content: "︽";
}

.ti-hexagon-letter-l:before {
  content: "";
}

.ti-hexagon-letter-l-filled:before {
  content: "︼";
}

.ti-hexagon-letter-m:before {
  content: "";
}

.ti-hexagon-letter-m-filled:before {
  content: "︻";
}

.ti-hexagon-letter-n:before {
  content: "";
}

.ti-hexagon-letter-n-filled:before {
  content: "︺";
}

.ti-hexagon-letter-o:before {
  content: "";
}

.ti-hexagon-letter-o-filled:before {
  content: "︹";
}

.ti-hexagon-letter-p:before {
  content: "";
}

.ti-hexagon-letter-p-filled:before {
  content: "︸";
}

.ti-hexagon-letter-q:before {
  content: "";
}

.ti-hexagon-letter-q-filled:before {
  content: "︷";
}

.ti-hexagon-letter-r:before {
  content: "";
}

.ti-hexagon-letter-r-filled:before {
  content: "︶";
}

.ti-hexagon-letter-s:before {
  content: "";
}

.ti-hexagon-letter-s-filled:before {
  content: "︵";
}

.ti-hexagon-letter-t:before {
  content: "";
}

.ti-hexagon-letter-t-filled:before {
  content: "︴";
}

.ti-hexagon-letter-u:before {
  content: "";
}

.ti-hexagon-letter-u-filled:before {
  content: "︳";
}

.ti-hexagon-letter-v:before {
  content: "";
}

.ti-hexagon-letter-v-filled:before {
  content: "︲";
}

.ti-hexagon-letter-w:before {
  content: "";
}

.ti-hexagon-letter-w-filled:before {
  content: "︱";
}

.ti-hexagon-letter-x:before {
  content: "";
}

.ti-hexagon-letter-x-filled:before {
  content: "︰";
}

.ti-hexagon-letter-y:before {
  content: "";
}

.ti-hexagon-letter-y-filled:before {
  content: "︯";
}

.ti-hexagon-letter-z:before {
  content: "";
}

.ti-hexagon-letter-z-filled:before {
  content: "︮";
}

.ti-hexagon-minus:before {
  content: "ﲏ";
}

.ti-hexagon-minus-2:before {
  content: "ﲎ";
}

.ti-hexagon-minus-filled:before {
  content: "︭";
}

.ti-hexagon-number-0:before {
  content: "";
}

.ti-hexagon-number-0-filled:before {
  content: "";
}

.ti-hexagon-number-1:before {
  content: "";
}

.ti-hexagon-number-1-filled:before {
  content: "";
}

.ti-hexagon-number-2:before {
  content: "";
}

.ti-hexagon-number-2-filled:before {
  content: "";
}

.ti-hexagon-number-3:before {
  content: "";
}

.ti-hexagon-number-3-filled:before {
  content: "";
}

.ti-hexagon-number-4:before {
  content: "";
}

.ti-hexagon-number-4-filled:before {
  content: "";
}

.ti-hexagon-number-5:before {
  content: "";
}

.ti-hexagon-number-5-filled:before {
  content: "";
}

.ti-hexagon-number-6:before {
  content: "";
}

.ti-hexagon-number-6-filled:before {
  content: "";
}

.ti-hexagon-number-7:before {
  content: "";
}

.ti-hexagon-number-7-filled:before {
  content: "";
}

.ti-hexagon-number-8:before {
  content: "";
}

.ti-hexagon-number-8-filled:before {
  content: "";
}

.ti-hexagon-number-9:before {
  content: "";
}

.ti-hexagon-number-9-filled:before {
  content: "";
}

.ti-hexagon-off:before {
  content: "";
}

.ti-hexagon-plus:before {
  content: "ﱅ";
}

.ti-hexagon-plus-2:before {
  content: "ﲐ";
}

.ti-hexagon-plus-filled:before {
  content: "︬";
}

.ti-hexagonal-prism:before {
  content: "瘟";
}

.ti-hexagonal-prism-off:before {
  content: "画";
}

.ti-hexagonal-prism-plus:before {
  content: "瘝";
}

.ti-hexagonal-pyramid:before {
  content: "直";
}

.ti-hexagonal-pyramid-off:before {
  content: "益";
}

.ti-hexagonal-pyramid-plus:before {
  content: "盛";
}

.ti-hexagons:before {
  content: "";
}

.ti-hexagons-off:before {
  content: "";
}

.ti-hierarchy:before {
  content: "";
}

.ti-hierarchy-2:before {
  content: "";
}

.ti-hierarchy-3:before {
  content: "";
}

.ti-hierarchy-off:before {
  content: "";
}

.ti-highlight:before {
  content: "";
}

.ti-highlight-off:before {
  content: "";
}

.ti-history:before {
  content: "";
}

.ti-history-off:before {
  content: "";
}

.ti-history-toggle:before {
  content: "";
}

.ti-home:before {
  content: "";
}

.ti-home-2:before {
  content: "";
}

.ti-home-bolt:before {
  content: "";
}

.ti-home-cancel:before {
  content: "";
}

.ti-home-check:before {
  content: "";
}

.ti-home-cog:before {
  content: "";
}

.ti-home-dollar:before {
  content: "";
}

.ti-home-dot:before {
  content: "";
}

.ti-home-down:before {
  content: "";
}

.ti-home-eco:before {
  content: "";
}

.ti-home-edit:before {
  content: "";
}

.ti-home-exclamation:before {
  content: "";
}

.ti-home-filled:before {
  content: "︫";
}

.ti-home-hand:before {
  content: "";
}

.ti-home-heart:before {
  content: "";
}

.ti-home-infinity:before {
  content: "";
}

.ti-home-link:before {
  content: "";
}

.ti-home-minus:before {
  content: "";
}

.ti-home-move:before {
  content: "";
}

.ti-home-off:before {
  content: "";
}

.ti-home-plus:before {
  content: "";
}

.ti-home-question:before {
  content: "";
}

.ti-home-ribbon:before {
  content: "";
}

.ti-home-search:before {
  content: "";
}

.ti-home-share:before {
  content: "";
}

.ti-home-shield:before {
  content: "";
}

.ti-home-signal:before {
  content: "";
}

.ti-home-star:before {
  content: "";
}

.ti-home-stats:before {
  content: "";
}

.ti-home-up:before {
  content: "";
}

.ti-home-x:before {
  content: "";
}

.ti-horse:before {
  content: "ﱆ";
}

.ti-horse-toy:before {
  content: "";
}

.ti-horseshoe:before {
  content: "ﲷ";
}

.ti-hospital:before {
  content: "ﵙ";
}

.ti-hospital-circle:before {
  content: "ﵘ";
}

.ti-hotel-service:before {
  content: "";
}

.ti-hourglass:before {
  content: "";
}

.ti-hourglass-empty:before {
  content: "";
}

.ti-hourglass-filled:before {
  content: "";
}

.ti-hourglass-high:before {
  content: "";
}

.ti-hourglass-low:before {
  content: "";
}

.ti-hourglass-off:before {
  content: "";
}

.ti-hours-12:before {
  content: "ﱓ";
}

.ti-hours-24:before {
  content: "";
}

.ti-html:before {
  content: "";
}

.ti-http-connect:before {
  content: "﨨";
}

.ti-http-delete:before {
  content: "﨩";
}

.ti-http-get:before {
  content: "飯";
}

.ti-http-head:before {
  content: "飼";
}

.ti-http-options:before {
  content: "館";
}

.ti-http-patch:before {
  content: "鶴";
}

.ti-http-post:before {
  content: "郞";
}

.ti-http-put:before {
  content: "隷";
}

.ti-http-que:before {
  content: "者";
}

.ti-http-trace:before {
  content: "侮";
}

.ti-ice-cream:before {
  content: "";
}

.ti-ice-cream-2:before {
  content: "";
}

.ti-ice-cream-off:before {
  content: "";
}

.ti-ice-skating:before {
  content: "";
}

.ti-icons:before {
  content: "";
}

.ti-icons-off:before {
  content: "";
}

.ti-id:before {
  content: "";
}

.ti-id-badge:before {
  content: "";
}

.ti-id-badge-2:before {
  content: "";
}

.ti-id-badge-off:before {
  content: "";
}

.ti-id-off:before {
  content: "";
}

.ti-image-in-picture:before {
  content: "ﶟ";
}

.ti-inbox:before {
  content: "";
}

.ti-inbox-off:before {
  content: "";
}

.ti-indent-decrease:before {
  content: "";
}

.ti-indent-increase:before {
  content: "";
}

.ti-infinity:before {
  content: "";
}

.ti-infinity-off:before {
  content: "";
}

.ti-info-circle:before {
  content: "";
}

.ti-info-circle-filled:before {
  content: "";
}

.ti-info-hexagon:before {
  content: "";
}

.ti-info-hexagon-filled:before {
  content: "暑";
}

.ti-info-octagon:before {
  content: "";
}

.ti-info-octagon-filled:before {
  content: "梅";
}

.ti-info-small:before {
  content: "濫";
}

.ti-info-square:before {
  content: "";
}

.ti-info-square-filled:before {
  content: "海";
}

.ti-info-square-rounded:before {
  content: "";
}

.ti-info-square-rounded-filled:before {
  content: "";
}

.ti-info-triangle:before {
  content: "藍";
}

.ti-info-triangle-filled:before {
  content: "渚";
}

.ti-inner-shadow-bottom:before {
  content: "";
}

.ti-inner-shadow-bottom-filled:before {
  content: "";
}

.ti-inner-shadow-bottom-left:before {
  content: "";
}

.ti-inner-shadow-bottom-left-filled:before {
  content: "";
}

.ti-inner-shadow-bottom-right:before {
  content: "";
}

.ti-inner-shadow-bottom-right-filled:before {
  content: "";
}

.ti-inner-shadow-left:before {
  content: "";
}

.ti-inner-shadow-left-filled:before {
  content: "";
}

.ti-inner-shadow-right:before {
  content: "";
}

.ti-inner-shadow-right-filled:before {
  content: "";
}

.ti-inner-shadow-top:before {
  content: "";
}

.ti-inner-shadow-top-filled:before {
  content: "";
}

.ti-inner-shadow-top-left:before {
  content: "";
}

.ti-inner-shadow-top-left-filled:before {
  content: "";
}

.ti-inner-shadow-top-right:before {
  content: "";
}

.ti-inner-shadow-top-right-filled:before {
  content: "";
}

.ti-input-ai:before {
  content: "ﱚ";
}

.ti-input-check:before {
  content: "ﱛ";
}

.ti-input-search:before {
  content: "";
}

.ti-input-x:before {
  content: "ﱜ";
}

.ti-invoice:before {
  content: "ﺫ";
}

.ti-ironing:before {
  content: "塚";
}

.ti-ironing-1:before {
  content: "";
}

.ti-ironing-2:before {
  content: "";
}

.ti-ironing-3:before {
  content: "";
}

.ti-ironing-filled:before {
  content: "︪";
}

.ti-ironing-off:before {
  content: "";
}

.ti-ironing-steam:before {
  content: "";
}

.ti-ironing-steam-off:before {
  content: "";
}

.ti-irregular-polyhedron:before {
  content: "磌";
}

.ti-irregular-polyhedron-off:before {
  content: "睊";
}

.ti-irregular-polyhedron-plus:before {
  content: "着";
}

.ti-italic:before {
  content: "";
}

.ti-jacket:before {
  content: "";
}

.ti-jetpack:before {
  content: "";
}

.ti-jetpack-filled:before {
  content: "︩";
}

.ti-jewish-star:before {
  content: "";
}

.ti-jewish-star-filled:before {
  content: "";
}

.ti-jpg:before {
  content: "";
}

.ti-json:before {
  content: "";
}

.ti-jump-rope:before {
  content: "";
}

.ti-karate:before {
  content: "";
}

.ti-kayak:before {
  content: "";
}

.ti-kerning:before {
  content: "";
}

.ti-key:before {
  content: "";
}

.ti-key-filled:before {
  content: "︨";
}

.ti-key-off:before {
  content: "";
}

.ti-keyboard:before {
  content: "";
}

.ti-keyboard-hide:before {
  content: "";
}

.ti-keyboard-off:before {
  content: "";
}

.ti-keyboard-show:before {
  content: "";
}

.ti-keyframe:before {
  content: "";
}

.ti-keyframe-align-center:before {
  content: "";
}

.ti-keyframe-align-center-filled:before {
  content: "ﰰ";
}

.ti-keyframe-align-horizontal:before {
  content: "";
}

.ti-keyframe-align-horizontal-filled:before {
  content: "ﰱ";
}

.ti-keyframe-align-vertical:before {
  content: "";
}

.ti-keyframe-align-vertical-filled:before {
  content: "ﰲ";
}

.ti-keyframe-filled:before {
  content: "ﰳ";
}

.ti-keyframes:before {
  content: "";
}

.ti-keyframes-filled:before {
  content: "ﰴ";
}

.ti-ladder:before {
  content: "";
}

.ti-ladder-off:before {
  content: "";
}

.ti-ladle:before {
  content: "ﰔ";
}

.ti-lambda:before {
  content: "";
}

.ti-lamp:before {
  content: "";
}

.ti-lamp-2:before {
  content: "";
}

.ti-lamp-off:before {
  content: "";
}

.ti-lane:before {
  content: "﫹";
}

.ti-language:before {
  content: "";
}

.ti-language-hiragana:before {
  content: "";
}

.ti-language-katakana:before {
  content: "";
}

.ti-language-off:before {
  content: "";
}

.ti-lasso:before {
  content: "";
}

.ti-lasso-off:before {
  content: "";
}

.ti-lasso-polygon:before {
  content: "";
}

.ti-layers-difference:before {
  content: "";
}

.ti-layers-intersect:before {
  content: "";
}

.ti-layers-intersect-2:before {
  content: "";
}

.ti-layers-linked:before {
  content: "";
}

.ti-layers-off:before {
  content: "";
}

.ti-layers-selected:before {
  content: "ﺩ";
}

.ti-layers-selected-bottom:before {
  content: "ﺪ";
}

.ti-layers-subtract:before {
  content: "";
}

.ti-layers-union:before {
  content: "";
}

.ti-layout:before {
  content: "";
}

.ti-layout-2:before {
  content: "";
}

.ti-layout-2-filled:before {
  content: "︧";
}

.ti-layout-align-bottom:before {
  content: "";
}

.ti-layout-align-bottom-filled:before {
  content: "︦";
}

.ti-layout-align-center:before {
  content: "";
}

.ti-layout-align-center-filled:before {
  content: "︥";
}

.ti-layout-align-left:before {
  content: "";
}

.ti-layout-align-left-filled:before {
  content: "︤";
}

.ti-layout-align-middle:before {
  content: "";
}

.ti-layout-align-middle-filled:before {
  content: "︣";
}

.ti-layout-align-right:before {
  content: "";
}

.ti-layout-align-right-filled:before {
  content: "︢";
}

.ti-layout-align-top:before {
  content: "";
}

.ti-layout-align-top-filled:before {
  content: "︡";
}

.ti-layout-board:before {
  content: "";
}

.ti-layout-board-split:before {
  content: "";
}

.ti-layout-bottombar:before {
  content: "";
}

.ti-layout-bottombar-collapse:before {
  content: "";
}

.ti-layout-bottombar-collapse-filled:before {
  content: "ﰵ";
}

.ti-layout-bottombar-expand:before {
  content: "";
}

.ti-layout-bottombar-expand-filled:before {
  content: "ﰶ";
}

.ti-layout-bottombar-filled:before {
  content: "ﰷ";
}

.ti-layout-bottombar-inactive:before {
  content: "﵅";
}

.ti-layout-cards:before {
  content: "";
}

.ti-layout-cards-filled:before {
  content: "︠";
}

.ti-layout-collage:before {
  content: "";
}

.ti-layout-columns:before {
  content: "";
}

.ti-layout-dashboard:before {
  content: "";
}

.ti-layout-dashboard-filled:before {
  content: "︟";
}

.ti-layout-distribute-horizontal:before {
  content: "";
}

.ti-layout-distribute-horizontal-filled:before {
  content: "︞";
}

.ti-layout-distribute-vertical:before {
  content: "";
}

.ti-layout-distribute-vertical-filled:before {
  content: "︝";
}

.ti-layout-filled:before {
  content: "︗";
}

.ti-layout-grid:before {
  content: "";
}

.ti-layout-grid-add:before {
  content: "";
}

.ti-layout-grid-filled:before {
  content: "︜";
}

.ti-layout-grid-remove:before {
  content: "墳";
}

.ti-layout-kanban:before {
  content: "";
}

.ti-layout-kanban-filled:before {
  content: "︛";
}

.ti-layout-list:before {
  content: "";
}

.ti-layout-list-filled:before {
  content: "︚";
}

.ti-layout-navbar:before {
  content: "";
}

.ti-layout-navbar-collapse:before {
  content: "";
}

.ti-layout-navbar-collapse-filled:before {
  content: "ﰸ";
}

.ti-layout-navbar-expand:before {
  content: "";
}

.ti-layout-navbar-expand-filled:before {
  content: "ﰹ";
}

.ti-layout-navbar-filled:before {
  content: "ﰺ";
}

.ti-layout-navbar-inactive:before {
  content: "﵆";
}

.ti-layout-off:before {
  content: "";
}

.ti-layout-rows:before {
  content: "";
}

.ti-layout-sidebar:before {
  content: "";
}

.ti-layout-sidebar-filled:before {
  content: "︘";
}

.ti-layout-sidebar-inactive:before {
  content: "﵇";
}

.ti-layout-sidebar-left-collapse:before {
  content: "";
}

.ti-layout-sidebar-left-collapse-filled:before {
  content: "ﰻ";
}

.ti-layout-sidebar-left-expand:before {
  content: "";
}

.ti-layout-sidebar-left-expand-filled:before {
  content: "ﰼ";
}

.ti-layout-sidebar-right:before {
  content: "";
}

.ti-layout-sidebar-right-collapse:before {
  content: "";
}

.ti-layout-sidebar-right-collapse-filled:before {
  content: "ﰽ";
}

.ti-layout-sidebar-right-expand:before {
  content: "";
}

.ti-layout-sidebar-right-expand-filled:before {
  content: "ﰾ";
}

.ti-layout-sidebar-right-filled:before {
  content: "︙";
}

.ti-layout-sidebar-right-inactive:before {
  content: "﵈";
}

.ti-leaf:before {
  content: "";
}

.ti-leaf-off:before {
  content: "";
}

.ti-lego:before {
  content: "";
}

.ti-lego-filled:before {
  content: "︖";
}

.ti-lego-off:before {
  content: "";
}

.ti-lemon:before {
  content: "";
}

.ti-lemon-2:before {
  content: "";
}

.ti-letter-a:before {
  content: "";
}

.ti-letter-a-small:before {
  content: "ﳇ";
}

.ti-letter-b:before {
  content: "";
}

.ti-letter-b-small:before {
  content: "ﳈ";
}

.ti-letter-c:before {
  content: "";
}

.ti-letter-c-small:before {
  content: "ﳉ";
}

.ti-letter-case:before {
  content: "";
}

.ti-letter-case-lower:before {
  content: "";
}

.ti-letter-case-toggle:before {
  content: "";
}

.ti-letter-case-upper:before {
  content: "";
}

.ti-letter-d:before {
  content: "";
}

.ti-letter-d-small:before {
  content: "ﳊ";
}

.ti-letter-e:before {
  content: "";
}

.ti-letter-e-small:before {
  content: "ﳋ";
}

.ti-letter-f:before {
  content: "";
}

.ti-letter-f-small:before {
  content: "ﳌ";
}

.ti-letter-g:before {
  content: "";
}

.ti-letter-g-small:before {
  content: "ﳍ";
}

.ti-letter-h:before {
  content: "";
}

.ti-letter-h-small:before {
  content: "ﳎ";
}

.ti-letter-i:before {
  content: "";
}

.ti-letter-i-small:before {
  content: "ﳏ";
}

.ti-letter-j:before {
  content: "";
}

.ti-letter-j-small:before {
  content: "ﳐ";
}

.ti-letter-k:before {
  content: "";
}

.ti-letter-k-small:before {
  content: "ﳑ";
}

.ti-letter-l:before {
  content: "";
}

.ti-letter-l-small:before {
  content: "ﳒ";
}

.ti-letter-m:before {
  content: "";
}

.ti-letter-m-small:before {
  content: "ﳓ";
}

.ti-letter-n:before {
  content: "";
}

.ti-letter-n-small:before {
  content: "ﳔ";
}

.ti-letter-o:before {
  content: "";
}

.ti-letter-o-small:before {
  content: "ﳕ";
}

.ti-letter-p:before {
  content: "";
}

.ti-letter-p-small:before {
  content: "ﳖ";
}

.ti-letter-q:before {
  content: "";
}

.ti-letter-q-small:before {
  content: "ﳗ";
}

.ti-letter-r:before {
  content: "";
}

.ti-letter-r-small:before {
  content: "ﳘ";
}

.ti-letter-s:before {
  content: "";
}

.ti-letter-s-small:before {
  content: "ﳙ";
}

.ti-letter-spacing:before {
  content: "";
}

.ti-letter-t:before {
  content: "";
}

.ti-letter-t-small:before {
  content: "ﳚ";
}

.ti-letter-u:before {
  content: "";
}

.ti-letter-u-small:before {
  content: "ﳛ";
}

.ti-letter-v:before {
  content: "";
}

.ti-letter-v-small:before {
  content: "ﳜ";
}

.ti-letter-w:before {
  content: "";
}

.ti-letter-w-small:before {
  content: "ﳝ";
}

.ti-letter-x:before {
  content: "";
}

.ti-letter-x-small:before {
  content: "ﳞ";
}

.ti-letter-y:before {
  content: "";
}

.ti-letter-y-small:before {
  content: "ﳟ";
}

.ti-letter-z:before {
  content: "";
}

.ti-letter-z-small:before {
  content: "ﳠ";
}

.ti-library:before {
  content: "﵌";
}

.ti-library-minus:before {
  content: "﵉";
}

.ti-library-photo:before {
  content: "﵊";
}

.ti-library-plus:before {
  content: "﵋";
}

.ti-license:before {
  content: "";
}

.ti-license-off:before {
  content: "";
}

.ti-lifebuoy:before {
  content: "";
}

.ti-lifebuoy-off:before {
  content: "";
}

.ti-lighter:before {
  content: "";
}

.ti-line:before {
  content: "";
}

.ti-line-dashed:before {
  content: "";
}

.ti-line-dotted:before {
  content: "";
}

.ti-line-height:before {
  content: "";
}

.ti-line-scan:before {
  content: "ﲸ";
}

.ti-link:before {
  content: "";
}

.ti-link-minus:before {
  content: "ﴖ";
}

.ti-link-off:before {
  content: "";
}

.ti-link-plus:before {
  content: "ﴗ";
}

.ti-list:before {
  content: "";
}

.ti-list-check:before {
  content: "";
}

.ti-list-details:before {
  content: "";
}

.ti-list-letters:before {
  content: "ﱇ";
}

.ti-list-numbers:before {
  content: "";
}

.ti-list-search:before {
  content: "";
}

.ti-list-tree:before {
  content: "﫺";
}

.ti-live-photo:before {
  content: "";
}

.ti-live-photo-off:before {
  content: "";
}

.ti-live-view:before {
  content: "";
}

.ti-load-balancer:before {
  content: "臭";
}

.ti-loader:before {
  content: "";
}

.ti-loader-2:before {
  content: "";
}

.ti-loader-3:before {
  content: "";
}

.ti-loader-quarter:before {
  content: "";
}

.ti-location:before {
  content: "";
}

.ti-location-bolt:before {
  content: "ﮯ";
}

.ti-location-broken:before {
  content: "";
}

.ti-location-cancel:before {
  content: "ﮰ";
}

.ti-location-check:before {
  content: "ﮱ";
}

.ti-location-code:before {
  content: "﮲";
}

.ti-location-cog:before {
  content: "﮳";
}

.ti-location-discount:before {
  content: "﮴";
}

.ti-location-dollar:before {
  content: "﮵";
}

.ti-location-down:before {
  content: "﮶";
}

.ti-location-exclamation:before {
  content: "﮷";
}

.ti-location-filled:before {
  content: "";
}

.ti-location-heart:before {
  content: "﮸";
}

.ti-location-minus:before {
  content: "﮹";
}

.ti-location-off:before {
  content: "";
}

.ti-location-pause:before {
  content: "﮺";
}

.ti-location-pin:before {
  content: "﮻";
}

.ti-location-plus:before {
  content: "﮼";
}

.ti-location-question:before {
  content: "﮽";
}

.ti-location-search:before {
  content: "﮾";
}

.ti-location-share:before {
  content: "﮿";
}

.ti-location-star:before {
  content: "﯀";
}

.ti-location-up:before {
  content: "﯁";
}

.ti-location-x:before {
  content: "﯂";
}

.ti-lock:before {
  content: "";
}

.ti-lock-access:before {
  content: "";
}

.ti-lock-access-off:before {
  content: "";
}

.ti-lock-bolt:before {
  content: "襤";
}

.ti-lock-cancel:before {
  content: "拉";
}

.ti-lock-check:before {
  content: "臘";
}

.ti-lock-code:before {
  content: "蠟";
}

.ti-lock-cog:before {
  content: "廊";
}

.ti-lock-dollar:before {
  content: "朗";
}

.ti-lock-down:before {
  content: "浪";
}

.ti-lock-exclamation:before {
  content: "狼";
}

.ti-lock-filled:before {
  content: "︕";
}

.ti-lock-heart:before {
  content: "郎";
}

.ti-lock-minus:before {
  content: "來";
}

.ti-lock-off:before {
  content: "";
}

.ti-lock-open:before {
  content: "";
}

.ti-lock-open-2:before {
  content: "ﺨ";
}

.ti-lock-open-off:before {
  content: "";
}

.ti-lock-pause:before {
  content: "冷";
}

.ti-lock-pin:before {
  content: "勞";
}

.ti-lock-plus:before {
  content: "擄";
}

.ti-lock-question:before {
  content: "櫓";
}

.ti-lock-search:before {
  content: "爐";
}

.ti-lock-share:before {
  content: "盧";
}

.ti-lock-square:before {
  content: "";
}

.ti-lock-square-rounded:before {
  content: "";
}

.ti-lock-square-rounded-filled:before {
  content: "";
}

.ti-lock-star:before {
  content: "老";
}

.ti-lock-up:before {
  content: "蘆";
}

.ti-lock-x:before {
  content: "虜";
}

.ti-logic-and:before {
  content: "";
}

.ti-logic-buffer:before {
  content: "";
}

.ti-logic-nand:before {
  content: "";
}

.ti-logic-nor:before {
  content: "";
}

.ti-logic-not:before {
  content: "";
}

.ti-logic-or:before {
  content: "";
}

.ti-logic-xnor:before {
  content: "";
}

.ti-logic-xor:before {
  content: "";
}

.ti-login:before {
  content: "";
}

.ti-login-2:before {
  content: "ﱶ";
}

.ti-logout:before {
  content: "";
}

.ti-logout-2:before {
  content: "奄";
}

.ti-logs:before {
  content: "ﺧ";
}

.ti-lollipop:before {
  content: "";
}

.ti-lollipop-off:before {
  content: "";
}

.ti-luggage:before {
  content: "";
}

.ti-luggage-off:before {
  content: "";
}

.ti-lungs:before {
  content: "";
}

.ti-lungs-filled:before {
  content: "︔";
}

.ti-lungs-off:before {
  content: "";
}

.ti-macro:before {
  content: "";
}

.ti-macro-filled:before {
  content: "︓";
}

.ti-macro-off:before {
  content: "";
}

.ti-magnet:before {
  content: "";
}

.ti-magnet-filled:before {
  content: "︒";
}

.ti-magnet-off:before {
  content: "";
}

.ti-magnetic:before {
  content: "ﲹ";
}

.ti-mail:before {
  content: "";
}

.ti-mail-ai:before {
  content: "僧";
}

.ti-mail-bolt:before {
  content: "路";
}

.ti-mail-cancel:before {
  content: "露";
}

.ti-mail-check:before {
  content: "魯";
}

.ti-mail-code:before {
  content: "鷺";
}

.ti-mail-cog:before {
  content: "碌";
}

.ti-mail-dollar:before {
  content: "祿";
}

.ti-mail-down:before {
  content: "綠";
}

.ti-mail-exclamation:before {
  content: "菉";
}

.ti-mail-fast:before {
  content: "";
}

.ti-mail-filled:before {
  content: "漢";
}

.ti-mail-forward:before {
  content: "";
}

.ti-mail-heart:before {
  content: "錄";
}

.ti-mail-minus:before {
  content: "鹿";
}

.ti-mail-off:before {
  content: "";
}

.ti-mail-opened:before {
  content: "";
}

.ti-mail-opened-filled:before {
  content: "煮";
}

.ti-mail-pause:before {
  content: "論";
}

.ti-mail-pin:before {
  content: "壟";
}

.ti-mail-plus:before {
  content: "弄";
}

.ti-mail-question:before {
  content: "籠";
}

.ti-mail-search:before {
  content: "聾";
}

.ti-mail-share:before {
  content: "牢";
}

.ti-mail-star:before {
  content: "磊";
}

.ti-mail-up:before {
  content: "賂";
}

.ti-mail-x:before {
  content: "雷";
}

.ti-mailbox:before {
  content: "";
}

.ti-mailbox-off:before {
  content: "";
}

.ti-man:before {
  content: "";
}

.ti-man-filled:before {
  content: "︑";
}

.ti-manual-gearbox:before {
  content: "";
}

.ti-manual-gearbox-filled:before {
  content: "︐";
}

.ti-map:before {
  content: "";
}

.ti-map-2:before {
  content: "";
}

.ti-map-bolt:before {
  content: "﯃";
}

.ti-map-cancel:before {
  content: "﯄";
}

.ti-map-check:before {
  content: "﯅";
}

.ti-map-code:before {
  content: "﯆";
}

.ti-map-cog:before {
  content: "﯇";
}

.ti-map-discount:before {
  content: "﯈";
}

.ti-map-dollar:before {
  content: "﯉";
}

.ti-map-down:before {
  content: "﯊";
}

.ti-map-east:before {
  content: "ﱝ";
}

.ti-map-exclamation:before {
  content: "﯋";
}

.ti-map-heart:before {
  content: "﯌";
}

.ti-map-minus:before {
  content: "﯍";
}

.ti-map-north:before {
  content: "ﱞ";
}

.ti-map-off:before {
  content: "";
}

.ti-map-pause:before {
  content: "﯎";
}

.ti-map-pin:before {
  content: "";
}

.ti-map-pin-2:before {
  content: "ﱈ";
}

.ti-map-pin-bolt:before {
  content: "壘";
}

.ti-map-pin-cancel:before {
  content: "屢";
}

.ti-map-pin-check:before {
  content: "樓";
}

.ti-map-pin-code:before {
  content: "淚";
}

.ti-map-pin-cog:before {
  content: "漏";
}

.ti-map-pin-dollar:before {
  content: "累";
}

.ti-map-pin-down:before {
  content: "縷";
}

.ti-map-pin-exclamation:before {
  content: "陋";
}

.ti-map-pin-filled:before {
  content: "";
}

.ti-map-pin-heart:before {
  content: "勒";
}

.ti-map-pin-minus:before {
  content: "肋";
}

.ti-map-pin-off:before {
  content: "";
}

.ti-map-pin-pause:before {
  content: "凜";
}

.ti-map-pin-pin:before {
  content: "凌";
}

.ti-map-pin-plus:before {
  content: "稜";
}

.ti-map-pin-question:before {
  content: "綾";
}

.ti-map-pin-search:before {
  content: "菱";
}

.ti-map-pin-share:before {
  content: "";
}

.ti-map-pin-star:before {
  content: "陵";
}

.ti-map-pin-up:before {
  content: "讀";
}

.ti-map-pin-x:before {
  content: "拏";
}

.ti-map-pins:before {
  content: "";
}

.ti-map-plus:before {
  content: "﯏";
}

.ti-map-question:before {
  content: "﯐";
}

.ti-map-route:before {
  content: "ﱹ";
}

.ti-map-search:before {
  content: "";
}

.ti-map-share:before {
  content: "﯑";
}

.ti-map-south:before {
  content: "ﱟ";
}

.ti-map-star:before {
  content: "﯒";
}

.ti-map-up:before {
  content: "ﯓ";
}

.ti-map-west:before {
  content: "ﱠ";
}

.ti-map-x:before {
  content: "ﯔ";
}

.ti-markdown:before {
  content: "";
}

.ti-markdown-off:before {
  content: "";
}

.ti-marquee:before {
  content: "";
}

.ti-marquee-2:before {
  content: "";
}

.ti-marquee-off:before {
  content: "";
}

.ti-mars:before {
  content: "";
}

.ti-mask:before {
  content: "";
}

.ti-mask-off:before {
  content: "";
}

.ti-masks-theater:before {
  content: "";
}

.ti-masks-theater-off:before {
  content: "";
}

.ti-massage:before {
  content: "";
}

.ti-matchstick:before {
  content: "";
}

.ti-math:before {
  content: "";
}

.ti-math-1-divide-2:before {
  content: "";
}

.ti-math-1-divide-3:before {
  content: "";
}

.ti-math-avg:before {
  content: "";
}

.ti-math-equal-greater:before {
  content: "";
}

.ti-math-equal-lower:before {
  content: "";
}

.ti-math-function:before {
  content: "";
}

.ti-math-function-off:before {
  content: "";
}

.ti-math-function-y:before {
  content: "";
}

.ti-math-greater:before {
  content: "";
}

.ti-math-integral:before {
  content: "";
}

.ti-math-integral-x:before {
  content: "";
}

.ti-math-integrals:before {
  content: "";
}

.ti-math-lower:before {
  content: "";
}

.ti-math-max:before {
  content: "";
}

.ti-math-max-min:before {
  content: "ﶠ";
}

.ti-math-min:before {
  content: "";
}

.ti-math-not:before {
  content: "";
}

.ti-math-off:before {
  content: "";
}

.ti-math-pi:before {
  content: "";
}

.ti-math-pi-divide-2:before {
  content: "";
}

.ti-math-symbols:before {
  content: "";
}

.ti-math-x-divide-2:before {
  content: "";
}

.ti-math-x-divide-y:before {
  content: "";
}

.ti-math-x-divide-y-2:before {
  content: "";
}

.ti-math-x-minus-x:before {
  content: "";
}

.ti-math-x-minus-y:before {
  content: "";
}

.ti-math-x-plus-x:before {
  content: "";
}

.ti-math-x-plus-y:before {
  content: "";
}

.ti-math-xy:before {
  content: "";
}

.ti-math-y-minus-y:before {
  content: "";
}

.ti-math-y-plus-y:before {
  content: "";
}

.ti-maximize:before {
  content: "";
}

.ti-maximize-off:before {
  content: "";
}

.ti-meat:before {
  content: "";
}

.ti-meat-off:before {
  content: "";
}

.ti-medal:before {
  content: "";
}

.ti-medal-2:before {
  content: "";
}

.ti-medical-cross:before {
  content: "";
}

.ti-medical-cross-circle:before {
  content: "﫨";
}

.ti-medical-cross-filled:before {
  content: "";
}

.ti-medical-cross-off:before {
  content: "";
}

.ti-medicine-syrup:before {
  content: "";
}

.ti-meeple:before {
  content: "";
}

.ti-melon:before {
  content: "ﱺ";
}

.ti-menorah:before {
  content: "";
}

.ti-menu:before {
  content: "";
}

.ti-menu-2:before {
  content: "";
}

.ti-menu-deep:before {
  content: "﫻";
}

.ti-menu-order:before {
  content: "";
}

.ti-message:before {
  content: "";
}

.ti-message-2:before {
  content: "";
}

.ti-message-2-bolt:before {
  content: "樂";
}

.ti-message-2-cancel:before {
  content: "諾";
}

.ti-message-2-check:before {
  content: "丹";
}

.ti-message-2-code:before {
  content: "";
}

.ti-message-2-cog:before {
  content: "寧";
}

.ti-message-2-dollar:before {
  content: "怒";
}

.ti-message-2-down:before {
  content: "率";
}

.ti-message-2-exclamation:before {
  content: "異";
}

.ti-message-2-heart:before {
  content: "北";
}

.ti-message-2-minus:before {
  content: "磻";
}

.ti-message-2-off:before {
  content: "";
}

.ti-message-2-pause:before {
  content: "便";
}

.ti-message-2-pin:before {
  content: "復";
}

.ti-message-2-plus:before {
  content: "不";
}

.ti-message-2-question:before {
  content: "泌";
}

.ti-message-2-search:before {
  content: "數";
}

.ti-message-2-share:before {
  content: "";
}

.ti-message-2-star:before {
  content: "索";
}

.ti-message-2-up:before {
  content: "參";
}

.ti-message-2-x:before {
  content: "塞";
}

.ti-message-bolt:before {
  content: "省";
}

.ti-message-cancel:before {
  content: "葉";
}

.ti-message-chatbot:before {
  content: "";
}

.ti-message-check:before {
  content: "說";
}

.ti-message-circle:before {
  content: "";
}

.ti-message-circle-2:before {
  content: "";
}

.ti-message-circle-2-filled:before {
  content: "";
}

.ti-message-circle-bolt:before {
  content: "殺";
}

.ti-message-circle-cancel:before {
  content: "辰";
}

.ti-message-circle-check:before {
  content: "沈";
}

.ti-message-circle-code:before {
  content: "拾";
}

.ti-message-circle-cog:before {
  content: "若";
}

.ti-message-circle-dollar:before {
  content: "掠";
}

.ti-message-circle-down:before {
  content: "略";
}

.ti-message-circle-exclamation:before {
  content: "亮";
}

.ti-message-circle-heart:before {
  content: "兩";
}

.ti-message-circle-minus:before {
  content: "凉";
}

.ti-message-circle-off:before {
  content: "";
}

.ti-message-circle-pause:before {
  content: "梁";
}

.ti-message-circle-pin:before {
  content: "糧";
}

.ti-message-circle-plus:before {
  content: "良";
}

.ti-message-circle-question:before {
  content: "諒";
}

.ti-message-circle-search:before {
  content: "量";
}

.ti-message-circle-share:before {
  content: "勵";
}

.ti-message-circle-star:before {
  content: "呂";
}

.ti-message-circle-up:before {
  content: "女";
}

.ti-message-circle-x:before {
  content: "廬";
}

.ti-message-code:before {
  content: "";
}

.ti-message-cog:before {
  content: "旅";
}

.ti-message-dollar:before {
  content: "濾";
}

.ti-message-dots:before {
  content: "";
}

.ti-message-down:before {
  content: "礪";
}

.ti-message-exclamation:before {
  content: "閭";
}

.ti-message-forward:before {
  content: "";
}

.ti-message-heart:before {
  content: "驪";
}

.ti-message-language:before {
  content: "";
}

.ti-message-minus:before {
  content: "麗";
}

.ti-message-off:before {
  content: "";
}

.ti-message-pause:before {
  content: "黎";
}

.ti-message-pin:before {
  content: "力";
}

.ti-message-plus:before {
  content: "";
}

.ti-message-question:before {
  content: "曆";
}

.ti-message-reply:before {
  content: "﵍";
}

.ti-message-report:before {
  content: "";
}

.ti-message-search:before {
  content: "歷";
}

.ti-message-share:before {
  content: "";
}

.ti-message-star:before {
  content: "轢";
}

.ti-message-up:before {
  content: "年";
}

.ti-message-x:before {
  content: "憐";
}

.ti-messages:before {
  content: "";
}

.ti-messages-off:before {
  content: "";
}

.ti-meteor:before {
  content: "";
}

.ti-meteor-off:before {
  content: "";
}

.ti-meter-cube:before {
  content: "ﵼ";
}

.ti-meter-square:before {
  content: "ﵽ";
}

.ti-metronome:before {
  content: "ﴥ";
}

.ti-michelin-bib-gourmand:before {
  content: "﫩";
}

.ti-michelin-star:before {
  content: "﫫";
}

.ti-michelin-star-green:before {
  content: "﫪";
}

.ti-mickey:before {
  content: "";
}

.ti-mickey-filled:before {
  content: "";
}

.ti-microphone:before {
  content: "";
}

.ti-microphone-2:before {
  content: "";
}

.ti-microphone-2-off:before {
  content: "";
}

.ti-microphone-filled:before {
  content: "️";
}

.ti-microphone-off:before {
  content: "";
}

.ti-microscope:before {
  content: "";
}

.ti-microscope-off:before {
  content: "";
}

.ti-microwave:before {
  content: "";
}

.ti-microwave-filled:before {
  content: "︎";
}

.ti-microwave-off:before {
  content: "";
}

.ti-military-award:before {
  content: "";
}

.ti-military-rank:before {
  content: "";
}

.ti-milk:before {
  content: "";
}

.ti-milk-off:before {
  content: "";
}

.ti-milkshake:before {
  content: "";
}

.ti-minimize:before {
  content: "";
}

.ti-minus:before {
  content: "";
}

.ti-minus-vertical:before {
  content: "";
}

.ti-mist:before {
  content: "";
}

.ti-mist-off:before {
  content: "";
}

.ti-mobiledata:before {
  content: "淋";
}

.ti-mobiledata-off:before {
  content: "林";
}

.ti-moneybag:before {
  content: "";
}

.ti-monkeybar:before {
  content: "ﺴ";
}

.ti-mood-angry:before {
  content: "";
}

.ti-mood-annoyed:before {
  content: "";
}

.ti-mood-annoyed-2:before {
  content: "";
}

.ti-mood-boy:before {
  content: "";
}

.ti-mood-check:before {
  content: "";
}

.ti-mood-cog:before {
  content: "";
}

.ti-mood-confuzed:before {
  content: "";
}

.ti-mood-confuzed-filled:before {
  content: "";
}

.ti-mood-crazy-happy:before {
  content: "";
}

.ti-mood-cry:before {
  content: "";
}

.ti-mood-dollar:before {
  content: "";
}

.ti-mood-edit:before {
  content: "洞";
}

.ti-mood-empty:before {
  content: "";
}

.ti-mood-empty-filled:before {
  content: "";
}

.ti-mood-happy:before {
  content: "";
}

.ti-mood-happy-filled:before {
  content: "";
}

.ti-mood-heart:before {
  content: "";
}

.ti-mood-kid:before {
  content: "";
}

.ti-mood-kid-filled:before {
  content: "";
}

.ti-mood-look-down:before {
  content: "ﴷ";
}

.ti-mood-look-left:before {
  content: "";
}

.ti-mood-look-right:before {
  content: "";
}

.ti-mood-look-up:before {
  content: "ﴸ";
}

.ti-mood-minus:before {
  content: "";
}

.ti-mood-nerd:before {
  content: "";
}

.ti-mood-nervous:before {
  content: "";
}

.ti-mood-neutral:before {
  content: "";
}

.ti-mood-neutral-filled:before {
  content: "";
}

.ti-mood-off:before {
  content: "";
}

.ti-mood-pin:before {
  content: "";
}

.ti-mood-plus:before {
  content: "";
}

.ti-mood-puzzled:before {
  content: "ﴹ";
}

.ti-mood-sad:before {
  content: "";
}

.ti-mood-sad-2:before {
  content: "";
}

.ti-mood-sad-dizzy:before {
  content: "";
}

.ti-mood-sad-filled:before {
  content: "";
}

.ti-mood-sad-squint:before {
  content: "";
}

.ti-mood-search:before {
  content: "";
}

.ti-mood-share:before {
  content: "暴";
}

.ti-mood-sick:before {
  content: "";
}

.ti-mood-silence:before {
  content: "";
}

.ti-mood-sing:before {
  content: "";
}

.ti-mood-smile:before {
  content: "";
}

.ti-mood-smile-beam:before {
  content: "";
}

.ti-mood-smile-dizzy:before {
  content: "";
}

.ti-mood-smile-filled:before {
  content: "";
}

.ti-mood-suprised:before {
  content: "";
}

.ti-mood-tongue:before {
  content: "";
}

.ti-mood-tongue-wink:before {
  content: "";
}

.ti-mood-tongue-wink-2:before {
  content: "";
}

.ti-mood-unamused:before {
  content: "";
}

.ti-mood-up:before {
  content: "";
}

.ti-mood-wink:before {
  content: "";
}

.ti-mood-wink-2:before {
  content: "";
}

.ti-mood-wrrr:before {
  content: "";
}

.ti-mood-x:before {
  content: "";
}

.ti-mood-xd:before {
  content: "";
}

.ti-moon:before {
  content: "";
}

.ti-moon-2:before {
  content: "";
}

.ti-moon-filled:before {
  content: "";
}

.ti-moon-off:before {
  content: "";
}

.ti-moon-stars:before {
  content: "";
}

.ti-moped:before {
  content: "";
}

.ti-motorbike:before {
  content: "";
}

.ti-mountain:before {
  content: "";
}

.ti-mountain-off:before {
  content: "";
}

.ti-mouse:before {
  content: "";
}

.ti-mouse-2:before {
  content: "";
}

.ti-mouse-filled:before {
  content: "אָ";
}

.ti-mouse-off:before {
  content: "";
}

.ti-moustache:before {
  content: "";
}

.ti-movie:before {
  content: "";
}

.ti-movie-off:before {
  content: "";
}

.ti-mug:before {
  content: "";
}

.ti-mug-off:before {
  content: "";
}

.ti-multiplier-0-5x:before {
  content: "";
}

.ti-multiplier-1-5x:before {
  content: "";
}

.ti-multiplier-1x:before {
  content: "";
}

.ti-multiplier-2x:before {
  content: "";
}

.ti-mushroom:before {
  content: "";
}

.ti-mushroom-filled:before {
  content: "";
}

.ti-mushroom-off:before {
  content: "";
}

.ti-music:before {
  content: "";
}

.ti-music-bolt:before {
  content: "ﯕ";
}

.ti-music-cancel:before {
  content: "ﯖ";
}

.ti-music-check:before {
  content: "ﯗ";
}

.ti-music-code:before {
  content: "ﯘ";
}

.ti-music-cog:before {
  content: "ﯙ";
}

.ti-music-discount:before {
  content: "ﯚ";
}

.ti-music-dollar:before {
  content: "ﯛ";
}

.ti-music-down:before {
  content: "ﯜ";
}

.ti-music-exclamation:before {
  content: "ﯝ";
}

.ti-music-heart:before {
  content: "ﯞ";
}

.ti-music-minus:before {
  content: "ﯟ";
}

.ti-music-off:before {
  content: "";
}

.ti-music-pause:before {
  content: "ﯠ";
}

.ti-music-pin:before {
  content: "ﯡ";
}

.ti-music-plus:before {
  content: "ﯢ";
}

.ti-music-question:before {
  content: "ﯣ";
}

.ti-music-search:before {
  content: "ﯤ";
}

.ti-music-share:before {
  content: "ﯥ";
}

.ti-music-star:before {
  content: "ﯦ";
}

.ti-music-up:before {
  content: "ﯧ";
}

.ti-music-x:before {
  content: "ﯨ";
}

.ti-navigation:before {
  content: "";
}

.ti-navigation-bolt:before {
  content: "ﯩ";
}

.ti-navigation-cancel:before {
  content: "ﯪ";
}

.ti-navigation-check:before {
  content: "ﯫ";
}

.ti-navigation-code:before {
  content: "ﯬ";
}

.ti-navigation-cog:before {
  content: "ﯭ";
}

.ti-navigation-discount:before {
  content: "ﯮ";
}

.ti-navigation-dollar:before {
  content: "ﯯ";
}

.ti-navigation-down:before {
  content: "ﯰ";
}

.ti-navigation-east:before {
  content: "ﲺ";
}

.ti-navigation-exclamation:before {
  content: "ﯱ";
}

.ti-navigation-filled:before {
  content: "";
}

.ti-navigation-heart:before {
  content: "ﯲ";
}

.ti-navigation-minus:before {
  content: "ﯳ";
}

.ti-navigation-north:before {
  content: "ﲻ";
}

.ti-navigation-off:before {
  content: "";
}

.ti-navigation-pause:before {
  content: "ﯴ";
}

.ti-navigation-pin:before {
  content: "ﯵ";
}

.ti-navigation-plus:before {
  content: "ﯶ";
}

.ti-navigation-question:before {
  content: "ﯷ";
}

.ti-navigation-search:before {
  content: "ﯸ";
}

.ti-navigation-share:before {
  content: "ﯹ";
}

.ti-navigation-south:before {
  content: "ﲼ";
}

.ti-navigation-star:before {
  content: "ﯺ";
}

.ti-navigation-top:before {
  content: "﫬";
}

.ti-navigation-up:before {
  content: "ﯻ";
}

.ti-navigation-west:before {
  content: "ﲽ";
}

.ti-navigation-x:before {
  content: "ﯼ";
}

.ti-needle:before {
  content: "";
}

.ti-needle-thread:before {
  content: "";
}

.ti-network:before {
  content: "";
}

.ti-network-off:before {
  content: "";
}

.ti-new-section:before {
  content: "";
}

.ti-news:before {
  content: "";
}

.ti-news-off:before {
  content: "";
}

.ti-nfc:before {
  content: "";
}

.ti-nfc-off:before {
  content: "";
}

.ti-no-copyright:before {
  content: "";
}

.ti-no-creative-commons:before {
  content: "";
}

.ti-no-derivatives:before {
  content: "";
}

.ti-north-star:before {
  content: "";
}

.ti-note:before {
  content: "";
}

.ti-note-off:before {
  content: "";
}

.ti-notebook:before {
  content: "";
}

.ti-notebook-off:before {
  content: "";
}

.ti-notes:before {
  content: "";
}

.ti-notes-off:before {
  content: "";
}

.ti-notification:before {
  content: "";
}

.ti-notification-off:before {
  content: "";
}

.ti-number:before {
  content: "";
}

.ti-number-0:before {
  content: "";
}

.ti-number-0-small:before {
  content: "ﳡ";
}

.ti-number-1:before {
  content: "";
}

.ti-number-1-small:before {
  content: "ﳢ";
}

.ti-number-10-small:before {
  content: "ﳣ";
}

.ti-number-11-small:before {
  content: "ﳤ";
}

.ti-number-12-small:before {
  content: "ﳥ";
}

.ti-number-123:before {
  content: "";
}

.ti-number-13-small:before {
  content: "ﳦ";
}

.ti-number-14-small:before {
  content: "ﳧ";
}

.ti-number-15-small:before {
  content: "ﳨ";
}

.ti-number-16-small:before {
  content: "ﳩ";
}

.ti-number-17-small:before {
  content: "ﳪ";
}

.ti-number-18-small:before {
  content: "ﳫ";
}

.ti-number-19-small:before {
  content: "ﳬ";
}

.ti-number-2:before {
  content: "";
}

.ti-number-2-small:before {
  content: "ﳭ";
}

.ti-number-20-small:before {
  content: "ﳮ";
}

.ti-number-21-small:before {
  content: "ﳯ";
}

.ti-number-22-small:before {
  content: "ﳰ";
}

.ti-number-23-small:before {
  content: "ﳱ";
}

.ti-number-24-small:before {
  content: "ﳲ";
}

.ti-number-25-small:before {
  content: "ﳳ";
}

.ti-number-26-small:before {
  content: "ﳴ";
}

.ti-number-27-small:before {
  content: "ﳵ";
}

.ti-number-28-small:before {
  content: "ﳶ";
}

.ti-number-29-small:before {
  content: "ﳷ";
}

.ti-number-3:before {
  content: "";
}

.ti-number-3-small:before {
  content: "ﳸ";
}

.ti-number-4:before {
  content: "";
}

.ti-number-4-small:before {
  content: "ﳹ";
}

.ti-number-5:before {
  content: "";
}

.ti-number-5-small:before {
  content: "ﳺ";
}

.ti-number-6:before {
  content: "";
}

.ti-number-6-small:before {
  content: "ﳻ";
}

.ti-number-7:before {
  content: "";
}

.ti-number-7-small:before {
  content: "ﳼ";
}

.ti-number-8:before {
  content: "";
}

.ti-number-8-small:before {
  content: "ﳽ";
}

.ti-number-9:before {
  content: "";
}

.ti-number-9-small:before {
  content: "ﳾ";
}

.ti-numbers:before {
  content: "";
}

.ti-nurse:before {
  content: "";
}

.ti-nut:before {
  content: "ﱡ";
}

.ti-octagon:before {
  content: "";
}

.ti-octagon-filled:before {
  content: "";
}

.ti-octagon-minus:before {
  content: "ﲒ";
}

.ti-octagon-minus-2:before {
  content: "ﲑ";
}

.ti-octagon-off:before {
  content: "";
}

.ti-octagon-plus:before {
  content: "ﲔ";
}

.ti-octagon-plus-2:before {
  content: "ﲓ";
}

.ti-octahedron:before {
  content: "类";
}

.ti-octahedron-off:before {
  content: "窱";
}

.ti-octahedron-plus:before {
  content: "節";
}

.ti-old:before {
  content: "";
}

.ti-olympics:before {
  content: "";
}

.ti-olympics-off:before {
  content: "";
}

.ti-om:before {
  content: "";
}

.ti-omega:before {
  content: "";
}

.ti-outbound:before {
  content: "";
}

.ti-outlet:before {
  content: "";
}

.ti-oval:before {
  content: "";
}

.ti-oval-filled:before {
  content: "";
}

.ti-oval-vertical:before {
  content: "";
}

.ti-oval-vertical-filled:before {
  content: "";
}

.ti-overline:before {
  content: "";
}

.ti-package:before {
  content: "";
}

.ti-package-export:before {
  content: "";
}

.ti-package-import:before {
  content: "";
}

.ti-package-off:before {
  content: "";
}

.ti-packages:before {
  content: "";
}

.ti-pacman:before {
  content: "";
}

.ti-page-break:before {
  content: "";
}

.ti-paint:before {
  content: "";
}

.ti-paint-filled:before {
  content: "";
}

.ti-paint-off:before {
  content: "";
}

.ti-palette:before {
  content: "";
}

.ti-palette-off:before {
  content: "";
}

.ti-panorama-horizontal:before {
  content: "";
}

.ti-panorama-horizontal-off:before {
  content: "";
}

.ti-panorama-vertical:before {
  content: "";
}

.ti-panorama-vertical-off:before {
  content: "";
}

.ti-paper-bag:before {
  content: "";
}

.ti-paper-bag-off:before {
  content: "";
}

.ti-paperclip:before {
  content: "";
}

.ti-parachute:before {
  content: "";
}

.ti-parachute-off:before {
  content: "";
}

.ti-parentheses:before {
  content: "";
}

.ti-parentheses-off:before {
  content: "";
}

.ti-parking:before {
  content: "";
}

.ti-parking-circle:before {
  content: "ﵚ";
}

.ti-parking-off:before {
  content: "";
}

.ti-password:before {
  content: "";
}

.ti-password-fingerprint:before {
  content: "ﱻ";
}

.ti-password-mobile-phone:before {
  content: "ﱼ";
}

.ti-password-user:before {
  content: "ﱽ";
}

.ti-paw:before {
  content: "";
}

.ti-paw-filled:before {
  content: "";
}

.ti-paw-off:before {
  content: "";
}

.ti-paywall:before {
  content: "ﵾ";
}

.ti-pdf:before {
  content: "";
}

.ti-peace:before {
  content: "";
}

.ti-pencil:before {
  content: "";
}

.ti-pencil-bolt:before {
  content: "ﯽ";
}

.ti-pencil-cancel:before {
  content: "ﯾ";
}

.ti-pencil-check:before {
  content: "ﯿ";
}

.ti-pencil-code:before {
  content: "ﰀ";
}

.ti-pencil-cog:before {
  content: "ﰁ";
}

.ti-pencil-discount:before {
  content: "ﰂ";
}

.ti-pencil-dollar:before {
  content: "ﰃ";
}

.ti-pencil-down:before {
  content: "ﰄ";
}

.ti-pencil-exclamation:before {
  content: "ﰅ";
}

.ti-pencil-heart:before {
  content: "ﰆ";
}

.ti-pencil-minus:before {
  content: "";
}

.ti-pencil-off:before {
  content: "";
}

.ti-pencil-pause:before {
  content: "ﰇ";
}

.ti-pencil-pin:before {
  content: "ﰈ";
}

.ti-pencil-plus:before {
  content: "";
}

.ti-pencil-question:before {
  content: "ﰉ";
}

.ti-pencil-search:before {
  content: "ﰊ";
}

.ti-pencil-share:before {
  content: "ﰋ";
}

.ti-pencil-star:before {
  content: "ﰌ";
}

.ti-pencil-up:before {
  content: "ﰍ";
}

.ti-pencil-x:before {
  content: "ﰎ";
}

.ti-pennant:before {
  content: "";
}

.ti-pennant-2:before {
  content: "";
}

.ti-pennant-2-filled:before {
  content: "";
}

.ti-pennant-filled:before {
  content: "";
}

.ti-pennant-off:before {
  content: "";
}

.ti-pentagon:before {
  content: "";
}

.ti-pentagon-filled:before {
  content: "";
}

.ti-pentagon-minus:before {
  content: "ﺳ";
}

.ti-pentagon-number-0:before {
  content: "ﱾ";
}

.ti-pentagon-number-1:before {
  content: "ﱿ";
}

.ti-pentagon-number-2:before {
  content: "ﲀ";
}

.ti-pentagon-number-3:before {
  content: "ﲁ";
}

.ti-pentagon-number-4:before {
  content: "ﲂ";
}

.ti-pentagon-number-5:before {
  content: "ﲃ";
}

.ti-pentagon-number-6:before {
  content: "ﲄ";
}

.ti-pentagon-number-7:before {
  content: "ﲅ";
}

.ti-pentagon-number-8:before {
  content: "ﲆ";
}

.ti-pentagon-number-9:before {
  content: "ﲇ";
}

.ti-pentagon-off:before {
  content: "";
}

.ti-pentagon-plus:before {
  content: "ﱉ";
}

.ti-pentagon-x:before {
  content: "ﲈ";
}

.ti-pentagram:before {
  content: "";
}

.ti-pepper:before {
  content: "";
}

.ti-pepper-off:before {
  content: "";
}

.ti-percentage:before {
  content: "";
}

.ti-perfume:before {
  content: "";
}

.ti-perspective:before {
  content: "";
}

.ti-perspective-off:before {
  content: "";
}

.ti-phone:before {
  content: "";
}

.ti-phone-call:before {
  content: "";
}

.ti-phone-calling:before {
  content: "";
}

.ti-phone-check:before {
  content: "";
}

.ti-phone-filled:before {
  content: "爫";
}

.ti-phone-incoming:before {
  content: "";
}

.ti-phone-off:before {
  content: "";
}

.ti-phone-outgoing:before {
  content: "";
}

.ti-phone-pause:before {
  content: "";
}

.ti-phone-plus:before {
  content: "";
}

.ti-phone-x:before {
  content: "";
}

.ti-photo:before {
  content: "";
}

.ti-photo-ai:before {
  content: "免";
}

.ti-photo-bolt:before {
  content: "戀";
}

.ti-photo-cancel:before {
  content: "";
}

.ti-photo-check:before {
  content: "";
}

.ti-photo-circle:before {
  content: "ﱊ";
}

.ti-photo-circle-minus:before {
  content: "ﱢ";
}

.ti-photo-circle-plus:before {
  content: "ﱣ";
}

.ti-photo-code:before {
  content: "撚";
}

.ti-photo-cog:before {
  content: "漣";
}

.ti-photo-dollar:before {
  content: "煉";
}

.ti-photo-down:before {
  content: "";
}

.ti-photo-edit:before {
  content: "";
}

.ti-photo-exclamation:before {
  content: "璉";
}

.ti-photo-filled:before {
  content: "琢";
}

.ti-photo-heart:before {
  content: "";
}

.ti-photo-hexagon:before {
  content: "ﱋ";
}

.ti-photo-minus:before {
  content: "";
}

.ti-photo-off:before {
  content: "";
}

.ti-photo-pause:before {
  content: "秊";
}

.ti-photo-pentagon:before {
  content: "ﱌ";
}

.ti-photo-pin:before {
  content: "練";
}

.ti-photo-plus:before {
  content: "";
}

.ti-photo-question:before {
  content: "聯";
}

.ti-photo-scan:before {
  content: "ﲨ";
}

.ti-photo-search:before {
  content: "";
}

.ti-photo-sensor:before {
  content: "";
}

.ti-photo-sensor-2:before {
  content: "";
}

.ti-photo-sensor-3:before {
  content: "";
}

.ti-photo-share:before {
  content: "輦";
}

.ti-photo-shield:before {
  content: "";
}

.ti-photo-square-rounded:before {
  content: "ﱍ";
}

.ti-photo-star:before {
  content: "";
}

.ti-photo-up:before {
  content: "";
}

.ti-photo-video:before {
  content: "ﲕ";
}

.ti-photo-x:before {
  content: "";
}

.ti-physotherapist:before {
  content: "";
}

.ti-piano:before {
  content: "䀘";
}

.ti-pick:before {
  content: "﫼";
}

.ti-picture-in-picture:before {
  content: "";
}

.ti-picture-in-picture-filled:before {
  content: "ﻁ";
}

.ti-picture-in-picture-off:before {
  content: "";
}

.ti-picture-in-picture-on:before {
  content: "";
}

.ti-picture-in-picture-top:before {
  content: "";
}

.ti-picture-in-picture-top-filled:before {
  content: "ﻂ";
}

.ti-pig:before {
  content: "";
}

.ti-pig-money:before {
  content: "";
}

.ti-pig-off:before {
  content: "";
}

.ti-pilcrow:before {
  content: "";
}

.ti-pilcrow-left:before {
  content: "ﵿ";
}

.ti-pilcrow-right:before {
  content: "ﶀ";
}

.ti-pill:before {
  content: "";
}

.ti-pill-off:before {
  content: "";
}

.ti-pills:before {
  content: "";
}

.ti-pin:before {
  content: "";
}

.ti-pin-end:before {
  content: "ﵛ";
}

.ti-pin-filled:before {
  content: "";
}

.ti-pin-invoke:before {
  content: "ﵜ";
}

.ti-ping-pong:before {
  content: "";
}

.ti-pinned:before {
  content: "";
}

.ti-pinned-filled:before {
  content: "";
}

.ti-pinned-off:before {
  content: "";
}

.ti-pizza:before {
  content: "";
}

.ti-pizza-off:before {
  content: "";
}

.ti-placeholder:before {
  content: "";
}

.ti-plane:before {
  content: "";
}

.ti-plane-arrival:before {
  content: "";
}

.ti-plane-departure:before {
  content: "";
}

.ti-plane-inflight:before {
  content: "";
}

.ti-plane-off:before {
  content: "";
}

.ti-plane-tilt:before {
  content: "";
}

.ti-planet:before {
  content: "";
}

.ti-planet-off:before {
  content: "";
}

.ti-plant:before {
  content: "";
}

.ti-plant-2:before {
  content: "";
}

.ti-plant-2-off:before {
  content: "";
}

.ti-plant-off:before {
  content: "";
}

.ti-play-basketball:before {
  content: "辶";
}

.ti-play-card:before {
  content: "";
}

.ti-play-card-off:before {
  content: "";
}

.ti-play-football:before {
  content: "逸";
}

.ti-play-handball:before {
  content: "難";
}

.ti-play-volleyball:before {
  content: "響";
}

.ti-player-eject:before {
  content: "";
}

.ti-player-eject-filled:before {
  content: "";
}

.ti-player-pause:before {
  content: "";
}

.ti-player-pause-filled:before {
  content: "";
}

.ti-player-play:before {
  content: "";
}

.ti-player-play-filled:before {
  content: "";
}

.ti-player-record:before {
  content: "";
}

.ti-player-record-filled:before {
  content: "";
}

.ti-player-skip-back:before {
  content: "";
}

.ti-player-skip-back-filled:before {
  content: "";
}

.ti-player-skip-forward:before {
  content: "";
}

.ti-player-skip-forward-filled:before {
  content: "";
}

.ti-player-stop:before {
  content: "";
}

.ti-player-stop-filled:before {
  content: "";
}

.ti-player-track-next:before {
  content: "";
}

.ti-player-track-next-filled:before {
  content: "";
}

.ti-player-track-prev:before {
  content: "";
}

.ti-player-track-prev-filled:before {
  content: "";
}

.ti-playlist:before {
  content: "";
}

.ti-playlist-add:before {
  content: "";
}

.ti-playlist-off:before {
  content: "";
}

.ti-playlist-x:before {
  content: "";
}

.ti-playstation-circle:before {
  content: "";
}

.ti-playstation-square:before {
  content: "";
}

.ti-playstation-triangle:before {
  content: "";
}

.ti-playstation-x:before {
  content: "";
}

.ti-plug:before {
  content: "";
}

.ti-plug-connected:before {
  content: "";
}

.ti-plug-connected-x:before {
  content: "";
}

.ti-plug-off:before {
  content: "";
}

.ti-plug-x:before {
  content: "";
}

.ti-plus:before {
  content: "";
}

.ti-plus-equal:before {
  content: "";
}

.ti-plus-minus:before {
  content: "";
}

.ti-png:before {
  content: "";
}

.ti-podium:before {
  content: "";
}

.ti-podium-off:before {
  content: "";
}

.ti-point:before {
  content: "";
}

.ti-point-filled:before {
  content: "";
}

.ti-point-off:before {
  content: "";
}

.ti-pointer:before {
  content: "";
}

.ti-pointer-bolt:before {
  content: "蓮";
}

.ti-pointer-cancel:before {
  content: "連";
}

.ti-pointer-check:before {
  content: "鍊";
}

.ti-pointer-code:before {
  content: "列";
}

.ti-pointer-cog:before {
  content: "劣";
}

.ti-pointer-dollar:before {
  content: "咽";
}

.ti-pointer-down:before {
  content: "烈";
}

.ti-pointer-exclamation:before {
  content: "裂";
}

.ti-pointer-filled:before {
  content: "אּ";
}

.ti-pointer-heart:before {
  content: "說";
}

.ti-pointer-minus:before {
  content: "廉";
}

.ti-pointer-off:before {
  content: "念";
}

.ti-pointer-pause:before {
  content: "捻";
}

.ti-pointer-pin:before {
  content: "殮";
}

.ti-pointer-plus:before {
  content: "簾";
}

.ti-pointer-question:before {
  content: "獵";
}

.ti-pointer-search:before {
  content: "令";
}

.ti-pointer-share:before {
  content: "囹";
}

.ti-pointer-star:before {
  content: "寧";
}

.ti-pointer-up:before {
  content: "嶺";
}

.ti-pointer-x:before {
  content: "怜";
}

.ti-pokeball:before {
  content: "";
}

.ti-pokeball-off:before {
  content: "";
}

.ti-poker-chip:before {
  content: "";
}

.ti-polaroid:before {
  content: "";
}

.ti-polaroid-filled:before {
  content: "碑";
}

.ti-polygon:before {
  content: "";
}

.ti-polygon-off:before {
  content: "";
}

.ti-poo:before {
  content: "";
}

.ti-pool:before {
  content: "";
}

.ti-pool-off:before {
  content: "";
}

.ti-power:before {
  content: "";
}

.ti-pray:before {
  content: "";
}

.ti-premium-rights:before {
  content: "";
}

.ti-prescription:before {
  content: "";
}

.ti-presentation:before {
  content: "";
}

.ti-presentation-analytics:before {
  content: "";
}

.ti-presentation-off:before {
  content: "";
}

.ti-printer:before {
  content: "";
}

.ti-printer-off:before {
  content: "";
}

.ti-prism:before {
  content: "缾";
}

.ti-prism-light:before {
  content: "ﺦ";
}

.ti-prism-off:before {
  content: "絛";
}

.ti-prism-plus:before {
  content: "練";
}

.ti-prison:before {
  content: "";
}

.ti-progress:before {
  content: "嗀";
}

.ti-progress-alert:before {
  content: "輻";
}

.ti-progress-bolt:before {
  content: "行";
}

.ti-progress-check:before {
  content: "降";
}

.ti-progress-down:before {
  content: "見";
}

.ti-progress-help:before {
  content: "廓";
}

.ti-progress-x:before {
  content: "兀";
}

.ti-prompt:before {
  content: "";
}

.ti-prong:before {
  content: "ﶡ";
}

.ti-propeller:before {
  content: "";
}

.ti-propeller-off:before {
  content: "";
}

.ti-protocol:before {
  content: "ﶁ";
}

.ti-pumpkin-scary:before {
  content: "";
}

.ti-puzzle:before {
  content: "";
}

.ti-puzzle-2:before {
  content: "";
}

.ti-puzzle-filled:before {
  content: "";
}

.ti-puzzle-off:before {
  content: "";
}

.ti-pyramid:before {
  content: "";
}

.ti-pyramid-off:before {
  content: "";
}

.ti-pyramid-plus:before {
  content: "者";
}

.ti-qrcode:before {
  content: "";
}

.ti-qrcode-off:before {
  content: "";
}

.ti-question-mark:before {
  content: "";
}

.ti-quote:before {
  content: "";
}

.ti-quote-off:before {
  content: "";
}

.ti-quotes:before {
  content: "ﬞ";
}

.ti-radar:before {
  content: "";
}

.ti-radar-2:before {
  content: "";
}

.ti-radar-filled:before {
  content: "︍";
}

.ti-radar-off:before {
  content: "";
}

.ti-radio:before {
  content: "";
}

.ti-radio-off:before {
  content: "";
}

.ti-radioactive:before {
  content: "";
}

.ti-radioactive-filled:before {
  content: "";
}

.ti-radioactive-off:before {
  content: "";
}

.ti-radius-bottom-left:before {
  content: "";
}

.ti-radius-bottom-right:before {
  content: "";
}

.ti-radius-top-left:before {
  content: "";
}

.ti-radius-top-right:before {
  content: "";
}

.ti-rainbow:before {
  content: "";
}

.ti-rainbow-off:before {
  content: "";
}

.ti-rating-12-plus:before {
  content: "";
}

.ti-rating-14-plus:before {
  content: "";
}

.ti-rating-16-plus:before {
  content: "";
}

.ti-rating-18-plus:before {
  content: "";
}

.ti-rating-21-plus:before {
  content: "";
}

.ti-razor:before {
  content: "";
}

.ti-razor-electric:before {
  content: "";
}

.ti-receipt:before {
  content: "";
}

.ti-receipt-2:before {
  content: "";
}

.ti-receipt-bitcoin:before {
  content: "ﵦ";
}

.ti-receipt-dollar:before {
  content: "ﵧ";
}

.ti-receipt-euro:before {
  content: "ﵨ";
}

.ti-receipt-off:before {
  content: "";
}

.ti-receipt-pound:before {
  content: "ﵩ";
}

.ti-receipt-refund:before {
  content: "";
}

.ti-receipt-rupee:before {
  content: "ﶂ";
}

.ti-receipt-tax:before {
  content: "";
}

.ti-receipt-yen:before {
  content: "ﵪ";
}

.ti-receipt-yuan:before {
  content: "ﵫ";
}

.ti-recharging:before {
  content: "";
}

.ti-record-mail:before {
  content: "";
}

.ti-record-mail-off:before {
  content: "";
}

.ti-rectangle:before {
  content: "";
}

.ti-rectangle-filled:before {
  content: "";
}

.ti-rectangle-rounded-bottom:before {
  content: "﫭";
}

.ti-rectangle-rounded-top:before {
  content: "﫮";
}

.ti-rectangle-vertical:before {
  content: "";
}

.ti-rectangle-vertical-filled:before {
  content: "";
}

.ti-rectangular-prism:before {
  content: "蝹";
}

.ti-rectangular-prism-off:before {
  content: "荒";
}

.ti-rectangular-prism-plus:before {
  content: "華";
}

.ti-recycle:before {
  content: "";
}

.ti-recycle-off:before {
  content: "";
}

.ti-refresh:before {
  content: "";
}

.ti-refresh-alert:before {
  content: "";
}

.ti-refresh-dot:before {
  content: "";
}

.ti-refresh-off:before {
  content: "";
}

.ti-regex:before {
  content: "";
}

.ti-regex-off:before {
  content: "";
}

.ti-registered:before {
  content: "";
}

.ti-relation-many-to-many:before {
  content: "";
}

.ti-relation-many-to-many-filled:before {
  content: "︌";
}

.ti-relation-one-to-many:before {
  content: "";
}

.ti-relation-one-to-many-filled:before {
  content: "︋";
}

.ti-relation-one-to-one:before {
  content: "";
}

.ti-relation-one-to-one-filled:before {
  content: "︊";
}

.ti-reload:before {
  content: "";
}

.ti-reorder:before {
  content: "ﰕ";
}

.ti-repeat:before {
  content: "";
}

.ti-repeat-off:before {
  content: "";
}

.ti-repeat-once:before {
  content: "";
}

.ti-replace:before {
  content: "";
}

.ti-replace-filled:before {
  content: "";
}

.ti-replace-off:before {
  content: "";
}

.ti-report:before {
  content: "";
}

.ti-report-analytics:before {
  content: "";
}

.ti-report-medical:before {
  content: "";
}

.ti-report-money:before {
  content: "";
}

.ti-report-off:before {
  content: "";
}

.ti-report-search:before {
  content: "";
}

.ti-reserved-line:before {
  content: "臨";
}

.ti-resize:before {
  content: "";
}

.ti-restore:before {
  content: "﫽";
}

.ti-rewind-backward-10:before {
  content: "諸";
}

.ti-rewind-backward-15:before {
  content: "請";
}

.ti-rewind-backward-20:before {
  content: "謁";
}

.ti-rewind-backward-30:before {
  content: "諾";
}

.ti-rewind-backward-40:before {
  content: "諭";
}

.ti-rewind-backward-5:before {
  content: "謹";
}

.ti-rewind-backward-50:before {
  content: "變";
}

.ti-rewind-backward-60:before {
  content: "贈";
}

.ti-rewind-forward-10:before {
  content: "輸";
}

.ti-rewind-forward-15:before {
  content: "遲";
}

.ti-rewind-forward-20:before {
  content: "醙";
}

.ti-rewind-forward-30:before {
  content: "鉶";
}

.ti-rewind-forward-40:before {
  content: "陼";
}

.ti-rewind-forward-5:before {
  content: "難";
}

.ti-rewind-forward-50:before {
  content: "靖";
}

.ti-rewind-forward-60:before {
  content: "韛";
}

.ti-ribbon-health:before {
  content: "";
}

.ti-rings:before {
  content: "頻";
}

.ti-ripple:before {
  content: "";
}

.ti-ripple-off:before {
  content: "";
}

.ti-road:before {
  content: "";
}

.ti-road-off:before {
  content: "";
}

.ti-road-sign:before {
  content: "";
}

.ti-robot:before {
  content: "";
}

.ti-robot-face:before {
  content: "ﲾ";
}

.ti-robot-off:before {
  content: "";
}

.ti-rocket:before {
  content: "";
}

.ti-rocket-off:before {
  content: "";
}

.ti-roller-skating:before {
  content: "";
}

.ti-rollercoaster:before {
  content: "";
}

.ti-rollercoaster-off:before {
  content: "";
}

.ti-rosette:before {
  content: "";
}

.ti-rosette-discount:before {
  content: "";
}

.ti-rosette-discount-check:before {
  content: "";
}

.ti-rosette-discount-check-filled:before {
  content: "";
}

.ti-rosette-discount-off:before {
  content: "";
}

.ti-rosette-filled:before {
  content: "";
}

.ti-rosette-number-0:before {
  content: "";
}

.ti-rosette-number-1:before {
  content: "";
}

.ti-rosette-number-2:before {
  content: "";
}

.ti-rosette-number-3:before {
  content: "";
}

.ti-rosette-number-4:before {
  content: "";
}

.ti-rosette-number-5:before {
  content: "";
}

.ti-rosette-number-6:before {
  content: "";
}

.ti-rosette-number-7:before {
  content: "";
}

.ti-rosette-number-8:before {
  content: "";
}

.ti-rosette-number-9:before {
  content: "";
}

.ti-rotate:before {
  content: "";
}

.ti-rotate-2:before {
  content: "";
}

.ti-rotate-360:before {
  content: "";
}

.ti-rotate-3d:before {
  content: "";
}

.ti-rotate-clockwise:before {
  content: "";
}

.ti-rotate-clockwise-2:before {
  content: "";
}

.ti-rotate-dot:before {
  content: "";
}

.ti-rotate-rectangle:before {
  content: "";
}

.ti-route:before {
  content: "";
}

.ti-route-2:before {
  content: "";
}

.ti-route-alt-left:before {
  content: "ﲩ";
}

.ti-route-alt-right:before {
  content: "ﲪ";
}

.ti-route-off:before {
  content: "";
}

.ti-route-scan:before {
  content: "ﲿ";
}

.ti-route-square:before {
  content: "ﲬ";
}

.ti-route-square-2:before {
  content: "ﲫ";
}

.ti-route-x:before {
  content: "ﲮ";
}

.ti-route-x-2:before {
  content: "ﲭ";
}

.ti-router:before {
  content: "";
}

.ti-router-off:before {
  content: "";
}

.ti-row-insert-bottom:before {
  content: "";
}

.ti-row-insert-top:before {
  content: "";
}

.ti-row-remove:before {
  content: "﫾";
}

.ti-rss:before {
  content: "";
}

.ti-rubber-stamp:before {
  content: "";
}

.ti-rubber-stamp-off:before {
  content: "";
}

.ti-ruler:before {
  content: "";
}

.ti-ruler-2:before {
  content: "";
}

.ti-ruler-2-off:before {
  content: "";
}

.ti-ruler-3:before {
  content: "";
}

.ti-ruler-measure:before {
  content: "";
}

.ti-ruler-off:before {
  content: "";
}

.ti-run:before {
  content: "";
}

.ti-rv-truck:before {
  content: "ﳀ";
}

.ti-s-turn-down:before {
  content: "";
}

.ti-s-turn-left:before {
  content: "";
}

.ti-s-turn-right:before {
  content: "";
}

.ti-s-turn-up:before {
  content: "";
}

.ti-sailboat:before {
  content: "";
}

.ti-sailboat-2:before {
  content: "";
}

.ti-sailboat-off:before {
  content: "";
}

.ti-salad:before {
  content: "";
}

.ti-salt:before {
  content: "";
}

.ti-sandbox:before {
  content: "ﵬ";
}

.ti-satellite:before {
  content: "";
}

.ti-satellite-off:before {
  content: "";
}

.ti-sausage:before {
  content: "";
}

.ti-scale:before {
  content: "";
}

.ti-scale-off:before {
  content: "";
}

.ti-scale-outline:before {
  content: "";
}

.ti-scale-outline-off:before {
  content: "";
}

.ti-scan:before {
  content: "";
}

.ti-scan-eye:before {
  content: "";
}

.ti-scan-position:before {
  content: "ﶬ";
}

.ti-schema:before {
  content: "";
}

.ti-schema-off:before {
  content: "";
}

.ti-school:before {
  content: "";
}

.ti-school-bell:before {
  content: "";
}

.ti-school-off:before {
  content: "";
}

.ti-scissors:before {
  content: "";
}

.ti-scissors-off:before {
  content: "";
}

.ti-scooter:before {
  content: "";
}

.ti-scooter-electric:before {
  content: "";
}

.ti-scoreboard:before {
  content: "恵";
}

.ti-screen-share:before {
  content: "";
}

.ti-screen-share-off:before {
  content: "";
}

.ti-screenshot:before {
  content: "";
}

.ti-scribble:before {
  content: "";
}

.ti-scribble-off:before {
  content: "";
}

.ti-script:before {
  content: "";
}

.ti-script-minus:before {
  content: "";
}

.ti-script-plus:before {
  content: "";
}

.ti-script-x:before {
  content: "";
}

.ti-scuba-diving:before {
  content: "﵎";
}

.ti-scuba-mask:before {
  content: "";
}

.ti-scuba-mask-off:before {
  content: "";
}

.ti-sdk:before {
  content: "";
}

.ti-search:before {
  content: "";
}

.ti-search-off:before {
  content: "";
}

.ti-section:before {
  content: "";
}

.ti-section-filled:before {
  content: "︉";
}

.ti-section-sign:before {
  content: "";
}

.ti-seeding:before {
  content: "";
}

.ti-seeding-off:before {
  content: "";
}

.ti-select:before {
  content: "";
}

.ti-select-all:before {
  content: "立";
}

.ti-selector:before {
  content: "";
}

.ti-send:before {
  content: "";
}

.ti-send-2:before {
  content: "ﵝ";
}

.ti-send-off:before {
  content: "";
}

.ti-seo:before {
  content: "";
}

.ti-separator:before {
  content: "";
}

.ti-separator-horizontal:before {
  content: "";
}

.ti-separator-vertical:before {
  content: "";
}

.ti-server:before {
  content: "";
}

.ti-server-2:before {
  content: "";
}

.ti-server-bolt:before {
  content: "";
}

.ti-server-cog:before {
  content: "";
}

.ti-server-off:before {
  content: "";
}

.ti-servicemark:before {
  content: "";
}

.ti-settings:before {
  content: "";
}

.ti-settings-2:before {
  content: "";
}

.ti-settings-automation:before {
  content: "";
}

.ti-settings-bolt:before {
  content: "玲";
}

.ti-settings-cancel:before {
  content: "瑩";
}

.ti-settings-check:before {
  content: "羚";
}

.ti-settings-code:before {
  content: "聆";
}

.ti-settings-cog:before {
  content: "鈴";
}

.ti-settings-dollar:before {
  content: "零";
}

.ti-settings-down:before {
  content: "靈";
}

.ti-settings-exclamation:before {
  content: "領";
}

.ti-settings-filled:before {
  content: "";
}

.ti-settings-heart:before {
  content: "例";
}

.ti-settings-minus:before {
  content: "禮";
}

.ti-settings-off:before {
  content: "";
}

.ti-settings-pause:before {
  content: "醴";
}

.ti-settings-pin:before {
  content: "隸";
}

.ti-settings-plus:before {
  content: "惡";
}

.ti-settings-question:before {
  content: "了";
}

.ti-settings-search:before {
  content: "僚";
}

.ti-settings-share:before {
  content: "寮";
}

.ti-settings-star:before {
  content: "尿";
}

.ti-settings-up:before {
  content: "料";
}

.ti-settings-x:before {
  content: "樂";
}

.ti-shadow:before {
  content: "";
}

.ti-shadow-off:before {
  content: "";
}

.ti-shape:before {
  content: "";
}

.ti-shape-2:before {
  content: "";
}

.ti-shape-3:before {
  content: "";
}

.ti-shape-off:before {
  content: "";
}

.ti-share:before {
  content: "";
}

.ti-share-2:before {
  content: "";
}

.ti-share-3:before {
  content: "";
}

.ti-share-off:before {
  content: "";
}

.ti-shareplay:before {
  content: "ﺥ";
}

.ti-shield:before {
  content: "";
}

.ti-shield-bolt:before {
  content: "燎";
}

.ti-shield-cancel:before {
  content: "療";
}

.ti-shield-check:before {
  content: "";
}

.ti-shield-check-filled:before {
  content: "";
}

.ti-shield-checkered:before {
  content: "";
}

.ti-shield-checkered-filled:before {
  content: "";
}

.ti-shield-chevron:before {
  content: "";
}

.ti-shield-code:before {
  content: "蓼";
}

.ti-shield-cog:before {
  content: "遼";
}

.ti-shield-dollar:before {
  content: "龍";
}

.ti-shield-down:before {
  content: "暈";
}

.ti-shield-exclamation:before {
  content: "阮";
}

.ti-shield-filled:before {
  content: "";
}

.ti-shield-half:before {
  content: "";
}

.ti-shield-half-filled:before {
  content: "";
}

.ti-shield-heart:before {
  content: "劉";
}

.ti-shield-lock:before {
  content: "";
}

.ti-shield-lock-filled:before {
  content: "";
}

.ti-shield-minus:before {
  content: "杻";
}

.ti-shield-off:before {
  content: "";
}

.ti-shield-pause:before {
  content: "柳";
}

.ti-shield-pin:before {
  content: "流";
}

.ti-shield-plus:before {
  content: "溜";
}

.ti-shield-question:before {
  content: "琉";
}

.ti-shield-search:before {
  content: "留";
}

.ti-shield-share:before {
  content: "硫";
}

.ti-shield-star:before {
  content: "紐";
}

.ti-shield-up:before {
  content: "類";
}

.ti-shield-x:before {
  content: "";
}

.ti-ship:before {
  content: "";
}

.ti-ship-off:before {
  content: "";
}

.ti-shirt:before {
  content: "";
}

.ti-shirt-filled:before {
  content: "";
}

.ti-shirt-off:before {
  content: "";
}

.ti-shirt-sport:before {
  content: "";
}

.ti-shoe:before {
  content: "";
}

.ti-shoe-off:before {
  content: "";
}

.ti-shopping-bag:before {
  content: "";
}

.ti-shopping-bag-check:before {
  content: "ﰖ";
}

.ti-shopping-bag-discount:before {
  content: "ﰗ";
}

.ti-shopping-bag-edit:before {
  content: "ﰘ";
}

.ti-shopping-bag-exclamation:before {
  content: "ﰙ";
}

.ti-shopping-bag-heart:before {
  content: "ﶢ";
}

.ti-shopping-bag-minus:before {
  content: "ﰚ";
}

.ti-shopping-bag-plus:before {
  content: "ﰛ";
}

.ti-shopping-bag-search:before {
  content: "ﰜ";
}

.ti-shopping-bag-x:before {
  content: "ﰝ";
}

.ti-shopping-cart:before {
  content: "";
}

.ti-shopping-cart-bolt:before {
  content: "ﭗ";
}

.ti-shopping-cart-cancel:before {
  content: "ﭘ";
}

.ti-shopping-cart-check:before {
  content: "ﭙ";
}

.ti-shopping-cart-code:before {
  content: "ﭚ";
}

.ti-shopping-cart-cog:before {
  content: "ﭛ";
}

.ti-shopping-cart-copy:before {
  content: "ﭜ";
}

.ti-shopping-cart-discount:before {
  content: "ﭝ";
}

.ti-shopping-cart-dollar:before {
  content: "ﭞ";
}

.ti-shopping-cart-down:before {
  content: "ﭟ";
}

.ti-shopping-cart-exclamation:before {
  content: "ﭠ";
}

.ti-shopping-cart-filled:before {
  content: "ﰿ";
}

.ti-shopping-cart-heart:before {
  content: "ﭡ";
}

.ti-shopping-cart-minus:before {
  content: "ﭢ";
}

.ti-shopping-cart-off:before {
  content: "";
}

.ti-shopping-cart-pause:before {
  content: "ﭣ";
}

.ti-shopping-cart-pin:before {
  content: "ﭤ";
}

.ti-shopping-cart-plus:before {
  content: "ﭥ";
}

.ti-shopping-cart-question:before {
  content: "ﭦ";
}

.ti-shopping-cart-search:before {
  content: "ﭧ";
}

.ti-shopping-cart-share:before {
  content: "ﭨ";
}

.ti-shopping-cart-star:before {
  content: "ﭩ";
}

.ti-shopping-cart-up:before {
  content: "ﭪ";
}

.ti-shopping-cart-x:before {
  content: "ﭫ";
}

.ti-shovel:before {
  content: "";
}

.ti-shovel-pitchforks:before {
  content: "ﴺ";
}

.ti-shredder:before {
  content: "";
}

.ti-sign-left:before {
  content: "";
}

.ti-sign-left-filled:before {
  content: "";
}

.ti-sign-right:before {
  content: "";
}

.ti-sign-right-filled:before {
  content: "";
}

.ti-signal-2g:before {
  content: "";
}

.ti-signal-3g:before {
  content: "";
}

.ti-signal-4g:before {
  content: "";
}

.ti-signal-4g-plus:before {
  content: "";
}

.ti-signal-5g:before {
  content: "";
}

.ti-signal-6g:before {
  content: "笠";
}

.ti-signal-e:before {
  content: "粒";
}

.ti-signal-g:before {
  content: "狀";
}

.ti-signal-h:before {
  content: "識";
}

.ti-signal-h-plus:before {
  content: "炙";
}

.ti-signal-lte:before {
  content: "什";
}

.ti-signature:before {
  content: "";
}

.ti-signature-off:before {
  content: "";
}

.ti-sitemap:before {
  content: "";
}

.ti-sitemap-off:before {
  content: "";
}

.ti-skateboard:before {
  content: "";
}

.ti-skateboard-off:before {
  content: "";
}

.ti-skateboarding:before {
  content: "響";
}

.ti-skew-x:before {
  content: "ﴻ";
}

.ti-skew-y:before {
  content: "ﴼ";
}

.ti-ski-jumping:before {
  content: "𤋮";
}

.ti-skull:before {
  content: "";
}

.ti-slash:before {
  content: "";
}

.ti-slashes:before {
  content: "";
}

.ti-sleigh:before {
  content: "";
}

.ti-slice:before {
  content: "";
}

.ti-slideshow:before {
  content: "";
}

.ti-smart-home:before {
  content: "";
}

.ti-smart-home-off:before {
  content: "";
}

.ti-smoking:before {
  content: "";
}

.ti-smoking-no:before {
  content: "";
}

.ti-snowboarding:before {
  content: "﵏";
}

.ti-snowflake:before {
  content: "";
}

.ti-snowflake-off:before {
  content: "";
}

.ti-snowman:before {
  content: "";
}

.ti-soccer-field:before {
  content: "";
}

.ti-social:before {
  content: "";
}

.ti-social-off:before {
  content: "";
}

.ti-sock:before {
  content: "";
}

.ti-sofa:before {
  content: "";
}

.ti-sofa-off:before {
  content: "";
}

.ti-solar-electricity:before {
  content: "ﳁ";
}

.ti-solar-panel:before {
  content: "";
}

.ti-solar-panel-2:before {
  content: "";
}

.ti-sort-0-9:before {
  content: "";
}

.ti-sort-9-0:before {
  content: "";
}

.ti-sort-a-z:before {
  content: "";
}

.ti-sort-ascending:before {
  content: "";
}

.ti-sort-ascending-2:before {
  content: "";
}

.ti-sort-ascending-letters:before {
  content: "";
}

.ti-sort-ascending-numbers:before {
  content: "";
}

.ti-sort-ascending-shapes:before {
  content: "ﶔ";
}

.ti-sort-ascending-small-big:before {
  content: "ﶕ";
}

.ti-sort-descending:before {
  content: "";
}

.ti-sort-descending-2:before {
  content: "";
}

.ti-sort-descending-letters:before {
  content: "";
}

.ti-sort-descending-numbers:before {
  content: "";
}

.ti-sort-descending-shapes:before {
  content: "ﶗ";
}

.ti-sort-descending-small-big:before {
  content: "ﶖ";
}

.ti-sort-z-a:before {
  content: "";
}

.ti-sos:before {
  content: "";
}

.ti-soup:before {
  content: "";
}

.ti-soup-filled:before {
  content: "︈";
}

.ti-soup-off:before {
  content: "";
}

.ti-source-code:before {
  content: "";
}

.ti-space:before {
  content: "";
}

.ti-space-off:before {
  content: "";
}

.ti-spaces:before {
  content: "ﺤ";
}

.ti-spacing-horizontal:before {
  content: "";
}

.ti-spacing-vertical:before {
  content: "";
}

.ti-spade:before {
  content: "";
}

.ti-spade-filled:before {
  content: "";
}

.ti-sparkles:before {
  content: "";
}

.ti-speakerphone:before {
  content: "";
}

.ti-speedboat:before {
  content: "";
}

.ti-sphere:before {
  content: "視";
}

.ti-sphere-off:before {
  content: "襁";
}

.ti-sphere-plus:before {
  content: "覆";
}

.ti-spider:before {
  content: "";
}

.ti-spiral:before {
  content: "";
}

.ti-spiral-off:before {
  content: "";
}

.ti-sport-billard:before {
  content: "";
}

.ti-spray:before {
  content: "";
}

.ti-spy:before {
  content: "";
}

.ti-spy-off:before {
  content: "";
}

.ti-sql:before {
  content: "";
}

.ti-square:before {
  content: "";
}

.ti-square-arrow-down:before {
  content: "";
}

.ti-square-arrow-down-filled:before {
  content: "בּ";
}

.ti-square-arrow-left:before {
  content: "";
}

.ti-square-arrow-left-filled:before {
  content: "גּ";
}

.ti-square-arrow-right:before {
  content: "";
}

.ti-square-arrow-right-filled:before {
  content: "דּ";
}

.ti-square-arrow-up:before {
  content: "";
}

.ti-square-arrow-up-filled:before {
  content: "הּ";
}

.ti-square-asterisk:before {
  content: "";
}

.ti-square-asterisk-filled:before {
  content: "וּ";
}

.ti-square-check:before {
  content: "";
}

.ti-square-check-filled:before {
  content: "";
}

.ti-square-chevron-down:before {
  content: "";
}

.ti-square-chevron-down-filled:before {
  content: "זּ";
}

.ti-square-chevron-left:before {
  content: "";
}

.ti-square-chevron-left-filled:before {
  content: "﬷";
}

.ti-square-chevron-right:before {
  content: "";
}

.ti-square-chevron-right-filled:before {
  content: "טּ";
}

.ti-square-chevron-up:before {
  content: "";
}

.ti-square-chevron-up-filled:before {
  content: "יּ";
}

.ti-square-chevrons-down:before {
  content: "";
}

.ti-square-chevrons-down-filled:before {
  content: "ךּ";
}

.ti-square-chevrons-left:before {
  content: "";
}

.ti-square-chevrons-left-filled:before {
  content: "כּ";
}

.ti-square-chevrons-right:before {
  content: "";
}

.ti-square-chevrons-right-filled:before {
  content: "לּ";
}

.ti-square-chevrons-up:before {
  content: "";
}

.ti-square-chevrons-up-filled:before {
  content: "﬽";
}

.ti-square-dot:before {
  content: "";
}

.ti-square-dot-filled:before {
  content: "מּ";
}

.ti-square-f0:before {
  content: "";
}

.ti-square-f0-filled:before {
  content: "";
}

.ti-square-f1:before {
  content: "";
}

.ti-square-f1-filled:before {
  content: "";
}

.ti-square-f2:before {
  content: "";
}

.ti-square-f2-filled:before {
  content: "";
}

.ti-square-f3:before {
  content: "";
}

.ti-square-f3-filled:before {
  content: "";
}

.ti-square-f4:before {
  content: "";
}

.ti-square-f4-filled:before {
  content: "";
}

.ti-square-f5:before {
  content: "";
}

.ti-square-f5-filled:before {
  content: "";
}

.ti-square-f6:before {
  content: "";
}

.ti-square-f6-filled:before {
  content: "";
}

.ti-square-f7:before {
  content: "";
}

.ti-square-f7-filled:before {
  content: "";
}

.ti-square-f8:before {
  content: "";
}

.ti-square-f8-filled:before {
  content: "";
}

.ti-square-f9:before {
  content: "";
}

.ti-square-f9-filled:before {
  content: "";
}

.ti-square-filled:before {
  content: "ﱀ";
}

.ti-square-forbid:before {
  content: "";
}

.ti-square-forbid-2:before {
  content: "";
}

.ti-square-half:before {
  content: "";
}

.ti-square-key:before {
  content: "";
}

.ti-square-letter-a:before {
  content: "";
}

.ti-square-letter-a-filled:before {
  content: "︇";
}

.ti-square-letter-b:before {
  content: "";
}

.ti-square-letter-b-filled:before {
  content: "︆";
}

.ti-square-letter-c:before {
  content: "";
}

.ti-square-letter-c-filled:before {
  content: "︅";
}

.ti-square-letter-d:before {
  content: "";
}

.ti-square-letter-d-filled:before {
  content: "︄";
}

.ti-square-letter-e:before {
  content: "";
}

.ti-square-letter-e-filled:before {
  content: "︃";
}

.ti-square-letter-f:before {
  content: "";
}

.ti-square-letter-f-filled:before {
  content: "︂";
}

.ti-square-letter-g:before {
  content: "";
}

.ti-square-letter-g-filled:before {
  content: "︁";
}

.ti-square-letter-h:before {
  content: "";
}

.ti-square-letter-h-filled:before {
  content: "︀";
}

.ti-square-letter-i:before {
  content: "";
}

.ti-square-letter-i-filled:before {
  content: "﷿";
}

.ti-square-letter-j:before {
  content: "";
}

.ti-square-letter-j-filled:before {
  content: "﷾";
}

.ti-square-letter-k:before {
  content: "";
}

.ti-square-letter-k-filled:before {
  content: "﷽";
}

.ti-square-letter-l:before {
  content: "";
}

.ti-square-letter-l-filled:before {
  content: "﷼";
}

.ti-square-letter-m:before {
  content: "";
}

.ti-square-letter-m-filled:before {
  content: "ﷻ";
}

.ti-square-letter-n:before {
  content: "";
}

.ti-square-letter-n-filled:before {
  content: "ﷺ";
}

.ti-square-letter-o:before {
  content: "";
}

.ti-square-letter-o-filled:before {
  content: "ﷹ";
}

.ti-square-letter-p:before {
  content: "";
}

.ti-square-letter-p-filled:before {
  content: "ﷸ";
}

.ti-square-letter-q:before {
  content: "";
}

.ti-square-letter-q-filled:before {
  content: "ﷷ";
}

.ti-square-letter-r:before {
  content: "";
}

.ti-square-letter-r-filled:before {
  content: "ﷶ";
}

.ti-square-letter-s:before {
  content: "";
}

.ti-square-letter-s-filled:before {
  content: "ﷵ";
}

.ti-square-letter-t:before {
  content: "";
}

.ti-square-letter-t-filled:before {
  content: "ﷴ";
}

.ti-square-letter-u:before {
  content: "";
}

.ti-square-letter-u-filled:before {
  content: "ﷳ";
}

.ti-square-letter-v:before {
  content: "";
}

.ti-square-letter-v-filled:before {
  content: "ﷲ";
}

.ti-square-letter-w:before {
  content: "";
}

.ti-square-letter-w-filled:before {
  content: "ﷱ";
}

.ti-square-letter-x:before {
  content: "";
}

.ti-square-letter-x-filled:before {
  content: "ﷰ";
}

.ti-square-letter-y:before {
  content: "";
}

.ti-square-letter-y-filled:before {
  content: "﷯";
}

.ti-square-letter-z:before {
  content: "";
}

.ti-square-letter-z-filled:before {
  content: "﷮";
}

.ti-square-minus:before {
  content: "";
}

.ti-square-minus-filled:before {
  content: "﬿";
}

.ti-square-number-0:before {
  content: "";
}

.ti-square-number-0-filled:before {
  content: "";
}

.ti-square-number-1:before {
  content: "";
}

.ti-square-number-1-filled:before {
  content: "";
}

.ti-square-number-2:before {
  content: "";
}

.ti-square-number-2-filled:before {
  content: "";
}

.ti-square-number-3:before {
  content: "";
}

.ti-square-number-3-filled:before {
  content: "";
}

.ti-square-number-4:before {
  content: "";
}

.ti-square-number-4-filled:before {
  content: "";
}

.ti-square-number-5:before {
  content: "";
}

.ti-square-number-5-filled:before {
  content: "";
}

.ti-square-number-6:before {
  content: "";
}

.ti-square-number-6-filled:before {
  content: "";
}

.ti-square-number-7:before {
  content: "";
}

.ti-square-number-7-filled:before {
  content: "";
}

.ti-square-number-8:before {
  content: "";
}

.ti-square-number-8-filled:before {
  content: "";
}

.ti-square-number-9:before {
  content: "";
}

.ti-square-number-9-filled:before {
  content: "";
}

.ti-square-off:before {
  content: "";
}

.ti-square-percentage:before {
  content: "ﶃ";
}

.ti-square-plus:before {
  content: "";
}

.ti-square-plus-2:before {
  content: "ﲖ";
}

.ti-square-root:before {
  content: "";
}

.ti-square-root-2:before {
  content: "";
}

.ti-square-rotated:before {
  content: "";
}

.ti-square-rotated-filled:before {
  content: "";
}

.ti-square-rotated-forbid:before {
  content: "";
}

.ti-square-rotated-forbid-2:before {
  content: "";
}

.ti-square-rotated-off:before {
  content: "";
}

.ti-square-rounded:before {
  content: "";
}

.ti-square-rounded-arrow-down:before {
  content: "";
}

.ti-square-rounded-arrow-down-filled:before {
  content: "";
}

.ti-square-rounded-arrow-left:before {
  content: "";
}

.ti-square-rounded-arrow-left-filled:before {
  content: "";
}

.ti-square-rounded-arrow-right:before {
  content: "";
}

.ti-square-rounded-arrow-right-filled:before {
  content: "";
}

.ti-square-rounded-arrow-up:before {
  content: "";
}

.ti-square-rounded-arrow-up-filled:before {
  content: "";
}

.ti-square-rounded-check:before {
  content: "";
}

.ti-square-rounded-check-filled:before {
  content: "";
}

.ti-square-rounded-chevron-down:before {
  content: "";
}

.ti-square-rounded-chevron-down-filled:before {
  content: "";
}

.ti-square-rounded-chevron-left:before {
  content: "";
}

.ti-square-rounded-chevron-left-filled:before {
  content: "";
}

.ti-square-rounded-chevron-right:before {
  content: "";
}

.ti-square-rounded-chevron-right-filled:before {
  content: "";
}

.ti-square-rounded-chevron-up:before {
  content: "";
}

.ti-square-rounded-chevron-up-filled:before {
  content: "";
}

.ti-square-rounded-chevrons-down:before {
  content: "";
}

.ti-square-rounded-chevrons-down-filled:before {
  content: "";
}

.ti-square-rounded-chevrons-left:before {
  content: "";
}

.ti-square-rounded-chevrons-left-filled:before {
  content: "";
}

.ti-square-rounded-chevrons-right:before {
  content: "";
}

.ti-square-rounded-chevrons-right-filled:before {
  content: "";
}

.ti-square-rounded-chevrons-up:before {
  content: "";
}

.ti-square-rounded-chevrons-up-filled:before {
  content: "";
}

.ti-square-rounded-filled:before {
  content: "";
}

.ti-square-rounded-letter-a:before {
  content: "";
}

.ti-square-rounded-letter-a-filled:before {
  content: "﷭";
}

.ti-square-rounded-letter-b:before {
  content: "";
}

.ti-square-rounded-letter-b-filled:before {
  content: "﷬";
}

.ti-square-rounded-letter-c:before {
  content: "";
}

.ti-square-rounded-letter-c-filled:before {
  content: "﷫";
}

.ti-square-rounded-letter-d:before {
  content: "";
}

.ti-square-rounded-letter-d-filled:before {
  content: "﷪";
}

.ti-square-rounded-letter-e:before {
  content: "";
}

.ti-square-rounded-letter-e-filled:before {
  content: "﷩";
}

.ti-square-rounded-letter-f:before {
  content: "";
}

.ti-square-rounded-letter-f-filled:before {
  content: "﷨";
}

.ti-square-rounded-letter-g:before {
  content: "";
}

.ti-square-rounded-letter-g-filled:before {
  content: "﷧";
}

.ti-square-rounded-letter-h:before {
  content: "";
}

.ti-square-rounded-letter-h-filled:before {
  content: "﷦";
}

.ti-square-rounded-letter-i:before {
  content: "";
}

.ti-square-rounded-letter-i-filled:before {
  content: "﷥";
}

.ti-square-rounded-letter-j:before {
  content: "";
}

.ti-square-rounded-letter-j-filled:before {
  content: "﷤";
}

.ti-square-rounded-letter-k:before {
  content: "";
}

.ti-square-rounded-letter-k-filled:before {
  content: "﷣";
}

.ti-square-rounded-letter-l:before {
  content: "";
}

.ti-square-rounded-letter-l-filled:before {
  content: "﷢";
}

.ti-square-rounded-letter-m:before {
  content: "";
}

.ti-square-rounded-letter-m-filled:before {
  content: "﷡";
}

.ti-square-rounded-letter-n:before {
  content: "";
}

.ti-square-rounded-letter-n-filled:before {
  content: "﷠";
}

.ti-square-rounded-letter-o:before {
  content: "";
}

.ti-square-rounded-letter-o-filled:before {
  content: "﷟";
}

.ti-square-rounded-letter-p:before {
  content: "";
}

.ti-square-rounded-letter-p-filled:before {
  content: "﷞";
}

.ti-square-rounded-letter-q:before {
  content: "";
}

.ti-square-rounded-letter-q-filled:before {
  content: "﷝";
}

.ti-square-rounded-letter-r:before {
  content: "";
}

.ti-square-rounded-letter-r-filled:before {
  content: "﷜";
}

.ti-square-rounded-letter-s:before {
  content: "";
}

.ti-square-rounded-letter-s-filled:before {
  content: "﷛";
}

.ti-square-rounded-letter-t:before {
  content: "";
}

.ti-square-rounded-letter-t-filled:before {
  content: "﷚";
}

.ti-square-rounded-letter-u:before {
  content: "";
}

.ti-square-rounded-letter-u-filled:before {
  content: "﷙";
}

.ti-square-rounded-letter-v:before {
  content: "";
}

.ti-square-rounded-letter-v-filled:before {
  content: "﷘";
}

.ti-square-rounded-letter-w:before {
  content: "";
}

.ti-square-rounded-letter-w-filled:before {
  content: "﷗";
}

.ti-square-rounded-letter-x:before {
  content: "";
}

.ti-square-rounded-letter-x-filled:before {
  content: "﷖";
}

.ti-square-rounded-letter-y:before {
  content: "";
}

.ti-square-rounded-letter-y-filled:before {
  content: "﷕";
}

.ti-square-rounded-letter-z:before {
  content: "";
}

.ti-square-rounded-letter-z-filled:before {
  content: "﷔";
}

.ti-square-rounded-minus:before {
  content: "";
}

.ti-square-rounded-minus-2:before {
  content: "ﲗ";
}

.ti-square-rounded-minus-filled:before {
  content: "נּ";
}

.ti-square-rounded-number-0:before {
  content: "";
}

.ti-square-rounded-number-0-filled:before {
  content: "";
}

.ti-square-rounded-number-1:before {
  content: "";
}

.ti-square-rounded-number-1-filled:before {
  content: "";
}

.ti-square-rounded-number-2:before {
  content: "";
}

.ti-square-rounded-number-2-filled:before {
  content: "";
}

.ti-square-rounded-number-3:before {
  content: "";
}

.ti-square-rounded-number-3-filled:before {
  content: "";
}

.ti-square-rounded-number-4:before {
  content: "";
}

.ti-square-rounded-number-4-filled:before {
  content: "";
}

.ti-square-rounded-number-5:before {
  content: "";
}

.ti-square-rounded-number-5-filled:before {
  content: "";
}

.ti-square-rounded-number-6:before {
  content: "";
}

.ti-square-rounded-number-6-filled:before {
  content: "";
}

.ti-square-rounded-number-7:before {
  content: "";
}

.ti-square-rounded-number-7-filled:before {
  content: "";
}

.ti-square-rounded-number-8:before {
  content: "";
}

.ti-square-rounded-number-8-filled:before {
  content: "";
}

.ti-square-rounded-number-9:before {
  content: "";
}

.ti-square-rounded-number-9-filled:before {
  content: "";
}

.ti-square-rounded-percentage:before {
  content: "ﶄ";
}

.ti-square-rounded-plus:before {
  content: "";
}

.ti-square-rounded-plus-2:before {
  content: "ﲘ";
}

.ti-square-rounded-plus-filled:before {
  content: "";
}

.ti-square-rounded-x:before {
  content: "";
}

.ti-square-rounded-x-filled:before {
  content: "";
}

.ti-square-toggle:before {
  content: "";
}

.ti-square-toggle-horizontal:before {
  content: "";
}

.ti-square-x:before {
  content: "";
}

.ti-square-x-filled:before {
  content: "סּ";
}

.ti-squares:before {
  content: "";
}

.ti-squares-diagonal:before {
  content: "";
}

.ti-squares-filled:before {
  content: "ﺟ";
}

.ti-squares-selected:before {
  content: "ﺣ";
}

.ti-stack:before {
  content: "";
}

.ti-stack-2:before {
  content: "";
}

.ti-stack-2-filled:before {
  content: "﷓";
}

.ti-stack-3:before {
  content: "";
}

.ti-stack-3-filled:before {
  content: "﷒";
}

.ti-stack-back:before {
  content: "ﴦ";
}

.ti-stack-backward:before {
  content: "ﴧ";
}

.ti-stack-filled:before {
  content: "﷑";
}

.ti-stack-forward:before {
  content: "ﴨ";
}

.ti-stack-front:before {
  content: "ﴩ";
}

.ti-stack-middle:before {
  content: "ﴪ";
}

.ti-stack-pop:before {
  content: "";
}

.ti-stack-push:before {
  content: "";
}

.ti-stairs:before {
  content: "";
}

.ti-stairs-down:before {
  content: "";
}

.ti-stairs-up:before {
  content: "";
}

.ti-star:before {
  content: "";
}

.ti-star-filled:before {
  content: "";
}

.ti-star-half:before {
  content: "";
}

.ti-star-half-filled:before {
  content: "";
}

.ti-star-off:before {
  content: "";
}

.ti-stars:before {
  content: "";
}

.ti-stars-filled:before {
  content: "";
}

.ti-stars-off:before {
  content: "";
}

.ti-status-change:before {
  content: "";
}

.ti-steam:before {
  content: "";
}

.ti-steering-wheel:before {
  content: "";
}

.ti-steering-wheel-off:before {
  content: "";
}

.ti-step-into:before {
  content: "";
}

.ti-step-out:before {
  content: "";
}

.ti-stereo-glasses:before {
  content: "";
}

.ti-stethoscope:before {
  content: "";
}

.ti-stethoscope-off:before {
  content: "";
}

.ti-sticker:before {
  content: "";
}

.ti-sticker-2:before {
  content: "ﴽ";
}

.ti-storm:before {
  content: "";
}

.ti-storm-off:before {
  content: "";
}

.ti-stretching:before {
  content: "";
}

.ti-stretching-2:before {
  content: "舘";
}

.ti-strikethrough:before {
  content: "";
}

.ti-submarine:before {
  content: "";
}

.ti-subscript:before {
  content: "";
}

.ti-subtask:before {
  content: "";
}

.ti-sum:before {
  content: "";
}

.ti-sum-off:before {
  content: "";
}

.ti-sun:before {
  content: "";
}

.ti-sun-electricity:before {
  content: "ﳂ";
}

.ti-sun-filled:before {
  content: "";
}

.ti-sun-high:before {
  content: "";
}

.ti-sun-low:before {
  content: "";
}

.ti-sun-moon:before {
  content: "";
}

.ti-sun-off:before {
  content: "";
}

.ti-sun-wind:before {
  content: "";
}

.ti-sunglasses:before {
  content: "";
}

.ti-sunrise:before {
  content: "";
}

.ti-sunset:before {
  content: "";
}

.ti-sunset-2:before {
  content: "";
}

.ti-superscript:before {
  content: "";
}

.ti-svg:before {
  content: "";
}

.ti-swimming:before {
  content: "";
}

.ti-swipe:before {
  content: "";
}

.ti-swipe-down:before {
  content: "ﵞ";
}

.ti-swipe-left:before {
  content: "ﵟ";
}

.ti-swipe-right:before {
  content: "ﵠ";
}

.ti-swipe-up:before {
  content: "ﵡ";
}

.ti-switch:before {
  content: "";
}

.ti-switch-2:before {
  content: "";
}

.ti-switch-3:before {
  content: "";
}

.ti-switch-horizontal:before {
  content: "";
}

.ti-switch-vertical:before {
  content: "";
}

.ti-sword:before {
  content: "";
}

.ti-sword-off:before {
  content: "";
}

.ti-swords:before {
  content: "";
}

.ti-table:before {
  content: "";
}

.ti-table-alias:before {
  content: "";
}

.ti-table-column:before {
  content: "﫿";
}

.ti-table-down:before {
  content: "靖";
}

.ti-table-export:before {
  content: "";
}

.ti-table-filled:before {
  content: "";
}

.ti-table-heart:before {
  content: "精";
}

.ti-table-import:before {
  content: "";
}

.ti-table-minus:before {
  content: "羽";
}

.ti-table-off:before {
  content: "";
}

.ti-table-options:before {
  content: "";
}

.ti-table-plus:before {
  content: "﨟";
}

.ti-table-row:before {
  content: "ﬀ";
}

.ti-table-share:before {
  content: "蘒";
}

.ti-table-shortcut:before {
  content: "";
}

.ti-tag:before {
  content: "";
}

.ti-tag-off:before {
  content: "";
}

.ti-tag-starred:before {
  content: "ﲙ";
}

.ti-tags:before {
  content: "";
}

.ti-tags-off:before {
  content: "";
}

.ti-tallymark-1:before {
  content: "";
}

.ti-tallymark-2:before {
  content: "";
}

.ti-tallymark-3:before {
  content: "";
}

.ti-tallymark-4:before {
  content: "";
}

.ti-tallymarks:before {
  content: "";
}

.ti-tank:before {
  content: "";
}

.ti-target:before {
  content: "";
}

.ti-target-arrow:before {
  content: "";
}

.ti-target-off:before {
  content: "";
}

.ti-teapot:before {
  content: "";
}

.ti-telescope:before {
  content: "";
}

.ti-telescope-off:before {
  content: "";
}

.ti-temperature:before {
  content: "";
}

.ti-temperature-celsius:before {
  content: "";
}

.ti-temperature-fahrenheit:before {
  content: "";
}

.ti-temperature-minus:before {
  content: "";
}

.ti-temperature-off:before {
  content: "";
}

.ti-temperature-plus:before {
  content: "";
}

.ti-temperature-snow:before {
  content: "ﶣ";
}

.ti-temperature-sun:before {
  content: "ﶤ";
}

.ti-template:before {
  content: "";
}

.ti-template-off:before {
  content: "";
}

.ti-tent:before {
  content: "";
}

.ti-tent-off:before {
  content: "";
}

.ti-terminal:before {
  content: "";
}

.ti-terminal-2:before {
  content: "";
}

.ti-test-pipe:before {
  content: "";
}

.ti-test-pipe-2:before {
  content: "";
}

.ti-test-pipe-off:before {
  content: "";
}

.ti-tex:before {
  content: "";
}

.ti-text-caption:before {
  content: "";
}

.ti-text-color:before {
  content: "";
}

.ti-text-decrease:before {
  content: "";
}

.ti-text-direction-ltr:before {
  content: "";
}

.ti-text-direction-rtl:before {
  content: "";
}

.ti-text-grammar:before {
  content: "ﵭ";
}

.ti-text-increase:before {
  content: "";
}

.ti-text-orientation:before {
  content: "";
}

.ti-text-plus:before {
  content: "";
}

.ti-text-recognition:before {
  content: "";
}

.ti-text-resize:before {
  content: "";
}

.ti-text-scan-2:before {
  content: "ﳃ";
}

.ti-text-size:before {
  content: "";
}

.ti-text-spellcheck:before {
  content: "";
}

.ti-text-wrap:before {
  content: "";
}

.ti-text-wrap-column:before {
  content: "ﺲ";
}

.ti-text-wrap-disabled:before {
  content: "";
}

.ti-texture:before {
  content: "";
}

.ti-theater:before {
  content: "";
}

.ti-thermometer:before {
  content: "";
}

.ti-thumb-down:before {
  content: "";
}

.ti-thumb-down-filled:before {
  content: "";
}

.ti-thumb-down-off:before {
  content: "";
}

.ti-thumb-up:before {
  content: "";
}

.ti-thumb-up-filled:before {
  content: "";
}

.ti-thumb-up-off:before {
  content: "";
}

.ti-tic-tac:before {
  content: "";
}

.ti-ticket:before {
  content: "";
}

.ti-ticket-off:before {
  content: "";
}

.ti-tie:before {
  content: "";
}

.ti-tilde:before {
  content: "";
}

.ti-tilt-shift:before {
  content: "";
}

.ti-tilt-shift-off:before {
  content: "";
}

.ti-time-duration-0:before {
  content: "䀹";
}

.ti-time-duration-10:before {
  content: "𥉉";
}

.ti-time-duration-15:before {
  content: "𥳐";
}

.ti-time-duration-30:before {
  content: "𧻓";
}

.ti-time-duration-45:before {
  content: "齃";
}

.ti-time-duration-5:before {
  content: "龎";
}

.ti-time-duration-60:before {
  content: "﫚";
}

.ti-time-duration-90:before {
  content: "﫛";
}

.ti-time-duration-off:before {
  content: "﫜";
}

.ti-timeline:before {
  content: "";
}

.ti-timeline-event:before {
  content: "";
}

.ti-timeline-event-exclamation:before {
  content: "";
}

.ti-timeline-event-filled:before {
  content: "ﴘ";
}

.ti-timeline-event-minus:before {
  content: "";
}

.ti-timeline-event-plus:before {
  content: "";
}

.ti-timeline-event-text:before {
  content: "";
}

.ti-timeline-event-x:before {
  content: "";
}

.ti-tir:before {
  content: "";
}

.ti-toggle-left:before {
  content: "";
}

.ti-toggle-left-filled:before {
  content: "ﻀ";
}

.ti-toggle-right:before {
  content: "";
}

.ti-toggle-right-filled:before {
  content: "ﺿ";
}

.ti-toilet-paper:before {
  content: "";
}

.ti-toilet-paper-off:before {
  content: "";
}

.ti-toml:before {
  content: "艹";
}

.ti-tool:before {
  content: "";
}

.ti-tools:before {
  content: "";
}

.ti-tools-kitchen:before {
  content: "";
}

.ti-tools-kitchen-2:before {
  content: "";
}

.ti-tools-kitchen-2-off:before {
  content: "";
}

.ti-tools-kitchen-3:before {
  content: "ﴫ";
}

.ti-tools-kitchen-off:before {
  content: "";
}

.ti-tools-off:before {
  content: "";
}

.ti-tooltip:before {
  content: "";
}

.ti-topology-bus:before {
  content: "";
}

.ti-topology-complex:before {
  content: "";
}

.ti-topology-full:before {
  content: "";
}

.ti-topology-full-hierarchy:before {
  content: "";
}

.ti-topology-ring:before {
  content: "";
}

.ti-topology-ring-2:before {
  content: "";
}

.ti-topology-ring-3:before {
  content: "";
}

.ti-topology-star:before {
  content: "";
}

.ti-topology-star-2:before {
  content: "";
}

.ti-topology-star-3:before {
  content: "";
}

.ti-topology-star-ring:before {
  content: "";
}

.ti-topology-star-ring-2:before {
  content: "";
}

.ti-topology-star-ring-3:before {
  content: "";
}

.ti-torii:before {
  content: "";
}

.ti-tornado:before {
  content: "";
}

.ti-tournament:before {
  content: "";
}

.ti-tower:before {
  content: "";
}

.ti-tower-off:before {
  content: "";
}

.ti-track:before {
  content: "";
}

.ti-tractor:before {
  content: "";
}

.ti-trademark:before {
  content: "";
}

.ti-traffic-cone:before {
  content: "";
}

.ti-traffic-cone-off:before {
  content: "";
}

.ti-traffic-lights:before {
  content: "";
}

.ti-traffic-lights-off:before {
  content: "";
}

.ti-train:before {
  content: "";
}

.ti-transaction-bitcoin:before {
  content: "ﵮ";
}

.ti-transaction-dollar:before {
  content: "ﵯ";
}

.ti-transaction-euro:before {
  content: "ﵰ";
}

.ti-transaction-pound:before {
  content: "ﵱ";
}

.ti-transaction-rupee:before {
  content: "ﶅ";
}

.ti-transaction-yen:before {
  content: "ﵲ";
}

.ti-transaction-yuan:before {
  content: "ﵳ";
}

.ti-transfer:before {
  content: "ﰟ";
}

.ti-transfer-in:before {
  content: "";
}

.ti-transfer-out:before {
  content: "";
}

.ti-transfer-vertical:before {
  content: "ﰞ";
}

.ti-transform:before {
  content: "";
}

.ti-transform-filled:before {
  content: "";
}

.ti-transform-point:before {
  content: "ﶩ";
}

.ti-transform-point-bottom-left:before {
  content: "ﶥ";
}

.ti-transform-point-bottom-right:before {
  content: "ﶦ";
}

.ti-transform-point-top-left:before {
  content: "ﶧ";
}

.ti-transform-point-top-right:before {
  content: "ﶨ";
}

.ti-transition-bottom:before {
  content: "";
}

.ti-transition-bottom-filled:before {
  content: "﷐";
}

.ti-transition-left:before {
  content: "";
}

.ti-transition-left-filled:before {
  content: "﷏";
}

.ti-transition-right:before {
  content: "";
}

.ti-transition-right-filled:before {
  content: "﷎";
}

.ti-transition-top:before {
  content: "";
}

.ti-transition-top-filled:before {
  content: "﷍";
}

.ti-trash:before {
  content: "";
}

.ti-trash-filled:before {
  content: "";
}

.ti-trash-off:before {
  content: "";
}

.ti-trash-x:before {
  content: "";
}

.ti-trash-x-filled:before {
  content: "";
}

.ti-treadmill:before {
  content: "﩮";
}

.ti-tree:before {
  content: "";
}

.ti-trees:before {
  content: "";
}

.ti-trekking:before {
  content: "";
}

.ti-trending-down:before {
  content: "";
}

.ti-trending-down-2:before {
  content: "";
}

.ti-trending-down-3:before {
  content: "";
}

.ti-trending-up:before {
  content: "";
}

.ti-trending-up-2:before {
  content: "";
}

.ti-trending-up-3:before {
  content: "";
}

.ti-triangle:before {
  content: "";
}

.ti-triangle-filled:before {
  content: "";
}

.ti-triangle-inverted:before {
  content: "";
}

.ti-triangle-inverted-filled:before {
  content: "";
}

.ti-triangle-minus:before {
  content: "ﲛ";
}

.ti-triangle-minus-2:before {
  content: "ﲚ";
}

.ti-triangle-off:before {
  content: "";
}

.ti-triangle-plus:before {
  content: "ﲝ";
}

.ti-triangle-plus-2:before {
  content: "ﲜ";
}

.ti-triangle-square-circle:before {
  content: "";
}

.ti-triangle-square-circle-filled:before {
  content: "﭂";
}

.ti-triangles:before {
  content: "";
}

.ti-trident:before {
  content: "";
}

.ti-trolley:before {
  content: "";
}

.ti-trophy:before {
  content: "";
}

.ti-trophy-filled:before {
  content: "";
}

.ti-trophy-off:before {
  content: "";
}

.ti-trowel:before {
  content: "";
}

.ti-truck:before {
  content: "";
}

.ti-truck-delivery:before {
  content: "";
}

.ti-truck-loading:before {
  content: "";
}

.ti-truck-off:before {
  content: "";
}

.ti-truck-return:before {
  content: "";
}

.ti-txt:before {
  content: "";
}

.ti-typeface:before {
  content: "ﶫ";
}

.ti-typography:before {
  content: "";
}

.ti-typography-off:before {
  content: "";
}

.ti-u-turn-left:before {
  content: "ﺢ";
}

.ti-u-turn-right:before {
  content: "ﺡ";
}

.ti-ufo:before {
  content: "";
}

.ti-ufo-off:before {
  content: "";
}

.ti-umbrella:before {
  content: "";
}

.ti-umbrella-filled:before {
  content: "";
}

.ti-umbrella-off:before {
  content: "";
}

.ti-underline:before {
  content: "";
}

.ti-universe:before {
  content: "ﳄ";
}

.ti-unlink:before {
  content: "";
}

.ti-upload:before {
  content: "";
}

.ti-urgent:before {
  content: "";
}

.ti-usb:before {
  content: "";
}

.ti-user:before {
  content: "";
}

.ti-user-bolt:before {
  content: "六";
}

.ti-user-cancel:before {
  content: "戮";
}

.ti-user-check:before {
  content: "";
}

.ti-user-circle:before {
  content: "";
}

.ti-user-code:before {
  content: "陸";
}

.ti-user-cog:before {
  content: "倫";
}

.ti-user-dollar:before {
  content: "崙";
}

.ti-user-down:before {
  content: "淪";
}

.ti-user-edit:before {
  content: "";
}

.ti-user-exclamation:before {
  content: "";
}

.ti-user-filled:before {
  content: "ﴙ";
}

.ti-user-heart:before {
  content: "";
}

.ti-user-hexagon:before {
  content: "ﱎ";
}

.ti-user-minus:before {
  content: "";
}

.ti-user-off:before {
  content: "";
}

.ti-user-pause:before {
  content: "輪";
}

.ti-user-pentagon:before {
  content: "ﱏ";
}

.ti-user-pin:before {
  content: "";
}

.ti-user-plus:before {
  content: "";
}

.ti-user-question:before {
  content: "";
}

.ti-user-scan:before {
  content: "ﲯ";
}

.ti-user-screen:before {
  content: "ﺠ";
}

.ti-user-search:before {
  content: "";
}

.ti-user-share:before {
  content: "律";
}

.ti-user-shield:before {
  content: "";
}

.ti-user-square:before {
  content: "ﱑ";
}

.ti-user-square-rounded:before {
  content: "ﱐ";
}

.ti-user-star:before {
  content: "";
}

.ti-user-up:before {
  content: "";
}

.ti-user-x:before {
  content: "";
}

.ti-users:before {
  content: "";
}

.ti-users-group:before {
  content: "﨡";
}

.ti-users-minus:before {
  content: "﨎";
}

.ti-users-plus:before {
  content: "﨏";
}

.ti-uv-index:before {
  content: "";
}

.ti-ux-circle:before {
  content: "";
}

.ti-vaccine:before {
  content: "";
}

.ti-vaccine-bottle:before {
  content: "";
}

.ti-vaccine-bottle-off:before {
  content: "";
}

.ti-vaccine-off:before {
  content: "";
}

.ti-vacuum-cleaner:before {
  content: "";
}

.ti-variable:before {
  content: "";
}

.ti-variable-minus:before {
  content: "";
}

.ti-variable-off:before {
  content: "";
}

.ti-variable-plus:before {
  content: "";
}

.ti-vector:before {
  content: "";
}

.ti-vector-bezier:before {
  content: "";
}

.ti-vector-bezier-2:before {
  content: "";
}

.ti-vector-bezier-arc:before {
  content: "";
}

.ti-vector-bezier-circle:before {
  content: "";
}

.ti-vector-off:before {
  content: "";
}

.ti-vector-spline:before {
  content: "";
}

.ti-vector-triangle:before {
  content: "";
}

.ti-vector-triangle-off:before {
  content: "";
}

.ti-venus:before {
  content: "";
}

.ti-versions:before {
  content: "";
}

.ti-versions-filled:before {
  content: "";
}

.ti-versions-off:before {
  content: "";
}

.ti-video:before {
  content: "";
}

.ti-video-minus:before {
  content: "";
}

.ti-video-off:before {
  content: "";
}

.ti-video-plus:before {
  content: "";
}

.ti-view-360:before {
  content: "";
}

.ti-view-360-arrow:before {
  content: "";
}

.ti-view-360-number:before {
  content: "";
}

.ti-view-360-off:before {
  content: "";
}

.ti-viewfinder:before {
  content: "";
}

.ti-viewfinder-off:before {
  content: "";
}

.ti-viewport-narrow:before {
  content: "";
}

.ti-viewport-wide:before {
  content: "";
}

.ti-vinyl:before {
  content: "";
}

.ti-vip:before {
  content: "";
}

.ti-vip-off:before {
  content: "";
}

.ti-virus:before {
  content: "";
}

.ti-virus-off:before {
  content: "";
}

.ti-virus-search:before {
  content: "";
}

.ti-vocabulary:before {
  content: "";
}

.ti-vocabulary-off:before {
  content: "";
}

.ti-volcano:before {
  content: "";
}

.ti-volume:before {
  content: "";
}

.ti-volume-2:before {
  content: "";
}

.ti-volume-3:before {
  content: "";
}

.ti-volume-off:before {
  content: "";
}

.ti-vs:before {
  content: "ﱒ";
}

.ti-walk:before {
  content: "";
}

.ti-wall:before {
  content: "";
}

.ti-wall-off:before {
  content: "";
}

.ti-wallet:before {
  content: "";
}

.ti-wallet-off:before {
  content: "";
}

.ti-wallpaper:before {
  content: "";
}

.ti-wallpaper-off:before {
  content: "";
}

.ti-wand:before {
  content: "";
}

.ti-wand-off:before {
  content: "";
}

.ti-wash:before {
  content: "";
}

.ti-wash-dry:before {
  content: "";
}

.ti-wash-dry-1:before {
  content: "";
}

.ti-wash-dry-2:before {
  content: "";
}

.ti-wash-dry-3:before {
  content: "";
}

.ti-wash-dry-a:before {
  content: "";
}

.ti-wash-dry-dip:before {
  content: "";
}

.ti-wash-dry-f:before {
  content: "";
}

.ti-wash-dry-flat:before {
  content: "奔";
}

.ti-wash-dry-hang:before {
  content: "";
}

.ti-wash-dry-off:before {
  content: "";
}

.ti-wash-dry-p:before {
  content: "";
}

.ti-wash-dry-shade:before {
  content: "";
}

.ti-wash-dry-w:before {
  content: "";
}

.ti-wash-dryclean:before {
  content: "";
}

.ti-wash-dryclean-off:before {
  content: "";
}

.ti-wash-eco:before {
  content: "婢";
}

.ti-wash-gentle:before {
  content: "";
}

.ti-wash-hand:before {
  content: "嬨";
}

.ti-wash-machine:before {
  content: "";
}

.ti-wash-off:before {
  content: "";
}

.ti-wash-press:before {
  content: "";
}

.ti-wash-temperature-1:before {
  content: "";
}

.ti-wash-temperature-2:before {
  content: "";
}

.ti-wash-temperature-3:before {
  content: "";
}

.ti-wash-temperature-4:before {
  content: "";
}

.ti-wash-temperature-5:before {
  content: "";
}

.ti-wash-temperature-6:before {
  content: "";
}

.ti-wash-tumble-dry:before {
  content: "";
}

.ti-wash-tumble-off:before {
  content: "";
}

.ti-waterpolo:before {
  content: "﩯";
}

.ti-wave-saw-tool:before {
  content: "";
}

.ti-wave-sine:before {
  content: "";
}

.ti-wave-square:before {
  content: "";
}

.ti-waves-electricity:before {
  content: "ﳅ";
}

.ti-webhook:before {
  content: "";
}

.ti-webhook-off:before {
  content: "";
}

.ti-weight:before {
  content: "";
}

.ti-wheel:before {
  content: "ﱤ";
}

.ti-wheelchair:before {
  content: "";
}

.ti-wheelchair-off:before {
  content: "";
}

.ti-whirl:before {
  content: "";
}

.ti-wifi:before {
  content: "";
}

.ti-wifi-0:before {
  content: "";
}

.ti-wifi-1:before {
  content: "";
}

.ti-wifi-2:before {
  content: "";
}

.ti-wifi-off:before {
  content: "";
}

.ti-wind:before {
  content: "";
}

.ti-wind-electricity:before {
  content: "ﳆ";
}

.ti-wind-off:before {
  content: "";
}

.ti-windmill:before {
  content: "";
}

.ti-windmill-filled:before {
  content: "";
}

.ti-windmill-off:before {
  content: "";
}

.ti-window:before {
  content: "";
}

.ti-window-maximize:before {
  content: "";
}

.ti-window-minimize:before {
  content: "";
}

.ti-window-off:before {
  content: "";
}

.ti-windsock:before {
  content: "";
}

.ti-wiper:before {
  content: "";
}

.ti-wiper-wash:before {
  content: "";
}

.ti-woman:before {
  content: "";
}

.ti-woman-filled:before {
  content: "﷌";
}

.ti-wood:before {
  content: "";
}

.ti-world:before {
  content: "";
}

.ti-world-bolt:before {
  content: "慄";
}

.ti-world-cancel:before {
  content: "栗";
}

.ti-world-check:before {
  content: "率";
}

.ti-world-code:before {
  content: "隆";
}

.ti-world-cog:before {
  content: "利";
}

.ti-world-dollar:before {
  content: "吏";
}

.ti-world-down:before {
  content: "履";
}

.ti-world-download:before {
  content: "";
}

.ti-world-exclamation:before {
  content: "易";
}

.ti-world-heart:before {
  content: "李";
}

.ti-world-latitude:before {
  content: "";
}

.ti-world-longitude:before {
  content: "";
}

.ti-world-minus:before {
  content: "梨";
}

.ti-world-off:before {
  content: "";
}

.ti-world-pause:before {
  content: "泥";
}

.ti-world-pin:before {
  content: "理";
}

.ti-world-plus:before {
  content: "痢";
}

.ti-world-question:before {
  content: "罹";
}

.ti-world-search:before {
  content: "裏";
}

.ti-world-share:before {
  content: "裡";
}

.ti-world-star:before {
  content: "里";
}

.ti-world-up:before {
  content: "離";
}

.ti-world-upload:before {
  content: "";
}

.ti-world-www:before {
  content: "";
}

.ti-world-x:before {
  content: "匿";
}

.ti-wrecking-ball:before {
  content: "";
}

.ti-writing:before {
  content: "";
}

.ti-writing-off:before {
  content: "";
}

.ti-writing-sign:before {
  content: "";
}

.ti-writing-sign-off:before {
  content: "";
}

.ti-x:before {
  content: "";
}

.ti-xbox-a:before {
  content: "";
}

.ti-xbox-a-filled:before {
  content: "﷋";
}

.ti-xbox-b:before {
  content: "";
}

.ti-xbox-b-filled:before {
  content: "﷊";
}

.ti-xbox-x:before {
  content: "";
}

.ti-xbox-x-filled:before {
  content: "﷉";
}

.ti-xbox-y:before {
  content: "";
}

.ti-xbox-y-filled:before {
  content: "﷈";
}

.ti-xd:before {
  content: "勉";
}

.ti-xxx:before {
  content: "ﰠ";
}

.ti-yin-yang:before {
  content: "";
}

.ti-yin-yang-filled:before {
  content: "";
}

.ti-yoga:before {
  content: "";
}

.ti-zeppelin:before {
  content: "";
}

.ti-zeppelin-filled:before {
  content: "ﷇ";
}

.ti-zeppelin-off:before {
  content: "";
}

.ti-zip:before {
  content: "";
}

.ti-zodiac-aquarius:before {
  content: "";
}

.ti-zodiac-aries:before {
  content: "";
}

.ti-zodiac-cancer:before {
  content: "";
}

.ti-zodiac-capricorn:before {
  content: "";
}

.ti-zodiac-gemini:before {
  content: "";
}

.ti-zodiac-leo:before {
  content: "";
}

.ti-zodiac-libra:before {
  content: "";
}

.ti-zodiac-pisces:before {
  content: "";
}

.ti-zodiac-sagittarius:before {
  content: "";
}

.ti-zodiac-scorpio:before {
  content: "";
}

.ti-zodiac-taurus:before {
  content: "";
}

.ti-zodiac-virgo:before {
  content: "";
}

.ti-zoom:before {
  content: "ﶪ";
}

.ti-zoom-cancel:before {
  content: "";
}

.ti-zoom-cancel-filled:before {
  content: "ﷆ";
}

.ti-zoom-check:before {
  content: "";
}

.ti-zoom-check-filled:before {
  content: "";
}

.ti-zoom-code:before {
  content: "";
}

.ti-zoom-code-filled:before {
  content: "ﷅ";
}

.ti-zoom-exclamation:before {
  content: "";
}

.ti-zoom-exclamation-filled:before {
  content: "ﷄ";
}

.ti-zoom-filled:before {
  content: "";
}

.ti-zoom-in:before {
  content: "";
}

.ti-zoom-in-area:before {
  content: "";
}

.ti-zoom-in-area-filled:before {
  content: "";
}

.ti-zoom-in-filled:before {
  content: "";
}

.ti-zoom-money:before {
  content: "";
}

.ti-zoom-money-filled:before {
  content: "ﷃ";
}

.ti-zoom-out:before {
  content: "";
}

.ti-zoom-out-area:before {
  content: "";
}

.ti-zoom-out-area-filled:before {
  content: "ﷂ";
}

.ti-zoom-out-filled:before {
  content: "";
}

.ti-zoom-pan:before {
  content: "";
}

.ti-zoom-pan-filled:before {
  content: "ﷁ";
}

.ti-zoom-question:before {
  content: "";
}

.ti-zoom-question-filled:before {
  content: "ﷀ";
}

.ti-zoom-replace:before {
  content: "";
}

.ti-zoom-reset:before {
  content: "";
}

.ti-zoom-scan:before {
  content: "ﲰ";
}

.ti-zoom-scan-filled:before {
  content: "ﶿ";
}

.ti-zzz:before {
  content: "";
}

.ti-zzz-off:before {
  content: "";
}

.ti-123:before {
  content: "";
}

.ti-360:before {
  content: "";
}

.ti-code-asterix:before {
  content: "";
}

.ti-discount-2:before {
  content: "";
}

.ti-discount-2-off:before {
  content: "";
}

.ti-discount-check:before {
  content: "";
}

.ti-hand-rock:before {
  content: "";
}

.ti-sort-deacending-small-big:before {
  content: "ﶖ";
}

.ti-shi-jumping:before {
  content: "𤋮";
}

.ti-box-seam:before {
  content: "";
}

.ti-kering:before {
  content: "";
}

.ti-2fa:before {
  content: "";
}

.ti-3d-cube-sphere:before {
  content: "";
}

.ti-3d-cube-sphere-off:before {
  content: "";
}

.ti-3d-rotate:before {
  content: "";
}

.ti-12-hours:before {
  content: "ﱓ";
}

.ti-24-hours:before {
  content: "";
}

.ti-360-view:before {
  content: "";
}

.ti-circle-0:before {
  content: "";
}

.ti-circle-1:before {
  content: "";
}

.ti-circle-2:before {
  content: "";
}

.ti-circle-3:before {
  content: "";
}

.ti-circle-4:before {
  content: "";
}

.ti-circle-5:before {
  content: "";
}

.ti-circle-6:before {
  content: "";
}

.ti-circle-7:before {
  content: "";
}

.ti-circle-8:before {
  content: "";
}

.ti-circle-9:before {
  content: "";
}

.ti-hexagon-0:before {
  content: "";
}

.ti-hexagon-1:before {
  content: "";
}

.ti-hexagon-2:before {
  content: "";
}

.ti-hexagon-3:before {
  content: "";
}

.ti-hexagon-4:before {
  content: "";
}

.ti-hexagon-5:before {
  content: "";
}

.ti-hexagon-6:before {
  content: "";
}

.ti-hexagon-7:before {
  content: "";
}

.ti-hexagon-8:before {
  content: "";
}

.ti-hexagon-9:before {
  content: "";
}

.ti-square-0:before {
  content: "";
}

.ti-square-1:before {
  content: "";
}

.ti-square-2:before {
  content: "";
}

.ti-square-3:before {
  content: "";
}

.ti-square-4:before {
  content: "";
}

.ti-square-5:before {
  content: "";
}

.ti-square-6:before {
  content: "";
}

.ti-square-7:before {
  content: "";
}

.ti-square-8:before {
  content: "";
}

.ti-square-9:before {
  content: "";
}

.ti-discount-check-filled:before {
  content: "";
}

.ribbon {
  width: 120px;
  height: 120px;
  top: 0;
  right: 0;
  overflow: hidden;
}

.ribbon > span {
  text-align: center;
  width: 225px;
  padding: 0;
  display: block;
  position: absolute;
  top: 40px;
  left: -40px;
  transform: rotate(45deg);
}

.compact .ribbon {
  width: 105px;
  height: 105px;
}

.compact .ribbon > span {
  top: 25px;
  left: -45px;
  transform: rotate(45deg);
}

* {
  --bs-variable-font-default: "wdth" 135, "wght" 400;
  letter-spacing: .0125rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-variation-settings: "wdth" 125, "wght" 400;
}

.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
  font-variation-settings: "wdth" 150, "wght" 500;
}

.lh-none {
  line-height: 0 !important;
}

body {
  background-image: repeating-linear-gradient(315deg, #00ffff2e 92%, #073aff00 100%), repeating-radial-gradient(75% 75% at 238% 218%, #00ffff12 30%, #073aff14 39%), radial-gradient(99% 99% at 109% 2%, #00c9ff 0%, #073aff00 100%), radial-gradient(99% 99% at 21% 78%, #441083 0%, #073aff00 100%), radial-gradient(160% 154% at 711px -303px, #ffd600 0%, #ff6d00 100%);
  background-position: 0 0, 0 0, 0 0, 0 0;
  background-size: 100% 100%;
  min-height: 105vh;
  overflow-x: hidden;
}

.gradient-header {
  color: #0000;
  text-transform: uppercase;
  background-image: repeating-linear-gradient(315deg, #00ffff2e 92%, #073aff00 100%), repeating-radial-gradient(75% 75% at 238% 218%, #00ffff12 30%, #073aff14 39%), radial-gradient(99% 99% at 109% 2%, #00c9ff 0%, #073aff00 100%), radial-gradient(99% 99% at 21% 78%, #441083 0%, #073aff00 100%), radial-gradient(160% 154% at 711px -303px, #ffd600 0%, #ff6d00 100%);
  background-position: 0 0, 0 0, 0 0, 0 0;
  background-size: 100vw 100vh;
  background-attachment: scroll;
  background-clip: text;
  padding-top: 5.5rem;
  font-size: calc(10.2vh + 6.8vw);
  font-weight: bold;
  line-height: calc(7.2vh + 4.8vw);
  overflow: hidden;
}

.service-card {
  --card-scale-x: 1;
  --card-scale-y: 1;
  box-shadow: var(--shadow-elevation-medium);
  transform: scaleX(var(--card-scale-x)) scaleY(var(--card-scale-y));
  transition-property: box-shadow, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.service-card.coming-soon > .row:first-of-type {
  filter: blur(8px) grayscale(50%) brightness(110%);
}

.service-card.animated:hover {
  --card-scale-x: 1.025;
  --card-scale-y: 1.025;
  box-shadow: var(--shadow-elevation-high);
}

.background-blur {
  backdrop-filter: blur(8px) grayscale(50%) brightness(110%);
}

.btn-xl {
  --bs-btn-padding-y: 1.5rem;
  --bs-btn-padding-x: 3rem;
  --bs-btn-font-size: calc(1.525rem + 3.3vw);
  --bs-btn-border-radius: calc(var(--bs-border-radius) * 4);
}

@media (width >= 1200px) {
  .btn-xl {
    --bs-btn-font-size: 4rem;
  }
}

body {
  min-height: 100vh;
}
/*# sourceMappingURL=index.css.map */
