.ribbon {
    width: 120px;
    height: 120px;
    top: 0;
    right: 0;
    overflow: hidden;
    > span {
        position: absolute;
        display: block;
        width: 225px;
        padding: 0;
        text-align: center;
        left: -40px;
        top: 40px;
        transform: rotate(45deg);
    }
}

.compact {
    .ribbon {
        width: 105px;
        height: 105px;
        > span {
            left: -45px;
            top: 25px;
            transform: rotate(45deg);
        }
    }
}
